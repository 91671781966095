import { makeStyles, Theme, createStyles } from '@material-ui/core';

const featuresStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: '7%',
      paddingRight: '7%',
      paddingBottom: 150,

      [theme.breakpoints.down('md')]: {
        paddingLeft: 'unset',
        paddingBottom: 0,
        paddingRight: 0,
      },
    },
    greenBox: {
      width: '52px',
      height: '52px',
      background: '#E0FCD6 0% 0% no-repeat padding-box',
      position: 'absolute',
      zIndex: -1,

      [theme.breakpoints.down('md')]: {
        bottom: '52%',
        left: -20
      },

      [theme.breakpoints.down('xs')]: {
        bottom: '52%',
        left: -23
      },

      [theme.breakpoints.up(805)]: {
        bottom: '0%',
        left: -20
      },

      [theme.breakpoints.up('lg')]: {
        bottom: '17%',
        left: -35
      },

    },
    carouselItemContent: {},
    cardsContent: {
      display: 'flex'
    },
    expandablesContent: {
      display: 'flex',
      paddingLeft: '50px',
    },
    titleContent: {
      display:'flex',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: '80px',
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        paddingLeft: '7%',
        paddingRight: '7%',
        paddingBottom: '80px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingBottom: '32px',
      },
    },
    title: {
      font: 'normal normal 600 48px/75px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
      zIndex: 1,
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/44px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/36px Poppins',
      },
    },

    greenLetter: {
      color: '#1AA732'
    },

    firstRow: {
      marginBottom: 100,

      [theme.breakpoints.down('md')]: {
        marginBottom: 0,
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    expandedContent:{
      display:'flex',
    },
    secondRow: {
      marginBottom: 100,

      [theme.breakpoints.down('md')]: {
        marginBottom: 0
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    thirdRow: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0
      }
    },
    firstIcon: {
      transform: 'rotate(0deg)',
      color: theme.palette.secondary.dark,
      width: 18,
    },
    firstIconExpanded: {
      color: theme.palette.secondary.dark,
      transform: 'rotate(-45deg)',
      width: 18,
    },
    firstBackground: {
      marginRight: 10,
      backgroundColor: theme.palette.secondary.light,
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    secondIcon: {
      transform: 'rotate(0deg)',
      color: '#0099E6',
      width: 18,
    },
    secondIconExpanded: {
      color: '#0099E6',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    secondBackground: {
      marginRight: 10,
      backgroundColor: '#E2FDFF',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    thirdIcon: {
      transform: 'rotate(0deg)',
      color: '#FFBF01',
      width: 18,
    },
    thirdIconExpanded: {
      color: '#FFBF01',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    thirdBackground: {
      marginRight: 10,
      backgroundColor: '#FFF5D8',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fourthIcon: {
      transform: 'rotate(0deg)',
      color: '#D72A1D',
      width: 18,
    },
    fourthIconExpanded: {
      color: '#D72A1D',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    fourthBackground: {
      marginRight: 10,
      backgroundColor: '#FEE0D3',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fifthIcon: {
      transform: 'rotate(0deg)',
      color: '#0A642D',
      width: 18,
    },
    fifthIconExpanded: {
      color: '#0A642D',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    fifthBackground: {
      marginRight: 10,
      backgroundColor: '#D9E7DF',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sixthIcon: {
      transform: 'rotate(0deg)',
      color: '#005580',
      width: 18,
    },
    sixthIconExpanded: {
      color: '#005580',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    sixthBackground: {
      marginRight: 10,
      backgroundColor: '#D8E5EB',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    seventhIcon: {
      transform: 'rotate(0deg)',
      color: '#B38600',
      width: 18,
    },
    seventhIconExpanded: {
      color: '#B38600',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    seventhBackground: {
      marginRight: 10,
      backgroundColor: '#F4EDD9',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    eighthIcon: {
      transform: 'rotate(0deg)',
      color: '#780F06',
      width: 18,
    },
    eighthIconExpanded: {
      color: '#780F06',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    eighthBackground: {
      marginRight: 10,
      backgroundColor: '#EADAD9',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ninthIcon: {
      transform: 'rotate(0deg)',
      color: '#1AA732',
      width: 18,
    },
    ninthIconExpanded: {
      color: '#1AA732',
      transform: 'rotate(-45deg)',
      width: 18,
    },
    ninthBackground: {
      marginRight: 10,
      backgroundColor: '#DCF1DF',
      transition: '300ms ease all',
      width: 26,
      height: 26,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    carousel:{
      paddingBottom: '80px',
    },

    root: {
      [theme.breakpoints.down('md')]: {
        maxWidth: 'none',
        paddingLeft: '7%',
        paddingRight: '7%',
        paddingTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 0,
      },
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflow: 'visible',
    },

    firstCard:{
      maxWidth: 401,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'none',
        paddingLeft: 50,
        paddingRight: 25,
        paddingTop: 0,
      },
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflow: 'visible',
    },
  })
);

export default featuresStyles;
