import React, {
  FC,
  useState,
  useEffect,
  useCallback,
} from 'react';
import Fade from '@material-ui/core/Fade';
import useStyle, { 
  StyledSlider 
} from './ProgressBar.styles';
import { ProgressBarProps } from '../../../../../Interfaces';

const ProgressBar: FC<ProgressBarProps> = ({
  percentage,
  videoPlayerRef,
  timeHandler,
}) => {
  const classes = useStyle({ percentage });
  const videoPlayer = videoPlayerRef.current;
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(percentage);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (percentage > 4 && active === false) {
      setOpen(false);
      setActive(true);
    };
  }, [active, percentage]);

  useEffect(() => {
    setValue(percentage);
  }, [percentage]);

  const handleChange = (event, newValue) => {
    if (videoPlayer) {
      const seconds = (newValue * 100) / videoPlayer.duration;

      timeHandler(seconds);
    };
  };

  const handleMouseLeave = useCallback(() => {
    if (active) {
      setOpen(false);
    };
  }, [active]);

  return (
    <div 
      className={classes.container}
      onMouseOver={() => setOpen(true)}
      onMouseLeave={handleMouseLeave}
    >
      <Fade 
        in={open}
        timeout={500}
      >
        <div className={classes.progressBarContent}>
          <div className={classes.progressBar} >
            <StyledSlider
              value={value}
              onChange={handleChange}
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ProgressBar;
