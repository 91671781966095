import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import mobileBadgesStyle from './styles';
import { mobileStores } from '../../global-constants';

function StoreBadges() {
  const { i18n } = useTranslation();
  const classes = mobileBadgesStyle();

  const storeBages = useMemo(() => {
    return mobileStores.map(({url, logo, company, alt}) => (
      <a target="_blank" rel="noopener noreferrer" key={company} href={url} className={classes.badgeLink}>
        <img alt={alt} title={alt} src={logo[i18n.language]} className={classes.badgeImage} />
      </a>))
  }, [classes, i18n.language]);

  return (
    <Box className={classes.badgesContainer}>
      {storeBages}
    </Box>
  )
}

export default StoreBadges;
