import { makeStyles, createStyles, Theme } from '@material-ui/core';
import palette from '../../../../../global-style/colors.styles';


const freeTrialStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: '10%',
    },
    gridInfo: {
      textAlign: 'center',
      paddingLeft: '14%',
      paddingRight: '21%',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    colorHighlighted: {
      fontWeight: 'bold',
      color: palette.primary.contrastText,
    },
    bold: {
      fontWeight: 'bold',
    },
    gridButton: {
      paddingLeft: '14%',
      paddingRight: '21%',
      textAlign: 'center',
      paddingBottom: '10%',
    },
    button: {
      marginTop: 50,
    },
  })
);

export default freeTrialStyles;
