import * as React from "react"

function LeftArrowComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.786}
      height={21.94}
      viewBox="0 0 11.786 21.94"
      {...props}
    >
      <title>
        Left Arrow
      </title>
      <path
        d="M.004 10.973a.749.749 0 01.221-.532L10.511.221a.751.751 0 011.058 1.065l-9.751 9.687 9.751 9.688a.751.751 0 01-1.058 1.065L.225 11.506a.752.752 0 01-.221-.533z"
        fill="#1d1d1b"
      />
    </svg>
  )
}

export default LeftArrowComponent;