import { makeStyles } from '@material-ui/core';

const cardFeatureStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 401,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'none',
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 0,
      paddingBottom: 150,
    },
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 0,
    },
    boxShadow: 'none',
    backgroundColor: 'transparent',
    overflow: 'visible',
  },
  title: {
    marginTop: 5,
    marginBottom: 8,
  },
  wrapIconExpanded: {
    transform: 'rotate(45deg)',
    transition: '300ms ease all',
    marginRight: 10,
    marginLeft: 15,
  },
  expand: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  card: {
    paddingBottom: '36px',

    [theme.breakpoints.down('md')]: {
      paddingBottom: '16px',
    },
  },
  cardContent: {
    paddingTop: 0,
    height: '116.17px',

    [theme.breakpoints.down(1370)]: {
      height: '135px',
    },

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      width: 'unset',
    },
  },
  link: {
    display: 'flex'
  },
  linkText: {
    paddingTop: '5px'
  },
  cardActions: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  featureList: {
    listStyle: 'none',
    marginTop: 0,
    maxWidth: 390,

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  cardHeader: {
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    left: -35,
  },
  cardDetails: {
    paddingTop: 0,
    paddingBottom: '0px !important',
  },
  moreFunctions: {
    fontSize: 20,
    color: '#1D1D1B',
  },
  listStyle: {
    verticalAlign: 'super',
    marginRight: 3,
  },
  href: {
    color: 'black',
    textDecoration: 'underline',
  },
  absolute: {},
}));

export default cardFeatureStyles;
