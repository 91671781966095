import {makeStyles, Theme} from '@material-ui/core';

const primaveraPageStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  },
  infoContainer: {
    backgroundColor: '#e2fdff',
    minHeight: 'calc(100vh - 80px)',
    height: '100%',
    display: 'flex',
    padding: '0 7%',
    paddingBottom: 60
  },
  separator: {
    width: '150px',
    height: '12px',
    backgroundColor: '#00acff',
    margin: '20px 0 50px',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  infoTextContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      font: 'normal normal 600 48px/56px Poppins',
      margin: 0,
      color: '#1d1d1d',
      '& span': {
        color: '#0099e6'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      paddingTop: 45
    }
  },
  infoSubText: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#005580',
    margin: 0
  },
  infoSmallImgContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .banner-image': {
        width: '100%'
      },
      marginBottom: 30
    }
  },
  infoImgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    '& .banner-image': {
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  infoTextContent: {
    margin: 0,
    font: 'normal normal 300 20px/24px Lato',
    color: '#1d1d1d',
    '& span': {
      fontWeight: 500
    }
  },
  bolderText: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#1d1d1d'
  },
  advantagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '110px 7% 130px',
    gap: 85,
    [theme.breakpoints.down('md')]: {
      paddingTop: 70,
      paddingBottom: 80
    }
  },
  advantagesTitleContainer: {
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    },
    '& h2': {
      margin: 0,
      font: 'normal normal 600 48px/56px Poppins',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    }
  },
  advantagesTitle: {
    position: 'relative',
    zIndex: 1
  },
  titleGreenBox: {
    width: 52,
    height: 52,
    backgroundColor: '#e0fcd6',
    position: 'absolute',
    zIndex: -1,
    top: '-13px',
    left: '-34px',
    [theme.breakpoints.down('md')]: {
      top: '-19px'
    }
  },
  titleGreenText: {
    color: '#1aa732'
  },
  advantagesCardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down(890)]: {
      flexDirection: 'column'
    }
  },
  toolCardContent: {
    maxWidth: 398,
    width: '100%',
    boxShadow: '0 0 5px #00000029',
    borderRadius: 26,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      flex: 1,
      minHeight: 70
    }
  },
  toolCardImgContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  toolCardParagraph: {
    font: 'normal normal 300 18px/22px Lato',
    color: '#1d1d1d',
    '& p': {
      margin: 0
    }
  },
  thirdSection: {
    backgroundColor: '#263238',
    padding: '10% 7% 5%',
    paddingBottom: 100,
    position: 'relative',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('lg')]: {
      padding: '5% 7%',
    },
  },
  thirdTitleContainer: {
    display:'flex',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: '80px',
    width: '100%',
    maxWidth: '1026px',
    textAlign: 'center',

    [theme.breakpoints.down('md')]: {
      paddingBottom: '80px',
      textAlign: 'left'
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: '70px',
    },
  },
  thirdTitleContent: {
    font: 'normal normal 600 48px/56px Poppins',
    color: '#fff',
    width: '100%',
    wordWrap: 'break-word',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 600 30px/35px Poppins'
    }
  },
  greenLetter: {
    color: '#1AA732'
  },
  processoUsabilidadeContainer: {
    color: '#fff',
    display: 'flex',
    gap: 10,
    flexDirection: 'column',
    [theme.breakpoints.down(960)]: {
      gap: 50
    }
  },
  processoUsabilidadeItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    [theme.breakpoints.down(960)]: {
      flexDirection: 'column'
    }
  },
  processoNumeberIndicator: {
    width: 45,
    height: 45,
    border: '2px solid #fff',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    font: 'normal normal 600 24px/24px "Source Sans 3", Helvetica, Arial, sans-serif'
  },
  processoTextContainer: {
    padding: '12px 20px',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    width: '100%',
    maxWidth: '752px',
    minHeight: '73px',
    display: 'flex',
    alignItems: 'center',
    font: 'normal normal normal 20px/24px Lato',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 18px/24px Lato'
    }
  },
  processoText: {
    margin: 0
  },
  square: {
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: 0,
    opacity: 0.11,

    '&.square--smallest': {
      top:177,
      right: 401,
      width: 89,
      height: 89,
      [theme.breakpoints.down(1614)]: {
        top: 150,
        right: 347,
        width: 75,
        height: 75
      },
      [theme.breakpoints.down(1227)]: {
        top: 95,
        right: 237,
        width: 48,
        height: 48
      }
    },

    '&.square--small': {
      top: 0,
      right: 224,
      width: 177,
      height: 177,
      [theme.breakpoints.down(1614)]: {
        right: 197,
        width: 150,
        height: 150
      },
      [theme.breakpoints.down(1227)]: {
        right: 142,
        width: 95,
        height: 95
      }
    },

    '&.square--big': {
      top: 177,
      right: 0,
      width: 224,
      height: 224,
      [theme.breakpoints.down(1614)]: {
        top: 150,
        width: 197,
        height: 197
      },
      [theme.breakpoints.down(1227)]: {
        top: 95,
        width: 142,
        height: 142
      }
    }
  },
  fourthSectionLeft: {
    backgroundColor: '#E0FCD6',
    padding: '10% 7%',
    position: 'relative',
    justifyContent: 'center',

    [theme.breakpoints.up('lg')]: {
      padding: '5% 7%',
    },
  },
  vantagensIntegrado: {
    display: 'flex',
    flexDirection: 'column',
    gap: 180,
    [theme.breakpoints.down('md')]: {
      gap: 100
    }
  },
  titleContent: {
    font: 'normal normal 600 48px/56px Poppins',
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 600 30px/35px Poppins'
    }
  },
  fourthSectionTextContainer: {
    marginTop: 45
  },
  fourthSectionTextContent: {
    margin: 0,
    font: 'normal normal normal 20px/24px Lato',
    '&.with-margin': {
      marginBottom: 10
    },
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal normal 18px/24px Lato'
    }
  },
  processoIntegradoImgContainer: {
    display: 'none',
    '& img': {
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 80,
      marginLeft: '-8%',
      marginRight: 20
    }
  },
  fourthSectionRight: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '7%',
    '& div': {
      '& img': {
        width: '100%'
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  fourthSectionRightImgContainer: {
    flex: 1
  },
  formContainer: {
    backgroundColor: '#E2FDFF',
    justifyContent: 'center',
    paddingTop: '165px',
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: '230px',
    flexDirection: 'column',
    alignItems: 'center',
    '& form': {
      padding: 0,
      paddingTop: 40,
      height: 'auto',
      minHeight: 'unset',
      maxWidth: '812px',
      boxShadow: 'none',
      '& .form-container-trial': {
        backgroundColor: '#e2fdff'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 100,
      marginBottom: 60
    }
  },
  formTitleContainer: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start'
    }
  },
  formTitle: {
    font: 'normal normal 600 40px/56px Poppins',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 600 30px/35px Poppins',
    }
  },
  newsLetterContainer: {
    '& .container-subscription': {
      [theme.breakpoints.up('lg')]: {
        marginTop: '-100px'
      }
    }
  }
}));

export default primaveraPageStyles;