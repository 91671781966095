import { makeStyles } from '@material-ui/core';

const cardFeatureStyles = makeStyles(theme => ({
  wrapIconExpanded: {
    transform: 'rotate(45deg)',
    transition: '300ms ease all',
    marginRight: 10,
    marginLeft: 15,
  },
  expand: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '7px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  link: {
    display: 'flex'
  },
  linkText: {
    paddingTop: '5px'
  },
  expandOpen: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  featureList: {
    listStyle: 'none',
    marginTop: 0,
    maxWidth: 390,

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  cardDetails: {
    paddingTop: 0,
    paddingBottom: '0px !important',
  },
  moreFunctions: {
    fontSize: 20,
    color: '#1D1D1B',
  },
  listStyle: {
    verticalAlign: 'super',
    marginRight: 3,
  },
  href: {
    color: 'black',
    textDecoration: 'underline',
  },
  absolute: {},
}));

export default cardFeatureStyles;