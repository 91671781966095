import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './Boxes.styles';

function Boxes({
  imageUrl,
  alt,
  highlight,
  firstNormal,
  secondNormal
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <img height="" width="" title={alt} src={imageUrl} alt={alt} className={classes.image} />
      <div className={classes.textContent}>
        <div>
          <b>{t(highlight)}</b> {t(firstNormal)}
          <br /> {t(secondNormal)}
        </div>
      </div>
    </div>
  )
}

export default Boxes;
