import * as React from "react"

function GreenBoxesComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={150.404}
      height={123.242}
      viewBox="0 0 150.404 123.242"
      {...props}
    >
      <title>
        Green Box
      </title>
      <g data-name="Grupo 406" fill="#e0fcd6">
        <path
          data-name="Ret\xE2ngulo 1622"
          d="M150.403 54.322v68.92h-68.92v-68.92z"
        />
      </g>
    </svg>
  )
}

export default GreenBoxesComponent