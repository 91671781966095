import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const mobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      marginBottom: 40,
    },    
    buttonLink: {
      height: '50px',
      marginRight: '26px',

      [theme.breakpoints.down('md')]: {
        marginRight: '26px',
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: '16px'
      },
    },
    btnContainer:{},    
    videoBtn: {
      height: '50px',
    },
    title: {
      maxWidth: '350px',
    },
    animatedImage: {},
    textContent: {
      paddingTop: '10px',
      
      [theme.breakpoints.down('xs')]: {
        paddingTop: '15px',
        
      },
    },
  })
);

export default mobileStyles;