export const listMobile = [
  'Application compatible with Android (Google), iOS (Apple) and HarmonyOS (Huawei) operating systems;',
  'Authenticate with your iDOK credentials or with your GTS credentials, digital signature provider (for GTS customers only);',
  'Access all the entities associated with your user profile;',
  'Consult and make search filters by documents sent, received, among others;',
  'View documents, associated entities and documents sent;',
  'Associate decisions, opinions or information;',
  'Assign deadlines and priority levels;',
  'Send documents to specific departments and/or users;',
  'Mark the documents to be signed;',
  'Sign a document or several documents simultaneously with GTS (batch signing);',
  'Validate your GTS digital signature via OTP code, pin, fingerprint (or Face ID, for iOS users);',
  'Receive notifications, in real time, on the receipt of new documents.'
];

export default {
  translation: {
    idokLogo: 'iDOK Document Management',
    login: 'Login',
    features: 'Features',
    gestaoSistemas: 'Systems Management',
    prices: 'Prices',
    news: 'News',
    requestTest: 'FREE TRIAL',
    requestPrices: 'Request a Quote',
    request: 'Request',
    confirmTest: 'Confirm',
    watchVideo: 'SEE VIDEO',
    talkToUs: 'Contact us',
    telephone: '707 451 451. ',
    idokSupportEmail: 'apoio@idok.pt. ',
    enter: 'Enter',
    mainContentHome:
      'iDOK is a simple and intuitive document management software that allows you to register, consult and file all documentation, anywhere. Dematerialise and organise the internal functioning of your entity.',
    makeYourTitleHome: 'MAKE THE',
    managementTitleHome: 'MANAGEMENT',
    documentaryTitleHome: 'OF YOUR DOCUMENTS',
    lighterTitleHome: 'LIGHTER',
    lighterTitleHome2: 'MORE EFFICIENT',
    lighterTitleHome3: 'MORE SUSTAINABLE',
    lighterTitleHome4: 'EASIER',
    normalTitleFeatures: 'Essential for your',
    highlightTitleFeatures: 'Entity to flow!',
    normalTitleIntegrations: 'Learn about',
    highlightTitleIntegrations: 'iDOK integrations',
    subtitleIntegrations: 'Automate your Document Management',
    integrations: 'Integrations',
    featuresDetails: 'Features',
    knowMore: 'More information',
    documentManagementTitle: 'Document Management',
    documentManagementContent:
      'Whether scanned or uploaded, the documents are always available for classification and are subsequently archived in the system. These documents can also be set up as templates.',
    modelManagement: 'Template management',
    documentClassification: 'Document classification',
    opticalCharacterRecognition: 'Optical Character Recognition',
    filesManagement: 'File management',
    documentSeries: 'Document Series',
    customisableFields: 'Customisable fields',
    mef: 'MEF (Macrostructure)',
    associationOfResponseDeadlines: 'Association of response deadlines',
    workflowTitle: 'Workflow',
    workflowContent:
      "Create workflow processes for document tracking. The stages are handled only by a restricted group of people defined by the entity. In this way, you manage the complete lifecycle of your entity's documentation.",
    search: 'Search',
    documentsDistribution: 'Document distribution',
    processingFlows: 'Flow processing',
    deadlinesAndReports: 'Deadlines and reports',
    flowchartsCreation: 'Flowchart creation',
    sendingAlertsAndEmails: 'Sending alerts and emails',
    rulesOfCommunicationWithWebServices: 'Web services communication rules',
    processesTitle: 'Process Management',
    processesContent:
      'A virtual folder, document aggregator, which allows you to associate files to a certain process, to guarantee that all the information is organised and centralised. You can also assign managers, consult and export information.',
    personalProcessManagement: 'Personal process management',
    exportProcessListing: 'Export of process listings',
    assignmentOfManagers: 'Assignment of managers',
    safetyTitle: 'Security',
    safetyContent:
      'Have access to a management area and user groups in which it is possible to define permissions and responsibilities for each user and define access by user and user profile to certain functionalities and content.',
    restrictedUserAccess: 'Restricted user access',
    reports: 'Reports',
    accessRegistration: 'Access Registration',
    digitalSignatureTitle: 'Digital signature',
    digitalSignatureContent:
      'Digitally sign the uploaded and forwarded documents on your computer, using a ',
    digitalSignatureSecondContent:
      ' of GTS - Global Trusted Sign or with the Portuguese Identity Card. In this way, you guarantee the authorship of your documents.',
    digitalSignatureHref: 'qualified digital certificate',
    ownershipGuarantee: 'Ownership guarantee',
    batchSubscription: 'Batch signature',
    severalSignatures: 'Possibility of having several signatures on the same document',
    customSolutionTitle: 'Customised solution',
    customAutomationTitle: 'Automatization',
    customGestaoSistemasTitle: 'Systems Management Module',
    customPortalServicosTitle: 'Services Portal',
    customSolutionContent:
      'iDOK is fully configurable, so that it is adapted to your entity. You can add new features that complement the document management, providing a more intuitive environment.',
    customAutomationContent: 'Our software ensures unprecedented progress in administrative processes. iDOK is committed to automation in order to provide efficiency and reduce errors. Modernize your document management.',
    customGestaoSistemasContent: 'The Systems Management Module is the answer you are looking for when it comes to auditing. It is a strong ally for compliance with policies, processes and procedures, with the possibility of limiting access to certain documents.',
    customPortalServicosContent: 'The iDOK platform makes it possible to integrate external portals that make it easier to access services.',
    automaticIndex: 'Automatic indexing',
    notificationAlert: 'Notifications and alerts',
    fluxoDinamico: 'Dynamic workflow',
    integracaoSistemasExterno: 'Integration with external systems',
    gestaoVersaoAutomatica: 'Automatic version management',
    gestaoModelos: 'Template management',
    registoAutenticacao: 'Registration and authentication of the target audience',
    solicitacaoPedidos: 'Online request and consultation of information services',
    envioDadosPedidos: 'Filling in/submitting data and sending requests for analysis',
    geracaoPagamentos: 'Payment generation',
    envioSmsEmail: 'SMS and e-mail notification',
    gestaoReservas: 'Reservation management',
    geracaoDocumentosDinamicos: '',
    emailStructure: 'Email structure',
    departmentManagement: 'Department management',
    billingInquiry: 'Invoicing consultation',
    entityManagement: 'Entity Management',
    normalTitleFreeTrial: 'Free',
    highlightTitleFreeTrial: 'Trial',
    introFreeTrial:
      'Do you want to know more about document dematerialisation? Ask us for a demonstration and discover all the advantages of a document management software like iDOK, without additional costs or limitations.',
    makeRegisterFreeTrial: 'Pre-register and we will contact you.',
    askQuestionPrimavera: 'We are at your disposal to answer your questions! Simplify your document management now.',
    contactForFreeTrial: 'Contact us to join or test with no obligation.',
    entity: 'Entity Name',
    email: 'Email',
    phone: 'Telephone',
    country: 'Country',
    portugal: 'Portugal',
    brazil: 'Brazil',
    canada: 'Canada',
    requiredFields: 'Mandatory fields.',
    readTerms: 'I declare that I have read and accept the ',
    conditionsTerms: 'General Conditions of Registration',
    codicoesGeraisPdf: 'cgadesao_en.pdf',
    registerTerms: 'I declare to know and accept the ',
    thanksForm: 'Thank you for your pre-registration, which was successful.',
    thanksFormPrimavera: 'Thank you for your interest in integrating with ERP Primavera.',
    thanksFormSelosTemporais: 'We appreciate your interest in GTS Timestamps.',
    soonForm: 'We will contact you shortly through our support line.',
    freePrice: '0.00€',
    cloudPrice: '9,90€',
    fiveGbPrice: '40€',
    tenGbPrice: '75€',
    twentyGbPrice: '140€',
    aboveTwentyGbPrice: '+15€',
    eachTwoGb: '/each 2GB',
    oneGb: '1GB',
    fiveGb: '5GB',
    tenGb: '10GB',
    twentyGb: '20GB',
    free: 'Free',
    perMonth: '/month',
    until: 'Until ',
    enjoy: 'Enjoy',
    overThan: 'More than ',
    thirtyDaysFree: '30 Days Free',
    withoutCommitment: 'with no obligation!',
    plansCloud: 'Cloud Plans',
    otherPlans: 'OTHER PLANS',
    normalTitlePrices: 'A Plan tailored to ',
    highlightTitlePrices: 'Your Entity',
    introPrices: 'Each entity deserves a personalised document management plan to make the most of all its functionalities.',
    titlePrices: 'Bet on document dematerialisation and a customised plan.',
    tryItForFree: '',
    duringThirtyDays: '30-day trial!',
    normalContentPrice: 'Limited ',
    highlightContentPrice: 'storage',
    every: 'All ',
    idokFunctionalities: 'iDOK Functionalities',
    cloud: 'Cloud',
    since: 'From',
    try: 'Free ',
    freeForThirtyDays: '30-day trial',
    cloudStorage: 'Cloud Storage ',
    fromOneGb: 'from 1GB',
    onPremise: 'On-Premise',
    priceOnRequest: 'Price upon request',
    normalEntityPlan: 'Do you need a plan adapted to the reality of your entity?',
    highlightEntityPlan: 'We have a solution tailored to your needs.',
    fullPriceList: 'FULL PRICE LIST',
    priceFormText: 'Tell us your details, with no obligation, and we will send you a proposal adapted to your reality.',
    select: 'Select',
    workers: 'Number of employees',
    entityType: 'Entity Type',
    thanks: 'Thank you!',
    normalTitleAcin: 'An ACIN - iCloud Solutions Lda',
    highlightTitleAcin: 'solution',
    firstParagraphAcinInit: '',
    firstParagraphAcinLink: 'ACIN - iCloud Solutions Lda',
    firstParagraphAcinEnd: ' is at the forefront of Cloud Computing and is committed to practical, cost-effective and user-friendly solutions that meet the different needs of the market and its customers.',
    highlightSecondParagraphAcin:
      'With more than 20 years in the market, ACIN Group is in constant expansion, currently having 4 companies and 14 software platforms.',
    normalSecondParagraphAcin: 'But the perspective is that these numbers continue to grow.',
    thirdParagraphAcin:
      'Every day we make a difference. Proof of this are the more than 180 thousand customers who trust our work and believe in our avant-garde solutions. Your satisfaction is our greatest purpose.',
    highlightNumberClientAcin: '+ 180.000',
    clients: 'customers',
    normalNumberClientAcin: 'worldwide',
    highlightBillsAcin: '+ 15.000.000 €',
    normalBillsAcin: 'in turnover',
    highlightEmployeesAcin: '+ 350',
    normalEmployeesAcin: 'employees',
    normalTitleNewsletter: 'Receive our latest',
    highlightTitleNewsletter: 'News!',
    subscribe: 'Register',
    menu: 'Menu',
    contacts: 'Contacts',
    contactsCost: 'Maximum amount to be paid per minute: 0,09€ (+VAT) for calls from fixed networks and 0,13€ (+VAT) for calls from mobile networks.',
    serviceHoursText: 'We are at your disposal on business days from 9:00 to 18:00.',
    addresses: 'Addresses',
    solution: 'A solution',
    demo: 'Demonstration',
    maps: 'See map',
    copyright: 'All rights reserved',
    terms: 'Terms and Conditions',
    privacyPolicy: 'Privacy Policy',
    suggestions: 'Acknowledgements, Suggestions and Complaints',
    subTitleFaqs: "FAQ iDOK DOCUMENT MANAGEMENT",
    normalTitleFaqs: 'How can we ',
    highlightTitleFaqs: 'Help?',
    searchQuestion: 'Search question',
    aboutUsQuestionFaqs: 'What is iDOK?',
    aboutUsAnswerFaqs:
      'iDOK is a document management software that allows you to register, consult and file all the documentation of your entity. This fully Web platform, hosted in the Cloud, ensures a strict monitoring of internal procedures and information security, available 24 hours a day, through any device.',
    advantagesQuestionFaqs: 'What are the advantages of a document management software?',
    advantagesAnswerFaqs:
      'Document management can be a demanding and time-consuming activity. But document dematerialisation avoids mistakes, such as the dispersion and loss of important documents, and saves time and resources.',
    adaptationQuestionFaqs: 'Is iDOK suitable for any entity?',
    adaptationAnswerFaqs: 'Yes, whether public or private organisations.',
    possibleBrowsersQuestionFaqs: 'Which browsers can I use to access the iDOK Document Management Platform?',
    possibleBrowsersAnswerFaqs:
      'Access to iDOK, as well as the correct use of the available functionalities, is ensured in the following Web browsers: Google Chrome, Mozilla Firefox, Safari, Opera, Edge.',
    digitalSignatureQuestionFaqs: 'Is it possible to use digital signatures on the iDOK Platform?',
    digitalSignatureAnswerFaqs:
      'iDOK is designed for the use of certified digital signatures, whether advanced or qualified.',
    sendAndReceiveDocumentQuestionFaqs: 'Does iDOK allow sending and receiving documents from other entities?',
    sendAndReceiveDocumentAnswerFaqs:
      'Yes, provided that the entities are registered on the Platform, it is only necessary to configure that option.',
    documentReceptionQuestionFaqs: 'Is it possible to receive documents from other platforms?',
    documentReceptionAnswerFaqs:
      'iDOK allows the integration, through webservice mechanisms, in real time of documents from other platforms.',
    flowChartsQuestionFaqs: 'Does iDOK allow the creation of flows?',
    flowChartsAnswerFaqs:
      'Yes, you can define flows for document tracking between departments according to the information entered. In this way, the stages are the responsibility of a restricted group of users.',
    govAutenticationQuestionFaqs: 'What is the AUTENTICAÇÃO.GOV application for?',
    govAutenticationAnswerFaqs: 'The AUTENTICAÇÃO.GOV application allows the use and collection of the Portuguese Identity Card data.',
    usersQuestionFaqs: 'How many users can I create in iDOK?',
    usersAnswerFaqs: 'The number of users is unlimited.',
    customPermissionsQuestionFaqs: 'Are user permissions customisable?',
    customPermissionsAnswerFaqs:
      'Yes. It is possible to define which documents can be viewed or edited, or what information is available.',
    restrictAccessQuestionFaqs: 'Is it possible to restrict access to certain users?',
    restrictAccessAnswerFaqs:
      'You can easily restrict user access to certain departments, whether for document routing, classification or searching. Determine what information can be entered, modified, deleted and consulted, limiting access to menus and modules of the platform.',
    supportQuestionFaqs: 'Does iDOK offer a free phone support service?',
    supportAnswerFaqs: 'Yes, we have a dedicated telephone line at your disposal: ',
    supportTwoAnswerFaqs: 'You can also contact us by email at ',
    supportThreeAnswerFaqs:
      'We are at your disposal, every business day, from 8.30 am to 7 pm, at no additional cost.',
    tryAnotherQuestion: 'Try a different question...',
    user: 'User',
    password: 'Password',
    forgetPassword: 'Forgot your password?',
    authenticate: 'Authenticate',
    loginWithCCContent: 'Authentication with the Portuguese Identity Card allows a practical and safe login in the portal.',
    loginWithGtsIdContent: 'Authentication via GTS ID allows you to access, securely and in a single login, the iDOK and GTS platforms.',
    aceitar: 'Accept',
    cookieText: 'iDOK uses cookies to improve your experience on this website. By browsing this website, you consent to their use. Learn more about our {tagopen} cookie policy {tagclose}',
    menuLogin: {
      opt1: 'Login',
      opt2: 'Portuguese Identity Card',
      opt3: 'GTS ID',
      opt4: 'Recover password',
      opt5: 'Change password',
      opt6: 'How to log in using the Portuguese Identity Card?',
    },
    recoveryPass: 'Enter your email address',
    goBack: 'Back',
    recovery: 'Recover',
    footerText: {
      text1: "Don't have an iDOK account yet?",
      text2: 'Ask us for a demo',
      text3: 'and try iDOK for free!'
    },
    loginBannerText:{
      text1: 'Sign anywhere',
      text2: 'with the GTS Electronic Signature',
      knowMore: 'More information',
    },
    wrongCredentials: 'Incorrect Username or Password',
    error: 'An error occurred',
    goback: 'Back',
    page404: {
      title: 'Oops!',
      subTitle: 'You seem lost in the midst of so many documents!',
      description: 'Go back to iDOK and we will help you.'
    },
    page500: {
      title: 'Crash!',
      subTitle: 'Internal server error',
      description: "Fortunately, it's all hosted in the cloud!"
    },
    page403: {
      title: 'Oh, no!',
      subTitle: 'Access Denied',
      description: 'Sorry, but you do not have permission to access what you have requested'
    },
    page401: {
      title: 'Oops!',
      subTitle: 'Authorisation Required',
      description: 'Please log in and access your cloud Document Management.'
    },
    acinGroup: {
      leftSide: {
        title: {
          quality: 'Quality and ',
          security: 'Security ',
          rest: 'in Document Management'
        },
        firstText: {
          start: 'The high quality that characterises the products of ',
          end: ', a company of the ACIN Group, is the result of a rigorous monitoring of internal procedures that guarantee the continuous improvement of services and promote increased levels of customer satisfaction.',
          link: 'ACIN - iCloud Solutions Lda'
        },
        iso9001: 'ISO 9001: ',
        iso9001Desc: 'Guarantees the quality and satisfaction of the services provided by ACIN.',
        iso27001: 'ISO 27001: ',
        iso27001Desc: 'Guarantees the confidentiality, integrity and privacy of the information managed by our platforms.',
        iso20000: 'ISO 20000: ',
        iso20000Desc: 'Guarantees compliance with service levels defined for our customers and suppliers.',
        lastText: 'Few Portuguese companies have such a rigorous technological certification.'
      },
      rightSide: {
        platform: '+ platforms ',
        rest: 'of the ACIN - iCloud Solutions Lda',
        knowMore: 'More information',
        from: 'of the',
        acinGroup: 'ACIN - iCloud Solutions Lda',
      },
      platforms: {
        igest: {
          title: 'iGest',
          desc: 'Invoicing Software',
          url:'igest.pt'
        },
        juntas: {
          title: 'Juntas Online',
          desc: 'Civil Parish Councils Service',
          url: 'juntasonline.pt'
        },
        ifreg: {
          title: 'iFREG',
          desc: 'Civil Parish Management Software',
          url: 'ifreg.pt'
        },
        paypay: {
          title: 'PayPay',
          desc: 'Payment Solution',
          url: 'paypay.pt'
        },
        gts: {
          title: 'GTS',
          desc: 'Trust Service Provider',
          url: 'globaltrustedsign.com'
        },
        acingov: {
          title: 'acinGov',
          desc: 'Public Procurement Electronic Platform',
          url: 'acingov.pt'
        },
        imed: {
          title: 'imed',
          desc: 'Clinical Management Software',
          url: 'imed.pt'
        },
        iParque: {
          title: 'iParque',
          desc: 'Car Park Management Software',
          url: 'iparque.pt'
        },
        irh: {
          title: 'iRH',
          desc: 'Attendance Management Software',
          url: 'irh.pt'
        },
        cestado: {
          title: 'C. Estado',
          desc: 'Business Opportunities',
          url: 'comprasdoestado.pt'
        },
        ilink: {
          title: 'iLink',
          desc: 'EDI and Electronic Invoicing',
          url: 'ilink.pt'
        },
        taxi: {
          title: 'omeutáxi',
          desc: 'Invoicing Software for Taxi Drivers',
          url: 'omeutaxi.pt'
        },
        agromarket: {
          title: 'Agromarket',
          desc: 'Platform for the sale of agricultural products',
          url: 'agromarket.pt'
        },
        denuncias: {
          title: 'P. Denúncias',
          desc: 'Reporting management platform',
          url: 'portaldedenuncias.pt'
        }
      }
    },
    acin: 'ACIN group',
    microsoftPlatform: 'Advanced Productivity Platform',
    salesforce: 'Salesforce',
    selosTemp: 'Timestamps',
    cardReader: 'Card Readers',
    boxEmails: 'Electronic Mailboxes',
    emailRequired: 'Email required',
    validEmail: 'Must be a valid email',
    passwordRequired: 'Password required',
    recoveryPassError: 'An error occurred while trying to recover your password',
    nameRequired: 'Name required',
    phoneRequired: 'Telephone required',
    onlyNumbers: 'The telephone number is required',
    countryRequired: 'The country is required',
    termsRequired: 'Deverá confirmar as Condições Gerais de Adesão',
    policyRequired: 'Deverá confirmar a Política de Privacidade',
    policyLink: 'https://acin.pt/en/politica-privacidade/',
    workersRequired: 'The number of employees is required',
    entityTypeRequired: 'The entity type is required',
    recoveryPassEmailSent: 'The email for your password recovery has been sent successfully.',
    newPassword: 'New password',
    newPasswordConfirmation: 'Confirmation of new password',
    passwordRules: {
      title: 'Your password must:',
      rule1: 'Be at least 10 characters long.',
      rule2: 'Contain 3 of these elements: ',
      rule3: 'Lowercase letters; ',
      rule4: 'Uppercase letters; ',
      rule5: 'Numbers; ',
      rule6: 'Special characters.',
      rule7: 'Reach a strong level.'
    },
    veryWeak:'Very Weak',
    weak: 'Weak',
    moderate: 'Moderate',
    hard: 'Strong',
    modify: 'Change',
    wrongConfirmPassword: 'The password does not match',
    passwordAlterSuccessfully: ' Your password was successfully changed',
    enterWithCC: 'How to log in using the Portuguese Identity Card?',
    subscribeCCError: 'An error occurred while trying to register with your Portuguese Identity Card',
    submit: 'Submeter',
    subscribeCCText: 'In order to access using your Portuguese Identity Card, you must activate this form of access.',
    sentEmail: 'The email was sent successfully',
    acinGroupPlatforms: 'Plataforms',
    conectionWithPortals: 'Posibility for interconect with services portal',
    accessCentralized: 'Centralized information access',
    alertsAndNotifications: 'Alerts and notifications',
    clasificationPlan: 'Classification plan',
    protectedInformation: 'Protected classificated information',
    signOnAuthentication: 'Single Sign-On authentication',
    relatories: 'Relatories and statistics',
    quality: 'Quality',
    mainAnimation: 'Document management',
    mobileAnimation: 'Document management application',
    phonesAnimation: 'Digital signature in the idok application',
    acinVideo: 'Digital transformation',
    mobileHome: {
      mainTitle: {
        line1: 'Do you already know',
        line2: {
          default: 'the ',
          color: 'Document Management application?'
        },
      },
      secondaryTitle: 'app idok',
      paragraphs: {
        text1: 'We present the extension of your Document Management solution, offering you more flexibility and mobility when managing your information.',
        text2: {
          init: 'Our document management application is integrated with the trust service provider ',
          middle: ' and allows you, if you have a ',
          bold: ' to digitally sign documents from your mobile phone.',
          link1: 'Global Trusted Sign',
          link2: 'GTS qualified signature,'
        },
      },
      button: 'Learn more',
      imageAlt: 'Screens of the iDOK Document Management application'
    },
    mobile: {
      linkBold: 'new',
      mainTitle: {
        line1: 'Innovative Document ',
        line2: {
          color: 'Management application'
        },
      },
      mainParagraph: {
        init: 'A Document Management application that guarantees ',
        bold: 'total mobility',
        end: '! Manage your documents and digitally sign them quickly, easily and securely. Available for '
      },
      secondaryTitle: {
        bold: 'Total mobility ',
        normal: 'with total ',
        color: 'Security'
      },
      secondaryParagraph: 'The iDOK application is an extension of your Document Management solution, an add-on that gives you more flexibility and mobility when managing your information.',
      thirdTitle: {
        color: 'Digital signature',
        normal: 'in the palm of your hand'
      },
      mobileImgAlt: {
        img1: "Sending of documents to users or departments, in the Document Management application.",
        img2: "Screen with the information of the document, associated entities and documents sent in the idok application.",
        img3: "Start screen of the Document Management application with the two solution types.",
        img4: "List of documents received, with search filters in the idok application.",
        img5: "Screen with the affixation of the digital signature in the document in the idok application."
      },
      thirdParagraph: {
        text1: 'Increasingly, more and more documents are processed electronically, requiring authentication.',
        text2: 'This validation can be done by affixing a qualified digital signature, promoting the digital transformation of your organisation.',
        text3: {
          init: 'The iDOK Document Management application is integrated with the trust service provider ',
          link1: 'Global Trusted Sign (GTS)',
          middle: ', which offers ',
          link2: 'digital signature certificates',
          end: ' of various types, i.e., for natural and legal persons.'
        },
        text4: {
          init: 'Avoid authentication methods that require the installation of hardware or software and ',
          bold: 'sign your documents directly in the iDOK application with the GTS digital signature.'
        }
      },
      forthTitle: {
        normal: 'Discover the main functionalities of the ',
        color: 'iDOK application'
      },
      list: listMobile,
      forthParagraph: {
        init: 'Take advantage of a simple and intuitive Document Management application, ',
        bold: 'integrated with the trust service provider Global Trusted Sign',
        end: ', which allows you, if you have a GTS qualified signature, to digitally sign documents from your mobile phone.'
      },
      listCards: [
        { bold: 'Receive notifications', normal: 'in real time', alt: 'Push notifications of the iDOK application' },
        { bold: 'Consult', normal: 'all the information', alt: 'Access to all documents through the iDOK application' },
        { bold: 'Digitally sign', normal: 'con GTS', alt: 'Digital signature with GTS' },
        { bold: 'Send', normal: 'documents', alt: 'Document sending through the iDOK application' },
      ],
       meta: {
        title: 'iDOK application - Total security for Document Management',
        description: 'A Document Management application that guarantees total mobility. Manage your documents and digitally sign them quickly, easily and securely.',
        keywords: 'segurança, autenticação, gestão Documental, documentos, assinatura digital, desmaterialização, transição digital'
      }
    },
    primavera: {
      meta: {
        title: 'iDOK Integration with Primavera ERP - Automate your Document Management',
        description: 'Integration with Primavera ERP allows financial data to be generated and retrieved directly from document management. In this way, companies can streamline their entire financial management efficiently and contribute to more effective workflows. In short, it allows the entire request process to be computerised, reduces the risk of duplication of information and avoids errors.'
      }
    },
    gestaoSistemasContent: {
      meta: {
        title: 'Efficient Systems Management - iDOK',
        description: 'Achieve maximum efficiency in Systems Management in Portugal. Automate processes, promote dematerialisation and ensure the traceability of your documents. Find out more now!'
      },
      imgTitles: {
        mainAnimation: 'Advanced Systems Management Solutions for Business Efficiency',
        gestaoEficienteImg: 'Comprehensive Solution for Efficient Systems Management. Automation and Dematerialisation to optimise Workflows'
      },
      tools: {
        confidencialidadeDados: "Confidentiality of the user's private data",
        confidencialidadeDadosAlt: 'Assurance of Confidentiality of User Data for Secure and Reliable Systems Management',
        autorizacaoAcesso: 'Authorised access to the platform and documentation, including Auditors',
        autorizacaoAcessoAlt: 'Access Control: Assurance of Authorised Access to the Platform and Documentation',
        limitacaoUtilizador: 'Limits user access to certain documents',
        limitacaoUtilizadorAlt: 'Restriction on Access to Documents',
        registoOperacoes: 'It records all operations as well as the executing agent, by monitoring those involved in Document Management',
        registoOperacoesAlt: 'Registration of transactions and users: Assurance of Transparency in Document Management'
      },
      introductionContainer: {
        mainTitleOne: 'Make ',
        mainTitleTwo: 'Systems Management effective ',
        mainTitleThree: 'with iDOK',
        introductionTextOne: 'Operational efficiency within an organisation, regardless of its sector or size, can be challenging.',
        introductionTextTwo: 'With this challenge on the table, iDOK, a software that is attentive to market needs, has launched the ',
        introductionTextThree: 'Systems Management Module',
        introductionTextFour: ', so that users have the right set of tools in their hands to achieve their objectives.'
      },
      advantagesContainer: {
        mainTitleOne: 'What does ',
        mainTitleTwo: "iDOK's new functionality",
        mainTitleThree: 'enable in this respect?'
      },
      essencialContainer: {
        title: 'What makes iDOK so essential for efficient Systems Management?',
        mainParagraphOne: 'Although it is essential for success in the corporate world, there are few solutions with sufficient capabilities to guarantee integrated management that matches the day-to-day needs of an organisation.',
        mainParagraphTwo: 'Therefore, this new functionality is an asset that allows workflows to be optimised, enables tasks to be automated, focuses on dematerialising information and ensures document traceability.',
        mainParagraphThreeFirst: 'Ultimately, iDOK with its ',
        mainParagraphThreeSecond: 'Systems Management Module',
        mainParagraphThreeThird: ', together with Document Management, will be the answer you are looking for when it comes to Auditing, as it will be a strong ally in the fulfilment of policies, processes and procedures, according to defined responsibilities, access levels, as well as the up-to-date status of documentation.'
      },
      prioridadeContainer: {
        titleOne: 'Our priority is to ensure',
        titleTwo: 'document management, integrity and security'
      },
      areasContainer: {
        titleOne: 'In what areas can the ',
        titleTwo: 'iDOK Systems Management Module ',
        titleThree: 'make a difference?',
        mainParagraphOne: 'With this ',
        mainParagraphTwo: 'new iDOK feature',
        mainParagraphThree: ', you can focus on an integrated management system to promote an organisational culture of excellence.',
        areaOne: 'Food',
        areaTwo: 'Training',
        areaThree: 'Environmental',
        areaFour: 'Information Security',
        areaFive: 'Quality Management',
        areaSix: 'GDPR',
        areaSeven: 'Equipment Management',
        areaEight: 'Human Resources Management',
        areaNine: 'Occupational Health and Safety',
        areaTen: 'Anti-corruption',
        areaEleven: 'Services',
        areaTwelve: 'Complaints Management',
      }
    },
    onlyoffice: {
      meta: {
        title: 'Create and edit documents with the iDOK-ONLYOFFICE integration',
        description: 'Create and edit documents in a variety of formats directly in iDOK, eliminating the need for external tools. Optimize document management with the iDOK-ONLYOFFICE integration and guarantee efficiency and practicality for your teams.'
      },
      introductionContainer: {
        mainOpening: 'WHAT IS ONLYOFFICE?',
        mainTitleOne: 'Create and edit documents with',
        mainTitleTwo: 'iDOK-ONLYOFFICE integration',
        bannerTitle: 'ONLYOFFICE: Online Document Editing Tool integrated with iDOK',
        startInfo: 'Working with iDOK is now even easier with this integration!',
        mainInfo: 'ONLYOFFICE is a tool for editing documents online. It offers a solution for creating files such as texts, spreadsheets and presentations. It is an alternative to Microsoft 365, with the possibility of creating, editing and saving documents in formats compatible with Word, Excel, PowerPoint and PDF.'
      },
      allowContainer: {
        mainTitleOne: 'What does ',
        mainTitleTwo: 'integration with ONLYOFFICE offer?',
        mainText: 'It will allow your teams to efficiently manage the creation and editing of documents in a simple and sophisticated way, making it one of the most complete platforms on the market.'
      },
      infoOnlyoffice: {
        titleImg: 'iDOK-ONLYOFFICE Integration: Efficient Document Creation and Editing',
        fileGroup: 'ONLYOFFICE: Compatible with Word, Excel, PowerPoint and PDF formats',
        paragraphOne: 'The use of a Document Management System results in a source of information about your business activities, and is fundamental for making decisions. Documents are a valuable resource that contain critical information for any Entity or Organization, and it is important that they preserve documents in an organized and valid manner.',
        paragraphTwo: 'The process of circulating internal or external documents, as well as processing information from them, is time-consuming and subject to errors. These can be avoided if entities or organizations opt for solutions that allow them to manage their documents in an automated way.'
      },
      pros: {
        mainTitleOne: 'What are the ',
        mainTitleTwo: 'advantages of integrating ',
        mainTitleThree: 'with ONLYOFFICE?',
        paragraphOne: 'This new solution will help your Entity to ',
        paragraphTwo: 'organize, maintain and control documents easily, ',
        paragraphThree: 'making it possible to follow the entire document lifecycle with automation.',
        listTitle: 'Some of the new advantages are:',
        listFirstOne: 'Create and edit files in various formats, ',
        listFirstTwo: 'such as docx, xlsx, pptx, odt, ott, among others, increasing flexibility for users. This functionality will be available for the other modules of the iDOK platform, such as Templates, Workflows, Processes and Services Portal.',
        listSecondOne: 'Platform integration, ',
        listSecondTwo: 'with no need to switch between different tools or interfaces.',
        listThirdOne: 'Integration with existing workflows ',
        listThirdTwo: 'on the iDOK platform, which guarantees a pleasant transition and a consistent user experience.',
        listFourth: 'Possibility of editing Word, Excel and PowerPoint documents even without Office installed.'
      }
    },
    selosTemporais: {
      meta: {
        title: 'Timestamps in Document Management - iDOK Integration',
        description: 'Timestamps certify the date/time of creation, sending or receipt of a document or electronic and/or commercial transaction. They are therefore essential for a rigorous and efficient Document Management. Provide authenticity, integrity and security to your documents sent electronically.'
      },
      tools: {
        documentoVinculado: 'They certify the existence of a document linked to a specific point in time (date/time)',
        documentoVinculadoAlt: 'Guarantee of document creation/modification time',
        documentoModificado: 'They prove that the document has not been modified',
        documentoModificadoAlt: 'Prevents document manipulation'
      },
      introductionContainer: {
        mainTitleOne: 'Timestamps',
        mainTitleTwo: 'in Document Management',
        question: 'What are Timestamps?',
        questionAnswer: 'Timestamps certify the date/time of creation, sending or receipt of a document or an electronic and/or commercial transaction. They are therefore essential for a rigorous and efficient Document Management.'
      },
      advantagesContainer: {
        mainTitleOne: 'What do',
        mainTitleTwo: 'Timestamps',
        mainTitleThree: 'guarantee for an entity?'
      },
      securityContainer: {
        title: 'What is the importance of Timestamps in Document Management?',
        mainParagraphOne: 'Within the scope of Document Management, it is essential to be able to guarantee the authenticity, integrity and security of your documents, as vitually all document transactions are nowadays carried out electronically.',
        mainParagraphTwo: 'In this regard, it is essential to ensure that your documents remain reliable over time and can thus be used as evidence in judicial or administrative proceedings. In fact, the use of Timestamps prevents the manipulation of digitally processed documents.',
        mainParagraphThree: 'By purchasing Timestamps, you are increasing the robustness and security of your Document Management. Ultimately, you are also investing in the credibility of your documents, which is essential for audits, administrative records or legal proceedings.',
        securityShieldTitle: 'Document Management Security'
      },
      gtsIntegrationContainer: {
        mainTitleOne: 'GTS Timestamps',
        mainTitleTwo: 'Global Trusted Sign',
        mainParagraphOne: 'To guarantee the best Document Management on the market for your entity, iDOK is integrated with the Trusted Service Provider GTS - Global Trusted Sign, which offers valid and reliable Interoperable Timestamps.',
        mainParagraphTwo: 'Maintain the credibility and security of your Document Management with the help of the iDOK integration with ',
        mainParagraphThree: 'Global Trusted Sign',
        mainParagraphFour: '. Check the price list for Timestamps and start investing in the authenticity of your documentation now!',
        buyNow: 'Buy now',
        gtsIntegrationAlt: 'Integration of iDOK Document Management Software with GTS Timestamps'
      }
    },
    integracaoIdok: 'iDOK INTEGRATION',
    gestaoSistemasMainTitle: 'SYSTEMS MANAGEMENT',
    whatIs: 'What is',
    conceitoPrimaveraUm: '',
    conceitoPrimaveraTres: 'ERP Primavera',
    conceitoPrimaveraQuatro: 'is an integrated management system for finance, accounting, purchasing, inventories, production, assets, marketing, human resources, among other processes.',
    conceitoPrimaveraDois: 'It is a software that focuses on the centralisation of information, providing functionalities that optimise the internal processes of organisations.',
    toolsTitleOne: 'What does the ',
    toolsTitleTwo: 'integration of iDOK',
    toolsTitleThree: 'with ERP Primavera allow?',
    sincronizacaoArtigosText: 'Synchronisation of suppliers, articles, cost centres, activities, among others.',
    sincronizacaoArtigosAlt: 'Synchronisation of suppliers, items, costs, etc.',
    documentosFinanceirosText: 'Creation and collection of details of financial documents',
    documentosFinanceirosAlt: 'Creation of Financial Documents',
    estadoEncomendasText: 'Consultation of the status of orders',
    estadoEncomendasAlt: 'Consult order status',
    elaboracaoProcedimentosText: 'Creation of procurement procedures in the contracts module',
    elaboracaoProcedimentosAlt: 'Creation of public procurement procedures',
    usabilidadeTitleUm: 'How does ',
    usabilidadeTitleDois: 'the usability process work for the end user?',
    usabilidadePassoUm: 'The request starts in the document management through a workflow adapted to the functioning of your organisation.',
    usabilidadePassoDois: 'ERP Primavera is used to obtain relevant information, such as items, cost centres, among others.',
    usabilidadePassoTres: 'ERP Primavera returns the financial information or documents',
    usabilidadePassoQuatro: 'The integration process has been completed',
    imagemPrimaveraAltTitle: 'Screens of the iDOK Document Management Software and the ERP Primavera',
    integracoesVantagensTitleUm: 'What are the',
    integracoesVantagensTitleDois: 'advantages',
    integracoesVantagensTitleTres: 'of this integration?',
    integracoesVantagensTextoUm: 'Integration with ERP Primavera allows financial data to be generated and retrieved directly from document management. This allows companies to',
    integracoesVantagensTextoDois: 'streamline all financial management efficiently and accurately',
    integracoesVantagensTextoTres: 'and contributes to more efficient workflows. In short, it computerises the entire order process, reduces the risk of duplication of information and avoids errors',
    integracoesProcessoTitleUm: 'Does an',
    integracoesProcessoTitleDois: 'integrated process',
    integracoesProcessoTitleTres: 'simplify document management?',
    integracoesProcessoTextoUm: 'The direct integration of iDOK with the solution',
    integracoesProcessoTextoDois: 'EDI link - Electronic Invoices',
    integracoesProcessoTextoTres: 'streamlines the invoice transmission process, automatically, efficiently and securely.',
    integracoesProcessoTextoQuatro: 'Another integrated functionality is the',
    integracoesProcessoTextoCinco: 'GTS - Global Trusted Sign Digital Signature',
    integracoesProcessoTextoSeis: 'which makes it possible to sign documents remotely, allowing more flexibility in your document management.',
    duvidasTitle: 'Do you have any doubts?',
    integrationDiagramImg: 'enDiagramaIntegracao',
    integrationDiagramTitleAlt: 'iDOK Document Management Software integration with ERP Primavera'
  },
};
