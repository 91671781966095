import { makeStyles, createStyles } from '@material-ui/core';

const selectLanguageStyle = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: '10px 10px',
      '& .MuiInputBase-formControl': {
        borderRadius: 25,
        minHeight: 30,
        minWidth: 60,
      },
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: 'solid 1px #DEDEDE',
      },
    },
    selectMenu: {
      padding: 0,
      paddingLeft: 12,
      fontSize: 16,
      fontFamily: 'Poppins',
      color: 'gray',
      [theme.breakpoints.only('sm')]: {
        color: 'white',
      },
      [theme.breakpoints.only('xs')]: {
        color: 'white',
      },
      '&:focus': {
        borderRadius: 25,
        minHeight: 27,
        paddingTop: 3,
      },
    },
    icon: {
      width: 8,
      left: 40,
      color: 'gray',
      [theme.breakpoints.only('sm')]: {
        fill: 'white',
      },
      [theme.breakpoints.only('xs')]: {
        fill: 'white',
      },
    },
    paper: {
      minWidth: 'none',
      marginTop: 40,
      top: '16px !important',
    },
    list: {
      '& .MuiMenuItem-root': {
        fontSize: 13,
      },
    },
  })
);
export default selectLanguageStyle;
