import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface StyleProps {
  color: string
  emptyBox: boolean;
};

const cardStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    root: {
      width: '200px',
      height: '200px',
      background: ({emptyBox}) => `${emptyBox ? 'transparent' : '#fff'} 0% 0% no-repeat padding-box`,
      boxShadow: ({emptyBox}) => `${emptyBox ? 'none' : '0px 0px 15px #00000021'}`,
      marginTop: '50px',
      marginLeft: '50px',
      opacity: 1,

      [theme.breakpoints.down('xs')]: {
        marginLeft: '35px',
        width: '186px',
        height: '186px',
        marginTop: '30px',
      },
    },

    colorBox: {
      backgroundColor: ({color}) => color,
      width: '18.64px',
      height: '18.64px',
    },

    title: {
      paddingTop: '17px',
      paddingLeft: '18.73px',
    },
    titleText: {
      textAlign: 'left',
      font: 'normal normal 600 24px/28px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
      opacity: 1,

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 22px/28px Poppins',
      },
    },

    description: {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop:'5px',
      height: '47px',
    },

    descriptionText: {
      textAlign: 'left',
      font: 'normal normal normal 16px/20px "Source Sans 3", Helvetica, Arial, sans-serif',
      letterSpacing: '0px',
      color: '#404040',
      opacity: 1,

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal normal 14px/20px "Source Sans 3", Helvetica, Arial, sans-serif',
      },
    },
    link: {
      paddingLeft:'18.73px',
      marginTop: '27.96px',
      '& a': {
        textAlign: 'left',
        textDecoration: 'underline',
        font: 'normal normal normal 12px/16px Poppins',
        letterSpacing: '0px',
        color: ({color}) => color,
        opacity: 1,

        [theme.breakpoints.down('xs')]: {
          font: 'normal normal normal 10px/16px Poppins',
        },
      }
    },
  })
);

export default cardStyles;