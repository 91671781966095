import React, {
  FC
} from 'react';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import footerStyle from './Footer.styles';
import Moradas from './components/address/Address';
import Creditos from './components/credit/Credit';
import FooterWebLayout from './WebLayout';
import FooterTabletLayout from './TabletLayout';

export interface FooterProps {
  footerShadow?: boolean
};

const Footer: FC<FooterProps> = ({
  footerShadow=false
}) => {
  const classes = footerStyle({footerShadow});
  const theme = useTheme();
  const webLayout = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid container className={classes.container}>
      {
        webLayout ?
          <FooterWebLayout 
            footerShadow={footerShadow}
          />
        :
          <FooterTabletLayout
            footerShadow={footerShadow}
          />
      }
      <div className={classes.moradasContent}>
        <Moradas />
      </div>
      <Creditos />
    </Grid>
  );
};
export default Footer;
