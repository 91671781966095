import AppStoreLogo from './assets/icons/idok-badge-appstore.svg';
import AppStoreLogoES from './assets/icons/idok-badge-appstore-es.svg';
import AppStoreLogoEN from './assets/icons/idok-badge-appstore-en.svg';
import GooglePlayLogo from './assets/icons/badge-googleplay-pt.svg';
import GooglePlayLogoES from './assets/icons/badge-googleplay-es.svg';
import GooglePlayLogoEN from './assets/icons/badge-googleplay-en.svg';
import AppGaleryLogo from './assets/icons/idok-badge-appgallery.svg';
import AppGaleryLogoES from './assets/icons/idok-badge-appgallery-es.svg';
import AppGaleryLogoEN from './assets/icons/idok-badge-appgallery-en.svg';

type Addresses = {
  porto: PlacesDetails;
  madeira: PlacesDetails;
  lisboa: PlacesDetails;
  acores: PlacesDetails;
};

interface PlacesDetails {
  place: string;
  local: string;
  address: string;
  zipcode: string;
  href: string;
}

type Contacts = {
  phone: string;
  email: string;
};

type Socials = {
  linkedin: string;
  youtube: string;
};

type Links = {
  privacyPolicy: string;
  terms: string;
  suggestions: string;
};

type LoginContact = {
  name: string,
  text1: string,
  text2: string,
  phone: string,
  email: string,
  web: string
};

type PrivateLoginContact = {
  entity: LoginContact,
  support: LoginContact
};

type FooterContent = {
  title: string,
  text1: string,
  text2: string,
};

type PlatformsUrl = {
  igest: string,
  altIgest: string,
  omeutaxi: string,
  altOmeuTaxi: string,
  ifreg: string,
  altIfreg: string,
  acingov: string,
  altAcinGov: string,
  paypay: string,
  altPayPay: string,
  gts: string,
  altGts: string,
  imed: string,
  altImed: string,
  iparque: string,
  altIparque: string,
  irh: string,
  altIrh: string,
  comprasDoEstado: string,
  altComprasDoEstado: string,
  ilink: string,
  altIlink: string,
  agroMarket: string,
  altAgroMarket: string,
  denuncias: string,
  altDenuncias: string
};

type Languages = {
  PT: string,
  GB: string,
  ES: string
};

type urlList = {
  aboutUs: string,
  advantages: string,
  adaptation: string,
  possibleBrowsers: string,
  digitalSignature: string,
  sendAndReceiveDocument: string,
  documentReception: string,
  flowCharts: string,
  govAutentication: string,
  users: string,
  customPermissions: string,
  restrictAccess: string,
  support: string,
};

interface Constants {
  addresses: Addresses;
  contacts: Contacts;
  socials: Socials;
  links: Links;
  privateLoginContact: PrivateLoginContact;
  footerContent: FooterContent;
  platforms: PlatformsUrl;
  languages: Languages;
  isoCertifiedUrl: string;
  w3Org: string;
  urlMarketingVideo: string;
  urlList: urlList;
}

export const constants: Constants = {
  addresses: {
    porto: {
      place: 'Porto',
      local: 'Tecmaia',
      address: 'Rua Eng. Frederico Ulrich 2650',
      zipcode: '4470-605',
      href:
        'https://google.pt/maps/place/ACIN+-+iCloud+Solutions+-+Porto/@41.2587977,-8.6435667,17z/data=!3m1!4b1!4m5!3m4!1s0xd2467e8569a452f:0xfb5ca1114f938fda!8m2!3d41.2587937!4d-8.641378',
    },
    lisboa: {
      place: 'Lisboa',
      local: 'Rua Domingos Monteiro',
      address: 'N.º 7 A',
      zipcode: '1050-074',
      href:
        'https://google.com/maps/place/ACIN+-+iCloud+Solutions+-+Lisboa/@38.7446763,-9.1487847,17z/data=!3m1!4b1!4m5!3m4!1s0xd1931fa7f89f40b:0xde35c286b3855019!8m2!3d38.7446763!4d-9.146596',
    },
    madeira: {
      place: 'Madeira',
      local: 'Estrada Regional 104 N.º42-A',
      address: 'Ribeira Brava',
      zipcode: '9350-203',
      href:
        'https://google.pt/maps/place/ACIN+-+iCloud+Solutions/@32.6790185,-17.0621327,17z/data=!3m1!4b1!4m5!3m4!1s0xc6059a3d538596b:0xa393204f0d195615!8m2!3d32.679014!4d-17.059944',
    },
    acores: {
      place: 'Açores',
      local: 'Rua da Alegria N.º 101',
      address: 'Ponta Delgada',
      zipcode: '9500-157',
      href:
        'https://google.pt/maps/place/ACIN+-+iCloud+Solutions+-+A%C3%A7ores/@37.7369822,-25.6785327,17z/data=!3m1!4b1!4m5!3m4!1s0xb432bb9e38b6363:0x4f9bff9f6e7e0837!8m2!3d37.736978!4d-25.676344',
    },
  },
  contacts: {
    phone: '707 451 451',
    email: 'apoio@idok.pt'
  },
  socials: {
    linkedin: 'https://linkedin.com/showcase/idok-gestao-documental',
    youtube: 'https://youtube.com/channel/UCWvV3Nc-OKHj-CRXhYz5kWg/feed',
  },
  links: {
    privacyPolicy: 'https://acin.pt/politica-privacidade/',
    terms: window.localStorage.getItem('idokUrl') + '/public/cgadesao',
    suggestions: 'https://livroreclamacoes.pt/',
  },
  privateLoginContact: {
    entity: {
      name: 'Entidade Gestora',
      text1: 'DRPI',
      text2: 'Direção Regional de Património e informatica',
      phone: '(+351)291212199',
      email: 'drpi@madeira.gov.pt',
      web: 'madeira.gov.pt'
    } ,
    support: {
      name: 'Suporte Técnico',
      text1: 'ACIN group',
      text2: 'ACIN iCloud Solutions',
      phone: '707451451',
      email: 'apoio@idok.pt',
      web: 'acin.pt',
    }
  },
  footerContent:{
    title:'Governo Regional da Madeira',
    text1:'iDOK, 2020',
    text2: 'Todos os direitos reservados | Direitos de Propriedade | Política de Privacidade',
  },
  platforms: {
    igest: 'https://igest.pt',
    altIgest: 'Programa de Faturação',
    omeutaxi: 'https://omeutaxi.pt',
    altOmeuTaxi: 'Programa de Faturação para táxis',
    ifreg: 'https://ifreg.pt',
    altIfreg: 'Gestão de Freguesias',
    acingov: 'https://acingov.pt',
    altAcinGov: 'Plataforma Eletrónica de Compras Públicas',
    paypay: 'https://paypay.pt',
    altPayPay: 'Solução de Pagamentos Online',
    gts: 'https://globaltrustedsign.com',
    altGts: 'Assinatura digital',
    imed: 'https://imed.pt',
    altImed: 'Software de Gestão Clínica',
    iparque: 'https://iparque.pt',
    altIparque: 'Software de Gestão de Estacionamentos',
    irh: 'https://irh.pt',
    altIrh: 'Software de Gestão de Assiduidade',
    comprasDoEstado: 'https://comprasdoestado.pt',
    altComprasDoEstado: 'Portal de pesquisa de Concursos Públicos',
    ilink: 'https://ilink.pt',
    altIlink: 'EDI e Faturação Eletrónica',
    agroMarket: 'https://agromarket.pt',
    altAgroMarket: 'Plataforma de venda de produtos agrícolas',
    denuncias: 'https://portaldedenuncias.pt',
    altDenuncias: 'Plataforma de gestão de denúncias'
  },
  languages: {
    PT: 'PT',
    ES: 'ES',
    GB: 'EN'
  },
  isoCertifiedUrl: 'http://sgs.com/en/certified-clients-and-products/certified-client-directory',
  w3Org: 'https://www.w3.org/WAI/WCAG2AAA-Conformance',
  urlMarketingVideo: "https://youtube.com/embed/fY_5dFOob1A?autoplay=1",
  urlList: {
    aboutUs: 'que-e-o-idok',
    advantages: 'vantagens-de-gestao-documental',
    adaptation: 'o-idok-para-minha-entidade',
    possibleBrowsers: 'browsers-para-gestao-documental-idok',
    digitalSignature: 'assinaturas-digitais-na-plataforma-idok',
    sendAndReceiveDocument:'enviar-e-receber-documentos-de-outras-entidades',
    documentReception: 'receber-documentos-de-outras-plataformas',
    flowCharts: 'criacao-de-fluxos-em-idok',
    govAutentication: 'para-que-serve-autenticacaogov',
    users: 'utilizadores-no-idok',
    customPermissions: 'permissoes-personalizaveis',
    restrictAccess: 'restringir-acesso-aos-utilizadores',
    support: 'suporte-telefonico-gratuito-no-idok',
  },
};

export const mobileStores = [
  {
    description: 'Android',
    company: '(Google),',
    url: 'https://play.google.com/store/apps/details?id=com.idokmobile',
    alt: 'GooglePlay',
    logo: {
      PT: GooglePlayLogo,
      GB: GooglePlayLogoEN,
      ES: GooglePlayLogoES
    }
  },
  {
    description: 'iOS',
    company: '(Apple),',
    url: 'https://apps.apple.com/pt/app/idok/id1513397368',
    alt: 'AppStore',
    logo: {
      PT: AppStoreLogo,
      GB: AppStoreLogoEN,
      ES: AppStoreLogoES
    }
  },
  {
    description: 'HarmonyOS',
    company: '(Huawei);',
    url: 'https://appgallery.huawei.com/app/C104912361',
    alt: 'AppGallery',
    logo: {
      PT: AppGaleryLogo,
      GB: AppGaleryLogoEN,
      ES: AppGaleryLogoES
    }
  }
];

export const gtsUrl = 'https://globaltrustedsign.com/';
export const assinaturaDigitalUrl = 'https://globaltrustedsign.com/public_c/products_info/qualified_signature';

export const acinLink = {
  PT: "https://acin.pt/",
  GB: "https://acin.pt/en",
  ES: "https://acin.pt/es"
}
