import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Hidden  from '@material-ui/core/Hidden';
import Grid  from '@material-ui/core/Grid';
import Integrations from '../../../components/sections/integrations/IntegrationsComponent';
import Newsletter from '../../../components/sections/newsletter/NewsletterComponent';
import Prices from '../../../components/sections/prices/PricesComponent';
import FreeTrial from '../../../components/sections/free-trail/FreeTrial';
import Mobile from '../../../components/sections/Mobile';
import { TrialFormContext } from '../../../context/TrialFormContext';
import i18next from 'i18next';
import Buttons from './Buttons';
import Acin from '../../sections/acin/AcinComponent';
import Features from '../../sections/features/FeaturesComponent';
import Home from '../../sections/home/HomeComponent';

function Content() {
  const { closeForm } = useContext(TrialFormContext);
  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang.toUpperCase());
    }

    return () => closeForm();
  }, [closeForm, lang]);

  return (
    <Grid container direction="column" alignContent="center">
      <Home />
      <Features />
      <Integrations />
      <FreeTrial />
      <Prices />
      <Mobile />
      <Acin />
      <Newsletter boxPosition="right" />
      <Hidden xlDown>
        <Buttons />
      </Hidden>
    </Grid>
  );
}

export default Content;
