import React, { createContext, useState, useCallback } from 'react';
import { generateUniqueId } from '../utils/Utils';

interface ITrialFormContext {
  showForm: boolean;
  leadsId: string;
  onToggleForm: () => void;
  closeForm: () => void;
}

export const TrialFormContext = createContext<ITrialFormContext>({} as ITrialFormContext);

const leadsId = generateUniqueId();

function TrialFormProvider({children}) {
  const [showForm, setShowForm] = useState(false);

  const onToggleForm = useCallback(() => {
    setShowForm(prevState => !prevState);
  }, [setShowForm])

  const closeForm = useCallback(() => {
    setShowForm(false);
  }, [setShowForm])

  return (
    <TrialFormContext.Provider value={{ showForm, onToggleForm, closeForm, leadsId }}>
      {children}
    </TrialFormContext.Provider>
  )
}

export default TrialFormProvider;
