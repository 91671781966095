import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const buttonStyle = makeStyles((theme: Theme) =>
  createStyles({
    inputText: {
      borderBottom: '1px solid #808080',
    },
    button:{
      background: '#005CB9 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      opacity: 1,
      width: '200px',
      height:'50px',

      textAlign: 'center',
      font: 'normal normal 600 16px/25px Poppins',
      letterSpacing: '0px',
      color: '#FFFFFF',
      textTransform: 'uppercase',

      '&:hover': {
        background: '#005CB9 0% 0% no-repeat padding-box',
      },
    }
  })
);

export default buttonStyle;
