import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface StyleProps {
  footerShadow: boolean
};

const footerStyle = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    container: {
      minHeight: 120,
      paddingTop: '10%',
      fontSize: '16px',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      boxShadow: ({footerShadow}) => footerShadow ? '0px -3px 6px #00000010' : 'none',
    },

    content: {
      width: '75%',
      display: 'flex',
      flexDirection:'row',
      justifyContent:'space-between',

      [theme.breakpoints.down(1280)]: {
        width: '85%',
      },
      [theme.breakpoints.down(1020)]: {
        width: '90%',
      },
      [theme.breakpoints.down(960)]: {
        width: '80%'
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent:'center',
      },
    },

    isoLink:{
      cursor: 'pointer',
      width: '76px',
      marginRight: '32px',
      marginLeft: '32px',

      [theme.breakpoints.down(960)]: {
        marginRight: '32px',
      },

      [theme.breakpoints.down(600)]: {
        marginRight: '10px',
        marginLeft: '10px',
      },
    },
    isoLinkNormal:{
      cursor: 'pointer',
      width: '76px',
    },

    moradasContent: {
      width: '75%',
    },
    otherContent:{
      width: '75%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.down(960)]: {
        width: '80%'
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
      },
    },
    menu: {
      [theme.breakpoints.down('xs')]: {
        width: '215px',
        textAlign: 'center',
        paddingTop: '50px',
      },
    },
    solucao: {
      fontWeight: 400,
      fontSize: '16px',

      [theme.breakpoints.only('xs')]: {
        display: 'block',
        textAlign: 'center',
        paddingBottom: '6px'
      },
    },
    box: {
      marginBottom: 20,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        width: '100%',
        display: 'block',
        margin: 0
      },
      '& a': {
        textDecoration: 'none',
        color: '#1d1d1d',
        fontWeight: 400,
        fontSize: '16px'
      }
    }
  })
);

export default footerStyle;
