import {
  Document,
  Solution,
  Automation,
  GestaoSistemas,
  PortalServicos,
  Safety,
  Workflow,
  Processes,
  DigitalSignature
} from '../../icons';

export const features = (classes, translation) => {
  return [
    {
      className: classes.firstRow,
      classNameRoot: classes.root,
      icon: Document,
      title: translation('documentManagementTitle'),
      content: translation('documentManagementContent'),
      color: classes.firstIcon,
      background: classes.firstBackground,
      iconExpanded: classes.firstIconExpanded,
      hasDetails: true,
      details: [
        translation('modelManagement'),
        translation('documentClassification'),
        translation('opticalCharacterRecognition'),
        translation('filesManagement'),
        translation('documentSeries'),
        translation('customisableFields'),
        translation('mef'),
        translation('associationOfResponseDeadlines'),
      ],
    },
    {
      className: classes.firstRow,
      classNameRoot: classes.root,
      icon: Workflow,
      title: translation('workflowTitle'),
      content: translation('workflowContent'),
      color: classes.secondIcon,
      background: classes.secondBackground,
      iconExpanded: classes.secondIconExpanded,
      hasDetails: true,
      details: [
        translation('documentsDistribution'),
        translation('processingFlows'),
        translation('deadlinesAndReports'),
        translation('flowchartsCreation'),
        translation('sendingAlertsAndEmails'),
        translation('rulesOfCommunicationWithWebServices'),
        translation('conectionWithPortals'),
      ],
    },
    {
      className: classes.firstRow,
      classNameRoot: classes.root,
      icon: Processes,
      title: translation('processesTitle'),
      content: translation('processesContent'),
      color: classes.thirdIcon,
      background: classes.thirdBackground,
      iconExpanded: classes.thirdIconExpanded,
      hasDetails: true,
      details: [
        translation('customisableFields'),
        translation('personalProcessManagement'),
        translation('exportProcessListing'),
        translation('assignmentOfManagers'),
        translation('accessCentralized'),
        translation('alertsAndNotifications'),
        translation('clasificationPlan'),
      ],
    },
    {
      className: classes.secondRow,
      classNameRoot: classes.root,
      icon: Safety,
      title: translation('safetyTitle'),
      content: translation('safetyContent'),
      color: classes.fourthIcon,
      background: classes.fourthBackground,
      iconExpanded: classes.fourthIconExpanded,
      hasDetails: true,
      details: [
        translation('restrictedUserAccess'),
        translation('reports'),
        translation('accessRegistration'),
        translation('protectedInformation'),
      ],
    },
    {
      className: classes.secondRow,
      classNameRoot: classes.root,
      icon: DigitalSignature,
      title: translation('digitalSignatureTitle'),
      content: translation('digitalSignatureContent'),
      secondContent: translation('digitalSignatureSecondContent'),
      digitalSignatureHref: 'https://globaltrustedsign.com/public_c/products_info/qualified_signature',
      batchSubscriptionHref: 'https://globaltrustedsign.com/public_c/mobile',
      color: classes.fifthIcon,
      background: classes.fifthBackground,
      iconExpanded: classes.fifthIconExpanded,
      hasDetails: true,
      details: [
        translation('ownershipGuarantee'),
        translation('severalSignatures'),
        translation('batchSubscription')
      ],
    },
    {
      className: classes.secondRow,
      classNameRoot: classes.root,
      icon: Automation,
      title: translation('customAutomationTitle'),
      content: translation('customAutomationContent'),
      color: classes.sixthIcon,
      background: classes.sixthBackground,
      iconExpanded: classes.sixthIconExpanded,
      hasDetails: true,
      details: [
        translation('automaticIndex'),
        translation('notificationAlert'),
        translation('fluxoDinamico'),
        translation('integracaoSistemasExterno'),
        translation('gestaoVersaoAutomatica'),
        translation('gestaoModelos'),
      ],
    },
    {
      className: classes.thirdRow,
      classNameRoot: classes.root,
      icon: GestaoSistemas,
      title: translation('customGestaoSistemasTitle'),
      content: translation('customGestaoSistemasContent'),
      color: classes.seventhIcon,
      background: classes.seventhBackground,
      iconExpanded: classes.seventhIconExpanded,
      hasDetails: false,
      linkToNavigate: '/gestao-sistemas',
      details: [],
    },
    {
      className: classes.thirdRow,
      classNameRoot: classes.root,
      icon: PortalServicos,
      title: translation('customPortalServicosTitle'),
      content: translation('customPortalServicosContent'),
      color: classes.eighthIcon,
      background: classes.eighthBackground,
      iconExpanded: classes.eighthIconExpanded,
      hasDetails: true,
      details: [
        translation('registoAutenticacao'),
        translation('solicitacaoPedidos'),
        translation('envioDadosPedidos'),
        translation('geracaoPagamentos'),
        translation('envioSmsEmail'),
        translation('gestaoReservas'),
        translation('geracaoDocumentosDinamicos'),
      ],
    },
    {
      className: classes.thirdRow,
      classNameRoot: classes.root,
      icon: Solution,
      title: translation('customSolutionTitle'),
      content: translation('customSolutionContent'),
      color: classes.ninthIcon,
      background: classes.ninthBackground,
      iconExpanded: classes.ninthIconExpanded,
      hasDetails: true,
      details: [
        translation('emailStructure'),
        translation('departmentManagement'),
        translation('billingInquiry'),
        translation('entityManagement'),
        translation('relatories'),
      ],
    },
  ]
}
