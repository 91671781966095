import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';


const carrousselStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselPage: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-arround',
      flexWrap: 'wrap',
      padding: '0 0 30px 75px',

      [theme.breakpoints.down(960)]: {
        padding: '0 0 30px 30px',
      },
      
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'inherit',
        padding: '0 0 30px 0',
      },
    }
  })
);

export default carrousselStyles;