import React, {
  FC,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { constants, acinLink } from '../../../../global-constants';
import useStyles from './AcinGroupTablet.styles';
import Iso20000 from '../../../../assets/images/Iso20000';
import Iso27001 from '../../../../assets/images/Iso27001';
import Iso9001 from '../../../../assets/images/Iso9001';
import Caroussel from '../carousselComponent/Carroussel';

const AcinGroupMobile: FC<{cards: any, cardsLength: number}> = ({cards, cardsLength}) => {
  const { isoCertifiedUrl } = constants;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.dataContent}>

        {/* Green Box */}
        <div className={classes.greenBox} />

        {/* Title */}
        <div className={classes.title}>
          <Typography variant="subtitle1" className={classes.titleText} >
            {t('acinGroup.leftSide.title.quality')}
            <span className={classes.greenLetter}>
              {t('acinGroup.leftSide.title.security')}
            </span>
          </Typography>
        </div>

        {/* Paragraph */}
        <div className={classes.textContent}>
          {/* Description */}
          <Typography variant="body1" className={classes.description}>
            {t('acinGroup.leftSide.firstText.start')}
            <a href={acinLink[i18n.language]} target="_blank" rel="noopener noreferrer">{t('acinGroup.leftSide.firstText.link')}</a>
            {t('acinGroup.leftSide.firstText.end')}
          </Typography>

          {/* ISO Rules */}
          <Typography variant="body1" className={classes.isoContent}>
            <span className={classes.isoTitle}>
              {t('acinGroup.leftSide.iso9001')}
            </span>
            <span className={classes.description}>
              {t('acinGroup.leftSide.iso9001Desc')}
            </span>
          </Typography>

          {/* Third Line */}
          <Typography variant="body1" className={classes.isoContent}>
            <span className={classes.isoTitle}>
              {t('acinGroup.leftSide.iso27001')}
            </span>
            <span className={classes.description}>
              {t('acinGroup.leftSide.iso27001Desc')}
            </span>
          </Typography>

          {/* Fourth Line */}
          <Typography variant="body1" className={classes.isoContent}>
            <span className={classes.isoTitle}>
              {t('acinGroup.leftSide.iso20000')}
            </span>
            <span className={classes.description}>
              {t('acinGroup.leftSide.iso20000Desc')}
            </span>
          </Typography>
        </div>

        <div className={classes.isoImagesContent}>
          <div className={classes.imageBottom}>
            <Iso9001 className={classes.isoLink} onClick={() => window.open(isoCertifiedUrl, "_blank")}/>
          </div>

          <div className={classes.imageBottom}>
            <Iso27001 className={classes.isoLink} onClick={() => window.open(isoCertifiedUrl, "_blank")}/>
          </div>

          <div className={classes.imageBottom}>
            <Iso20000 className={classes.isoLink} onClick={() => window.open(isoCertifiedUrl, "_blank")}/>
          </div>
        </div>


      </Grid>
      <Grid item xs={12} className={classes.platformContent}>
        <div className={classes.dataPlatform}>
          <div className={classes.platformTitle}>
            {/* Title */}
            <Typography variant="body1">
              <span className={classes.plusPlatform}>
                {t('acinGroup.rightSide.platform')}
              </span>
              <span className={classes.normalTitle}>
                {t('acinGroup.rightSide.rest')}
              </span>
            </Typography>
          </div>

          <div className={classes.knowMoreDiv} />
        </div>

        <div className={classes.carousel}>
          <Caroussel
            cards={cards}
            cardsLength={cardsLength}
          />
        </div>
      </Grid>

    </Grid>
  )
};

export default AcinGroupMobile;
