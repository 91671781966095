import React, {
    FC,
    useEffect,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BaseErrorProps } from '../../Interfaces';
import BaseErrorWeb from './baseErrorLayout/BaseErrorWeb';
import BaseErrorMobile from './baseErrorLayout/BaseErrorMobile';
import BaseErrorStyle from './BaseError.styles';
import lottie401 from '../../assets/animations/error401.json';
import lottie403 from '../../assets/animations/error403.json';
import lottie404 from '../../assets/animations/error404.json';
import lottie500 from '../../assets/animations/error500.json';


const defaultOptions = {
  loop: false,
  autoplay: true,
};

const BaseError: FC<BaseErrorProps> = ({
  name,
  dataOptions,
  onClick,
  buttonsComponent,
}) => {
  let component = <></>;
  const classes = BaseErrorStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const webLayout = useMediaQuery(theme.breakpoints.up('md'));
  let animationObj;

  switch (name) {
    case 'error401':
      animationObj = lottie401;
      break;
    case 'error403':
      animationObj = lottie403;
      break;
    case 'error404':
      animationObj = lottie404;
      break;
    case 'error500':
      animationObj = lottie500;
      break;
    default:
      animationObj = '';
      break;
  }

  const finalOptions = {
    ...defaultOptions,
    animationData: animationObj
  };

  const messageComponent = (dataOptions.msg) ? (
    <div className={classes.message}>{dataOptions.msg}</div>
  ) : null;

  const buttons = buttonsComponent || (
    <Button className={classes.backButton} onClick={onClick}>
        {t("goback")}
    </Button>
  );

  if (webLayout) {
    component =
      <BaseErrorWeb
        dataOptions={dataOptions}
        messageComponent={messageComponent}
        buttons={buttons}
        finalOptions={finalOptions}
      />
  } else {
    component =
      <BaseErrorMobile
        dataOptions={dataOptions}
        messageComponent={messageComponent}
        buttons={buttons}
        finalOptions={finalOptions}
      />
  };

  return component
};

export default BaseError;