import { useCallback, useEffect, useRef } from 'react';

interface IProps {
  intersectionCallback: () => void;
  observerConfig?: {
    root: HTMLElement | null;
    rootMargin: string;
    threshold: number;
  }
}

const defaultConfig = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0
}

const useIntersectionObserver = ({intersectionCallback, observerConfig}: IProps) => {
  const intersectionRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const callback = useCallback((entries) => {

    if(entries[0].isIntersecting) {
      intersectionCallback();
      observerRef.current?.disconnect();
    }

  }, [intersectionCallback]);

  useEffect(() => {
    if(!intersectionRef.current) return;

    const config = {...defaultConfig, ...observerConfig};

    observerRef.current = new IntersectionObserver(callback, config);
    observerRef.current.observe(intersectionRef.current);

    return () => {
      observerRef.current?.disconnect();
    }

  }, [intersectionRef, callback, observerConfig]);

  return {intersectionRef};

}

export default useIntersectionObserver;
