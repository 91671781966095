import * as React from "react"

function RightArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.786}
      height={21.94}
      viewBox="0 0 11.786 21.94"
      {...props}
    >
      <title>
        Right Arrow
      </title>
      <path
        d="M11.782 10.968a.749.749 0 01-.221.532L1.275 21.72a.751.751 0 01-1.058-1.065l9.751-9.687L.217 1.28A.751.751 0 011.275.215l10.286 10.22a.752.752 0 01.221.533z"
        fill="#1d1d1b"
      />
    </svg>
  )
}

export default RightArrow;