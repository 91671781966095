import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { boxParams, platformParams } from './Utils';
import AcinStyle from './Acin.styles';
import Box from './components/Boxes';
import Platform from './components/Platforms';
import { acinLink } from '../../../global-constants';

function Acin() {
  const classes = AcinStyle();
  const { t: translation, i18n } = useTranslation();
  const theme = useTheme();
  const mobileLayout = useMediaQuery(theme.breakpoints.down('xs'));

  const infoPaper = (
    src: string,
    alt: string,
    highlight: string,
    firstNormal: string,
    secondNormal: string
  ) => (
    <Grid item xs={12} sm={4} key={src}>
      <Paper className={classes.paperContent}>
        <img width="130.21" height="118.59" title={alt} src={src} alt={alt} className={classes.image} />
        <Typography variant="body1" className={classes.caption}>
          <b>{translation(highlight)}</b> {translation(firstNormal)}
          <br /> {translation(secondNormal)}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Grid container direction="row" className={classes.container} id="acin">
      <Grid item xs={12} lg={6} className={classes.leftColumn}>
        <div className={classes.wrapperTitle}>
          <Typography variant="overline" component="h3" className={classes.title}>
            <span className={classes.wrapperFirstLetter}>
              {translation('normalTitleAcin')}
              {/* <br /> */}
              {' '}
            </span>
            <span className={classes.textHighlight}>{translation('highlightTitleAcin')}</span>
          </Typography>
        </div>
        <div className={classes.descriptionContent}>
          <Typography variant="body1" className={classes.contentTop}>
            {translation('firstParagraphAcinInit')}
            <a className="link" href={acinLink[i18n.language]} rel="noopener noreferrer" target="_blank">{translation('firstParagraphAcinLink')}</a>
            {translation('firstParagraphAcinEnd')}
          </Typography>
          <Typography variant="body1" className={classes.contentMiddle}>
            <b>{translation('highlightSecondParagraphAcin')}</b>
            {/* {translation('normalSecondParagraphAcin')} */}
          </Typography>
          <Typography variant="body1" className={classes.contentBottom}>
            {translation('thirdParagraphAcin')}
          </Typography>
        </div>
        <Grid container spacing={3} className={classes.infoContainer}>
          {
            boxParams.map((box, i) =>
              mobileLayout ?
                (
                  <Box
                    key={i}
                    imageUrl={box.imageUrl}
                    alt={box.alt}
                    highlight={box.highlight }
                    firstNormal={box.firstNormal }
                    secondNormal={box.secondNormal }
                  />
                )
              :
                (
                  infoPaper(
                   box.imageUrl,
                   box.alt,
                   box.highlight,
                   box.firstNormal,
                   box.secondNormal,
                  )
                )
            )
          }
        </Grid>
      </Grid>
      <Grid item lg={6} className={classes.rightColumn}>
        <Typography variant="body1" className={classes.sponsorText}>
          <span>+ {translation('acinGroupPlatforms')} </span>
          {translation('acinGroup.rightSide.rest')}
        </Typography>
        <Grid className={classes.sponsor}>
          {
            platformParams(translation).map( (param, i) => (
              <Grid key={i} container spacing={5} className={classes.sponsorMiddleLine}>
                {
                  param.map((data, i) => (
                    <Platform
                      key={i}
                      src1={data.src1}
                      src2={data.src2}
                      urlToRedirect={data.urlToRedirect}
                      alt={data.alt}
                    />
                  ))
                }
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Acin;
