import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  Link
} from '@material-ui/core';
import {
  useTranslation
} from 'react-i18next';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MinusIcon,
  PlusIcon
} from '../../../../icons';
import cardFeatureStyles from './Expanded.styles';


export interface Feature {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
  secondContent?: string;
  details: string[];
  className: string;
  color: string;
  background: string;
  digitalSignatureHref?: string;
  batchSubscriptionHref?: string;
  iconExpanded: string;
  classNameRoot?: string;
  hasDetails: boolean;
  linkToNavigate: string;
};

function Feature({
  details,
  className,
  color,
  background,
  batchSubscriptionHref,
  iconExpanded,
  classNameRoot,
  hasDetails,
  linkToNavigate
}: Feature) {
  const classes = cardFeatureStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { t: translation } = useTranslation();
  const history = useHistory();

  const handleExpandClick = ( hasDetails, linkToNavigate ) => {
    if (hasDetails) {
      setExpanded(!expanded);
    } else {
      history.push(linkToNavigate);
    }
  }

  return (
    <div className={className}>
      <Card className={classNameRoot}>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => handleExpandClick(hasDetails, linkToNavigate)}
            aria-expanded={expanded}
            aria-label="show more"
            disableRipple
          >
            <div
              className={clsx(background, {
                [classes.wrapIconExpanded]: expanded,
              })}
            >
              {expanded ? (
                <MinusIcon className={clsx({ color, iconExpanded }, { [iconExpanded]: expanded })} />
              ) : (
                <PlusIcon className={color} />
              )}
            </div>
            <Typography variant="h3" className={classes.moreFunctions}>
              {translation('featuresDetails')}
            </Typography>
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.absolute}>
          <CardContent className={classes.cardDetails}>
            <ul className={classes.featureList}>
              {details.map(detail => (
                <li key={detail} className={classes.link}>
                  <div><span className={classes.listStyle}>.</span></div>
                  <div className={classes.linkText}>{detail}</div>
                </li>
              ))}
              {batchSubscriptionHref ? (
                <>
                  <span className={classes.listStyle}>.</span>
                  <Link href={batchSubscriptionHref} target="_blank" rel="noopener" className={classes.href}>
                    {translation('signOnAuthentication')}
                  </Link>
                </>
              ) : (
                ''
              )}
            </ul>
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
}

export default Feature;
