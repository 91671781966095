import * as React from "react"

function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.685}
      height={20.6}
      viewBox="0 0 20.685 20.6"
      {...props}
    >
      <defs>
        <style>
          {".prefix__a{fill:#404040;stroke:#404040;stroke-width:.8px}"}
        </style>
      </defs>
      <path
        className="prefix__a"
        d="M1.5 20.2a1.015 1.015 0 01-.717-1.732L18.469.786a1.014 1.014 0 111.434 1.435L2.218 19.903a1.017 1.017 0 01-.718.297zm0 0"
      />
      <path
        className="prefix__a"
        d="M19.187 20.2a1.006 1.006 0 01-.717-.3L.783 2.218A1.014 1.014 0 112.218.786l17.685 17.683a1.015 1.015 0 01-.716 1.732zm0 0"
      />
    </svg>
  )
};

export default CloseIcon;