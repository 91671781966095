import { makeStyles, createStyles } from '@material-ui/core/styles';

const headerStyle = makeStyles((theme) =>
  createStyles({
    logoContainer: {
      '& img': {
        [theme.breakpoints.down(1650)]: {
          width: 110
        }
      }
    },
    container: {
      backgroundColor:'inherit',
      minHeight: 80,
      paddingRight: '3%',
      paddingLeft: '7%',

      [theme.breakpoints.only('sm')]: {
        minHeight: 80,
        paddingRight: '5%',
        paddingLeft: '7%',
      },

      [theme.breakpoints.only('xs')]: {
        minHeight: 80,
        paddingRight: '6%',
        paddingLeft: '6%',
        justifyContent: 'space-between',
      },
    },
    logo: {
      [theme.breakpoints.only('sm')]: {
        height: '50.41px',
      },
      [theme.breakpoints.only('xs')]: {
        height: '49.48px',
      },
    },
    // BUTTON
    buttonLogin: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: 16,
      marginRight: 25,
      textTransform: 'uppercase',
      textDecoration: 'none',
      color: theme.palette.secondary.dark,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
      [theme.breakpoints.only('sm')]: {
        marginRight: 15,
      },
      [theme.breakpoints.only('xs')]: {
        marginRight: 15,
      },
    },
    buttonLink: {
      marginRight: 25,

      [theme.breakpoints.down(1005)]: {
        marginRight: 10,
      },
    },
    buttonLinkTest: {
      marginRight: 25,
      height: 50,
    },
    // DRAWER
    drawer: {
      width: 250,
      flexShrink: 0,
    },
    drawerLink: {
      paddingLeft: 0,
      paddingRight: 0,
      color: '#fff',

      '& span': {
        fontSize: '18px',
      }
    },
    drawerLinkSpecial:{
      paddingLeft: 0,
      color: '#fff',

      '& span': {
        fontSize: '18px',
      }
    },
    menuContent: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    drawerUl: {
      listStyleType: 'none',
      paddingLeft: 0,
      width: '138px',
    },
    drawerLi: {
      marginBottom: 5,
    },
    paper: {
      width: 260,
      backgroundColor: '#404040',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      minHeight: 80,
      padding: theme.spacing(0, 1),
      justifyContent: 'flex-start',
    },
    closeIcon: {
      color: '#fff',
      fontSize: 30,
    },
    freeTrialDiv: {
      paddingLeft: 62,
      '@media (max-height:550px)': {
        display: 'none',
      },
    },
    freeTrialButton: {
      position: 'absolute',
      bottom: 0,
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 60,
      background: '#E2FDFF',
      color: '#0099E6 ',
      borderRadius: 5,
      marginBottom: 30,
    },
    freeTrialImg: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      marginLeft: 72,
      marginBottom: 75,
    },
    buttonLinkHighlight: {
      color: theme.palette.secondary.dark,
      font: 'italic normal 600 10px/19px Poppins !important',
      margin: '2px 0 0 5px',
      textTransform: 'uppercase',

      [theme.breakpoints.up('lg')]: {
        font: 'italic normal 600 10px/19px Poppins',
      },

      '&.mobile-menu': {
        marginTop: 7
      }
    },
  })
);

export default headerStyle;
