import { makeStyles, Theme } from '@material-ui/core';

const onlyofficePageStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  },
  introductionContainer: {
    backgroundColor: '#f5f5f5',
    minHeight: 'calc(100vh - 80px)',
    height: '100%',
    display: 'flex',
    padding: '0 7%',
    paddingBottom: 60
  },
  introductionInfoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      font: 'normal normal 600 40px/54px Poppins',
      margin: 0,
      color: '#1d1d1d',
      '& span': {
        color: '#00ACFF'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      paddingTop: 100
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 55
    }
  },
  introductionIntegration: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#005580',
    margin: 0
  },
  separator: {
    width: '150px',
    height: '12px',
    backgroundColor: '#00ACFF',
    margin: '20px 0 50px',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  introductionImgContainerSmall: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .banner-image': {
        maxHeight: 324
      },
      marginBottom: 30
    }
  },
  whatIsStamps: {
    margin: '0 0 20px 0',
    font: 'normal normal 600 18px/22px Lato',
    color: '#1d1d1d',
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 600 18px/24px Lato',
      marginBottom: 15
    }
  },
  whatIsStampsAnswer: {
    margin: 0,
    font: 'normal normal 300 20px/22px Lato',
    color: '#1d1d1d',
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 300 18px/24px Lato'
    }
  },
  introductionImgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    '& .banner-image': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600,
        maxHeight: 600
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 674,
        maxHeight: 720
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  allowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '110px 7% 130px',
    gap: 35,
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 100
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 55,
      paddingBottom: 85
    }
  },
  allowParagraphContainer: {
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    },
    '& h2': {
      margin: 0,
      font: 'normal normal 600 40px/54px Poppins',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    }
  },
  allowParagraphText: {
    position: 'relative',
    zIndex: 1
  },
  allowGreenBox: {
    width: 52,
    height: 52,
    backgroundColor: '#e0fcd6',
    position: 'absolute',
    zIndex: -1,
    top: '-13px',
    left: '-34px',
    [theme.breakpoints.down('md')]: {
      top: '-19px'
    }
  },
  allowGreenText: {
    color: '#1aa732'
  },
  allowTextContainer: {
    textAlign: 'center',
    '& p': {
      margin: 0,
      font: 'normal normal 300 20px/24px Lato'
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    }
  },
  infoOnlyofficeContainer: {
    position: 'relative',
    backgroundColor: '#263238'
  },
  infoOnlyofficeSubContainer: {
    display: 'flex',
    padding: '0 7%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  infoOnlyofficeTextContainer: {
    flex: 1,
    '& div': {
      height: '100%',
      padding: '85px 7% 160px 0',
      '& p': {
        font: 'normal normal 300 20px/24px Lato',
        color: '#fff',
        margin: 0,
        '&:first-of-type' : {
          marginBottom: 20
        }
      },
      '& img': {
        width: '100%',
        marginBottom: 64
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: 0
      }
    },
  },
  infoOnlyofficeImgContainer: {
    flex: 1,
    '& div': {
      height: '100%',
      paddingLeft: '7%',
      position: 'relative',
      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: '33%',
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('md')]: {
          position: 'relative',
          top: '90px',
          width: '100%',
        }
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '600px',
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  infoBackgroundBoxOne: {
    position: 'absolute',
    height: 224,
    width: 224,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 0,
    [theme.breakpoints.down('md')]: {
      height: 94,
      width: 94,
      top: 74
    }
  },
  infoBackgroundBoxTwo: {
    position: 'absolute',
    height: 177,
    width: 177,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 0,
    right: 224,
    [theme.breakpoints.down('md')]: {
      width: 74,
      height: 74,
      right: 94
    }
  },
  infoBackgroundBoxThree: {
    position: 'absolute',
    height: 88,
    width: 88,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 401,
    [theme.breakpoints.down('md')]: {
      width: 37,
      height: 37,
      top: 74,
      right: 168
    }
  },
  formContainer: {
    backgroundColor: '#E2FDFF',
    justifyContent: 'center',
    paddingTop: '165px',
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: '230px',
    flexDirection: 'column',
    alignItems: 'center',
    '& form': {
      padding: 0,
      paddingTop: 40,
      height: 'auto',
      minHeight: 'unset',
      maxWidth: '812px',
      boxShadow: 'none',
      '& .form-container-trial': {
        backgroundColor: '#e2fdff'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 100,
      marginBottom: 60
    }
  },
  formTitleContainer: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start'
    }
  },
  formTitle: {
    font: 'normal normal 600 40px/56px Poppins',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 600 38px/50px Poppins'
    },
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 600 30px/35px Poppins',
    }
  },
  newsLetterContainer: {
    '& .container-subscription': {
      [theme.breakpoints.up('lg')]: {
        marginTop: '-100px'
      }
    }
  },
  prosContainer: {
    display: 'flex',
    background: 'linear-gradient(to right, #e0fcd6 50%, #ffffff 50%)',
    padding: '0 7%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      background: '#e0fcd6'
    }
  },
  prosFirstHalfContainer: {
    flex: 1,
    '& div': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '140px 16% 140px 0',
      gap: 30,
      [theme.breakpoints.down('md')]: {
        paddingBottom: 24
      }
    },
    '& h2': {
      margin: 0,
      color: '#1d1d1d',
      font: 'normal normal 600 40px/56px Poppins',
      '& span': {
        color: '#1aa732'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    '& p': {
      margin: 0,
      color: '#1d1d1d',
      font: 'normal normal 300 20px/24px Lato',
      '& span': {
        fontWeight: 600
      }
    }
  },
  prosSecondHalfContainer: {
    flex: 1
  },
  prosSecondContentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '150px 0 150px 16%',
    gap: 32,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      paddingBottom: 120
    }
  },
  listTitle: {
    margin: 0,
    textDecoration: 'underline',
    textUnderlineOffset: '5px',
    font: 'normal normal 600 20px/24px Lato',
    color: '#1d1d1d'
  },
  listContainer: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 16
  },
  listItemContainer: {

  },
  listItemContent: {
    display: 'flex',
    gap: 16
  },
  listItemImgContainer: {
    '& img': {
      paddingTop: 3
    }
  },
  listItemTextContainer: {
    font: 'normal normal 300 18px/22px',
    '& span': {
      fontWeight: 600
    }
  }
}));

export default onlyofficePageStyles;