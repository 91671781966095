import React, {
  useState
} from 'react';
import { 
  FormControlProps,
  SelectProps
} from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form/dist/types';
import ReactDOM from "react-dom";
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SelectMaterialUi from '@material-ui/core/Select';

interface Select {
  selectProps?: SelectProps;
  onChange?: SelectProps['onChange'];
  onSelect?: SelectProps['onSelect'];
  formControlProps?: FormControlProps;
  label?: string;
  children: JSX.IntrinsicElements['option'];
  required?: boolean;
  name: string;
  id?: string;
  value?: string;
  helperText?: string;
  error?: boolean;
  inputRef?: UseFormMethods['register'];
}
const Select: React.FC<Select> = ({
  label,
  selectProps,
  formControlProps,
  children,
  onChange,
  name,
  id,
  value,
  helperText,
  error,
  inputRef,
  onSelect,
}) => {
  const [inputLabelRef, setInputLabelRef] = useState<HTMLLabelElement|null>(null);
  const labelOffsetWidth = inputLabelRef
    ? (ReactDOM.findDOMNode(inputLabelRef) as HTMLLabelElement).offsetWidth
    : 0;

  return (
    <FormControl required fullWidth variant="outlined" size="small" {...formControlProps}>
      <InputLabel
        ref={ref => {
          setInputLabelRef(ref);
        }}
        style={{paddingTop: '2px'}}
        error={error} htmlFor={name}
      >
        {label}
      </InputLabel>
      <SelectMaterialUi
        native
        inputRef={inputRef}
        error={error}
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        label={name}
        {...selectProps}
        inputProps={{
          name,
          id: id || name,
        }}
        input={
          <OutlinedInput
            labelWidth={labelOffsetWidth}
            name={name}
          />
        }
      >
        {children}
      </SelectMaterialUi>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;
