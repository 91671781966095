import React, { useEffect, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Meta } from 'Interfaces';
import { animateScroll } from 'react-scroll';
import Newsletter from '../../../components/sections/newsletter/NewsletterComponent';
import Form from '../../../components/sections/free-trail/form';
import primaveraPageStyles from './PrimaveraPage.styles';
import useSetMeta from '../../../hooks/useSetMeta';
import primaveraImg from '../../../assets/images/iDOK-cegid.gif';
import sincronizacaoArtigos from '../../../assets/icons/sincronizacao-artigos.svg';
import documentosFinanceiros from '../../../assets/icons/documentos-financeiros.svg';
import estadoEncomendas from '../../../assets/icons/estado-encomendas.svg';
import elaboracaoProcedimentos from '../../../assets/icons/elaboracao-procedimentos.svg';
import diagramaIntegracaoMobilePt from '../../../assets/images/diagrama-integracao-mobile-pt.svg';
import diagramaIntegracaoMobileEn from '../../../assets/images/diagrama-integracao-mobile-en.svg';
import diagramaIntegracaoMobileEs from '../../../assets/images/diagrama-integracao-mobile-es.svg';
import diagramaIntegracaoDesktopPt from '../../../assets/images/diagrama-integracao-desktop-pt.svg';
import diagramaIntegracaoDesktopEn from '../../../assets/images/diagrama-integracao-desktop-en.svg';
import diagramaIntegracaoDesktopEs from '../../../assets/images/diagrama-integracao-desktop-es.svg';

function PrimaveraPage() {
  const classes = primaveraPageStyles();
  const { t: translation } = useTranslation();

  const metaTags: Array<Meta> = useMemo(() => ([
    { name: 'title', content: translation('primavera.meta.title') },
    { name: 'description', content: translation('primavera.meta.description') }
  ]), [translation]);
  useSetMeta(metaTags);

  useEffect(() => {
    document.title = translation('primavera.meta.title')
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const diagramMobileImgs = {
    'ptDiagramaIntegracao': diagramaIntegracaoMobilePt,
    'esDiagramaIntegracao': diagramaIntegracaoMobileEs,
    'enDiagramaIntegracao': diagramaIntegracaoMobileEn,
  }

  const diagramDesktopImgs = {
    'ptDiagramaIntegracao': diagramaIntegracaoDesktopPt,
    'esDiagramaIntegracao': diagramaIntegracaoDesktopEn,
    'enDiagramaIntegracao': diagramaIntegracaoDesktopEs
  }

  const tools = [
    {
      id: 1,
      icon: sincronizacaoArtigos,
      texto: translation('sincronizacaoArtigosText'),
      altTitle: translation('sincronizacaoArtigosAlt')
    },
    {
      id: 2,
      icon: documentosFinanceiros,
      texto: translation('documentosFinanceirosText'),
      altTitle: translation('documentosFinanceirosAlt')
    },
    {
      id: 3,
      icon: estadoEncomendas,
      texto: translation('estadoEncomendasText'),
      altTitle: translation('estadoEncomendasAlt')
    },
    {
      id: 4,
      icon: elaboracaoProcedimentos,
      texto: translation('elaboracaoProcedimentosText'),
      altTitle: translation('elaboracaoProcedimentosAlt')
    },
  ]

  const processosUsabilidade = [
    {
      number: 1,
      texto: translation('usabilidadePassoUm')
    },
    {
      number: 2,
      texto: translation('usabilidadePassoDois')
    },
    {
      number: 3,
      texto: translation('usabilidadePassoTres')
    },
    {
      number: 4,
      texto: translation('usabilidadePassoQuatro')
    }
  ]

  return (
    <Grid
      container
      direction="column"
      alignItems='stretch'
      className={classes.root}
    >
      <Grid item className={classes.infoContainer}>
        <div className={classes.infoTextContainer}>
          <h3 className={classes.infoSubText}>{translation('integracaoIdok')}</h3>
          <h1>
            {translation('whatIs')}<br/>
            <span>ERP Primavera</span>
          </h1>
          <div className={classes.separator}></div>
          <div className={classes.infoSmallImgContainer}>
            <img className="banner-image" src={primaveraImg} alt="" />
          </div>
          <p className={classes.infoTextContent}>
            {translation('conceitoPrimaveraUm')} <a href="https://pt.primaverabss.com/pt/" target="_blank" rel="noopener noreferrer" className={classes.bolderText}>{translation('conceitoPrimaveraTres')}</a> {translation('conceitoPrimaveraQuatro')}<br />
            {translation('conceitoPrimaveraDois')}
          </p>
        </div>
        <div className={classes.infoImgContainer}>
          <img className="banner-image" src={primaveraImg} alt="" />
        </div>
      </Grid>
      <Grid item className={classes.advantagesContainer}>
        <div className={classes.advantagesTitleContainer}>
          <h2>
            <span className={classes.advantagesTitle}>
              <span className={classes.titleGreenBox}></span>
              {translation('toolsTitleOne')}
              <span className={classes.titleGreenText}>{translation('toolsTitleTwo')} </span>
              {translation('toolsTitleThree')}
            </span>
          </h2>
        </div>
        <div className={classes.advantagesCardsContainer}>
          {tools.map((tool) => {
            return (
              <div key={tool.id} className={classes.toolCardContent}>
                <div className={classes.toolCardImgContainer}>
                  <img src={tool.icon} title={tool.altTitle} alt={tool.altTitle} />
                </div>
                <div className={classes.toolCardParagraph}>
                  <p>{tool.texto}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Grid>
      <Grid container className={classes.thirdSection}>
        <div className={classes.thirdTitleContainer}>
          <h2 className={classes.thirdTitleContent}>
            <span className={classes.greenLetter}>{translation('usabilidadeTitleUm')}</span>
            {translation('usabilidadeTitleDois')}
          </h2>
        </div>
        <div className={classes.processoUsabilidadeContainer}>
          {processosUsabilidade.map((processo) => {
            return (
              <div key={processo.number} className={classes.processoUsabilidadeItem}>
                <div className={classes.processoNumeberIndicator}>{processo.number}</div>
                <div className={classes.processoTextContainer}>
                  <p className={classes.processoText}>{processo.texto}</p>
                </div>
              </div>
            )
          })}
        </div>
        <div className={`${classes.square} square--smallest`} />
        <div className={`${classes.square} square--small`} />
        <div className={`${classes.square} square--big`} />
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={6} className={classes.fourthSectionLeft}>
          <div className={classes.vantagensIntegrado}>
            <div>
              <Typography component={'h2'} className={classes.titleContent}>
                {translation('integracoesVantagensTitleUm')} <span className={classes.greenLetter}>{translation('integracoesVantagensTitleDois')}</span> {translation('integracoesVantagensTitleTres')}
              </Typography>
              <div className={classes.fourthSectionTextContainer}>
                <p className={classes.fourthSectionTextContent}>{translation('integracoesVantagensTextoUm')} <span className={classes.bolderText}>{translation('integracoesVantagensTextoDois')}</span>, {translation('integracoesVantagensTextoTres')}.</p>
              </div>
            </div>
            <div>
              <Typography component={'h2'} className={classes.titleContent}>
                {translation('integracoesProcessoTitleUm')} <span className={classes.greenLetter}>{translation('integracoesProcessoTitleDois')}</span> {translation('integracoesProcessoTitleTres')}
              </Typography>
              <div className={classes.fourthSectionTextContainer}>
                <p className={`${classes.fourthSectionTextContent} with-margin`}>{translation('integracoesProcessoTextoUm')} <a href="https://www.ilink.pt/ilink/" target="_blank" rel="noopener noreferrer" className={classes.bolderText}>{translation('integracoesProcessoTextoDois')}</a> {translation('integracoesProcessoTextoTres')}</p>
                <p className={classes.fourthSectionTextContent}>{translation('integracoesProcessoTextoQuatro')} <a href="https://www.globaltrustedsign.com/" target="_blank" rel="noopener noreferrer" className={classes.bolderText}>{translation('integracoesProcessoTextoCinco')}</a>, {translation('integracoesProcessoTextoSeis')}</p>
              </div>
            </div>
          </div>
          <div className={classes.processoIntegradoImgContainer}>
            <img src={diagramMobileImgs[translation('integrationDiagramImg')]} title={translation('integrationDiagramTitleAlt')} alt={translation('integrationDiagramTitleAlt')}/>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={6} className={classes.fourthSectionRight}>
          <div className={classes.fourthSectionRightImgContainer}>
            <img src={diagramDesktopImgs[translation('integrationDiagramImg')]} title={translation('integrationDiagramTitleAlt')} alt={translation('integrationDiagramTitleAlt')} />
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.formContainer}>
        <div className={classes.formTitleContainer}>
          <h3 className={classes.formTitle}>
            {translation('duvidasTitle')}
          </h3>
        </div>
        <Form message="askQuestionPrimavera" supportType="4"/>
      </Grid>
      <Grid item className={classes.newsLetterContainer}>
          <Newsletter boxPosition="3" />
      </Grid>
    </Grid>
  )
}

export default PrimaveraPage;