const primary = {
  primary: '#39D155',
  ligth: '#E0FCD6',
  ligther: 'hsl(104, 86%, 97%)',
  dark: '#1AA732',
  darker: '#0A642D',
};

const secondary = {
  secondary: '#FB4628',
  ligther: '#FEE0D3',
  dark: '#D72A1D',
  darker: '#780F06',
};

const tertiary = {
  tertiary: '#00ACFF',
  ligther: '#E2FDFF',
  dark: '#0099E6',
  darker: '#005580',
  darkest: '#263238',
};

const quartenary = {
  quartenary: '#FFBF01',
  ligther: '#FFF5D8',
  dark: '#E3AA00',
  darker: '#906C00',
};

const grayScales = {
  white: '#FFFFFF',
  ligtherGray: '#F5F5F5',
  ligthGray: '#BFBFBF',
  gray: '#808080',
  darkGray: '#404040',
  darkerGray: '#1D1D1B',
};

const Color = {
  primary,
  secondary,
  tertiary,
  quartenary,
  grayScales
};

export default Color;