import React from "react";
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import integrationsStyles from './IntegrationsComponent.styles';
import IntegrationsSlider from "./components/IntegrationsSlider";

function Integrations() {
  const { t: translation } = useTranslation();
  const classes = integrationsStyles();
  return (
    <Grid container className={classes.container} id="integrations">
      <div className={classes.titleContent}>
        <Typography variant="body2" className={classes.title} >
          <div className={classes.greenBox} />
          {translation('normalTitleIntegrations')} <span className={classes.greenLetter}>{translation('highlightTitleIntegrations')}</span>
        </Typography>
      </div>
      <div className={classes.integrationSubtitleContainer}>
        <span className={classes.integrationSubtitleText}>{translation('subtitleIntegrations')}</span>
      </div>
      <IntegrationsSlider />
    </Grid>
  )
}

export default Integrations;