import React from 'react';
import { Typography } from '@material-ui/core';

export interface Title {
  name: string;
  className: string;
}

function Title({ name, className }: Title) {
  return <Typography variant="body2" component="span" className={className}>
    {name}
  </Typography>
}

export default Title;
