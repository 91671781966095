import { createStyles, makeStyles } from '@material-ui/core';

const pricesFormStyle = makeStyles(theme =>
  createStyles({
    formContainer:{
      maxWidth:'630px',
    },
    form:{},
    formText:{
      textAlign: 'center',
      paddingTop: '46px',

      [theme.breakpoints.only('sm')]: {
        textAlign: 'unset',
        paddingRight: 0,
        paddingLeft: 28,
      },
      [theme.breakpoints.only('xs')]: {
        textAlign: 'unset',
        padding: 0,
        marginBottom: 10,
      },
    },
    specialInputText: {
      marginTop:'20px',
      minWidth: '48%',
      marginRight: '24px',

      [theme.breakpoints.down('md') ]: {
        minWidth: '100%'
      },
    },
    text:{
      font: 'normal normal 600 20px/26px lato',
      color: '#1D1D1B',
    },
    inputText:{
      marginTop:'20px',
      minWidth: '48%',

      [theme.breakpoints.down('md') ]: {
        minWidth: '100%'
      },
    },
    selectText:{
      marginTop:'20px',
      width: '48%',

      [theme.breakpoints.down('md') ]: {
        width: 'unset',
        minWidth: '100%'
      },
    },
    select:{
      marginTop:'20px',
      marginLeft: '4%',
      minWidth: '48%',

      [theme.breakpoints.down('md') ]: {
        minWidth: '100%',
        marginLeft: 0,
      },
    },
    requiredField:{
      paddingTop:'10px',
      paddingBottom:'30px',
      font: 'normal normal 300 16px/19px Lato',
      color: '#404040',

      '& span':{
        color:'#00ACFF'
      }
    },
    checkbox: {
      paddingTop: 45,
    },
    respTerms:{
      font: 'normal normal 300 16px/17px Lato',
      letterSpacing: '0px',
      color: '#404040',

      '& span':{
        textDecoration: 'underline',
        font: 'normal normal bold 16px/17px Lato',
        letterSpacing: '0px',
        color: '#404040',
      }
    },
    checkboxContainer:{
      [theme.breakpoints.down('xs') ]: {
        paddingTop: '10px',
      },
    },
    buttonSubmitContainerWithCaptcha:{
      display:'flex',
      flexDirection:'row',
      justifyContent: 'space-between',

      '& button': {
        height: '50px',
        background: '#1AA732 0% 0% no-repeat padding-box',
        borderRadius: '4px',

        '&:hover': {
          background: '#1AA732 0% 0% no-repeat padding-box',
        },

        [theme.breakpoints.down('xs') ]: {
          width: '100%',
        },
      },
    },
    submitButton:{
      display:'flex',
      flexDirection:'row',
      justifyContent: 'flex-end',
      paddingTop: 40,

      '& button': {
        height: '50px',
        background: '#1AA732 0% 0% no-repeat padding-box',
        borderRadius: '4px',

        '&:hover': {
          background: '#1AA732 0% 0% no-repeat padding-box',
        },

        [theme.breakpoints.down('xs') ]: {
          width: '100%',
        },
      },

      [theme.breakpoints.down('xs') ]: {
        justifyContent: 'flex-start',
      },
    },
    label: {
      fontSize: 16,
      lineHeight: 1.1,
      color: '#404040',

      '& span':{
        textDecoration: 'underline',
        font: 'normal normal bold 16px/17px Lato',
        letterSpacing: '0px',
        color: '#404040',
      }
    },
    link: {
      color: '#1D1D1B',
      fontWeight: 700,
      textDecoration: 'underline',
    },
  })
);

export default pricesFormStyle;