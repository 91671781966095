import React, {
  useRef,
  useCallback,
  useState,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next';
import idokVideo from '../../../../../assets/videos/iDokVideo.mp4';
import useStyles from './Video.styles.';
import ProgressBar from './ProgressBar';
import CloseIcon from './CloseIcon';
import PauseIcon from './PauseIcon';
import PlayIcon from './PlayIcon';

function Video({
  closePane
}) {
  const videoPlayerRef = useRef<HTMLVideoElement | null>(null);
  const classes = useStyles();
  const [percentage, setPercentage] = useState(0);
  const [videoState, setVideoState] = useState<'play'|'pause'>('play');
  const { t: translation } = useTranslation();

  const handleCurrentTime = useCallback((currentTime: number) => {
    const videoPlayer = videoPlayerRef.current;

    if (videoPlayer) {
        videoPlayer.currentTime = currentTime;
    }
  }, []);

  const handleTimeUpdate = useCallback(() => {
    const videoPlayer = videoPlayerRef.current;
    if (videoPlayer) {
      setPercentage(
        (videoPlayer.currentTime / videoPlayer.duration) * 100
      );
    }
  }, []);

  const pauseVideoPlayer = useCallback(() => {
    const videoPlayer = videoPlayerRef.current;

    if (videoPlayer) {
      if (videoState === 'play') {
        setVideoState('pause');
        videoPlayer.pause();
      } else {
        setVideoState('play');
        videoPlayer.play();
      }
    };
  }, [videoState]);

  useEffect(() => {
    if (percentage > 99.8) {
      closePane();
    }
  }, [percentage, closePane]);

  return (
    <div className={classes.videoContent}>
      <video
        autoPlay
        ref={videoPlayerRef}
        onTimeUpdate={handleTimeUpdate}
        className={classes.videoPlayer}
        title={translation('acinVideo')}
      >
        <source src={idokVideo} type="video/mp4" />
      </video>
      <div className={classes.buttonsContainer}>
        <div className={classes.closeBtn} onClick={closePane}>
          <CloseIcon />
        </div>
          <div
            className={classes.videoControlBtn}
            onClick={pauseVideoPlayer}
          >
            { (videoState === 'play') ? <PauseIcon /> : <PlayIcon /> }
          </div>
      </div>
      <ProgressBar
        percentage={percentage}
        timeHandler={handleCurrentTime}
        videoPlayerRef={videoPlayerRef}
      />
    </div>
  )
}

export default Video;
