import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import palette from '../../../../../global-style/colors.styles';

const addressStyle = makeStyles((theme: Theme) =>
  createStyles({
    // GERAL
    noBullets: {
      listStyleType: 'none',
      padding: '0px',
      margin: '8px 0px',
    },
    list: {
      color: '#1D1D1B',
      textDecoration: 'underline',
    },
    titleSemiBold: {
      fontWeight: 500,
      fontSize: '16px',
    },
    footerFonts: {
      fontSize: '16px',
      color: '#1D1D1B',
    },
    centered: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.only('sm')]: {
        paddingRight: 0,
      },
      [theme.breakpoints.only('xs')]: {
        paddingRight: 0,
      },
    },
    center: {
      textAlign: 'center',
    },
    title: {
      color: palette.secondary.dark,
      fontWeight: 600,
      fontSize: '16px',
    },
    overflowHidden: {
      overflowX: 'hidden',
      overflowY: 'hidden',
    },
    // MORADAS
    collapse: {
      marginTop: '2px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap:'nowrap',

      [theme.breakpoints.down(960)]: {
        flexWrap:'wrap',
      },


      [theme.breakpoints.only('xs')]: {
        flexDirection: 'column',
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    collapseWrapper: {
      cursor: 'pointer',
    },
    iconOpen: {
      color: 'green',
      cursor: 'pointer',
      height: '16px',
      transform: 'rotate(90deg)',
      marginTop: '8px',
      marginRight: '5px',
      textAlign: 'center',
    },
    iconClosed: {
      color: 'green',
      marginTop: '0px',
      marginBottom: '1px',
      marginRight: '0px',
      cursor: 'pointer',
      transform: 'rotate(270deg)',
    },
    padding: {
      paddingTop: '30px',
      [theme.breakpoints.only('xs')]: {
        padding: '20px !important',
      },
    },
    eachAddressPadding: {
      [theme.breakpoints.only('xs')]: {
        paddingBottom: '10px',
      },
    },
  })
);

export default addressStyle;
