import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTranslation} from 'react-i18next';
import { animateScroll } from 'react-scroll';
import AnimatedImage from '../../animation/AnimatedImage';
import Lottie from 'react-lottie-player';
import { Meta } from 'Interfaces';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useStyles from './MobilePage.styles';
import useSetMeta from '../../../hooks/useSetMeta';
import GreenBoxes from '../../../assets/images/GreenBoxes';
import GreenBox from '../../../assets/images/GreenBox';
import { listMobile } from '../../../i18n/locales/pt';
import Newsletter from '../../sections/newsletter/NewsletterComponent';
import StoreBadges from '../../StoreBadges';
import {mobileStores, gtsUrl, assinaturaDigitalUrl} from '../../../global-constants';
import lottieIdokMobile from '../../../assets/animations/animacao-idok-mobile.json';

import EncaminharDocumentos from '../../../assets/images/encaminhar-documentos.svg';
import ConsultarInformacao from '../../../assets/images/consultar-informacao.svg';
import AssinarDigitalmente from '../../../assets/images/assinar-digitalmente.svg';
import NotificacoesPush from '../../../assets/images/notificacoes-push.svg';
import AppAssinaturaDigital from '../../../assets/images/app-idok-assinatura-digital.png';
import AppDocumentosRecebidos from '../../../assets/images/app-idok-documentos-recebidos.png';
import AppEcraInicial from '../../../assets/images/app-idok-ecra-inicial.png';
import AppEncaminhamentoDocumentos from '../../../assets/images/app-idok-encaminhamento-documentos.png';
import AppInformacaoDocumento from '../../../assets/images/app-idok-informacao-documento.png';

const listCardsImgs = [NotificacoesPush, ConsultarInformacao, AssinarDigitalmente, EncaminharDocumentos];

const MobilePage: FC = () => {
  const classes = useStyles();
  const { t: translation, i18n } = useTranslation();
  const theme = useTheme();
  const mobileLayout = useMediaQuery(theme.breakpoints.down('sm'));
  const [isImageAnimated, setIsImageAnimated] = useState(false);

  const metaTags: Array<Meta> = useMemo(() => ([
    { name: 'title', content: translation('mobile.meta.title') },
    { name: 'description', content: translation('mobile.meta.description') },
    { name: 'keywords', content: translation('mobile.meta.keywords') }
  ]), [translation]);
  useSetMeta(metaTags);

  const onIntersectionObserver = () => {
    setIsImageAnimated(true);
  }

  const { intersectionRef } = useIntersectionObserver({intersectionCallback: onIntersectionObserver, observerConfig: { root: null, rootMargin: '0px', threshold: 0.4 }});

  const { lang } = useParams();

  useEffect(() => {
    document.title = translation('mobile.meta.title');

    if (lang) {
      i18next.changeLanguage(lang.toUpperCase());
    }
  }, [lang]);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const listItem = useMemo(() => {
    return listMobile.map((_, index) => (
      <li key={index.toString()} className={classes.listItem}>
        <span className="bullet">·</span>
        {translation(`mobile.list.${index}`)}
      </li>
    ))
  }, [classes, translation]);

  const listCards = useMemo(() => {
    return listCardsImgs.map((img, index) => (
      <li key={index.toString()} className={classes.listCardItem}>
        <img width="" height="" src={img} alt={translation(`mobile.listCards.${index}.alt`)} title={translation(`mobile.listCards.${index}.alt`)} />
        <div className={classes.listCardContent}>
          <span className="bold">{translation(`mobile.listCards.${index}.bold`)}</span>
          <span>{translation(`mobile.listCards.${index}.normal`)}</span>
        </div>
      </li>
    ))
  }, [classes, translation]);

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.mainSection}>
        <div className={classes.mainSectionLeft}>
          <h1>
            {translation('mobile.mainTitle.line1')}
            <span>{translation('mobile.mainTitle.line2.color')}</span>
          </h1>
          <div className={classes.separator} />
          <div className={classes.mainSectionSmallContainerSmall}>
            <Lottie
              loop={false}
              animationData={lottieIdokMobile}
              play={true}
              renderer="svg"
              className="banner-image"
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          <p className={classes.mainSectionText}>
            {translation('mobile.mainParagraph.init')}
            <span className="bold">{translation('mobile.mainParagraph.bold')}</span>
            {translation('mobile.mainParagraph.end')}
            {
              mobileStores.map(({description, company, url}) => (<span key={company}><a target="_blank" rel="noopener noreferrer" href={url}>{description}</a> {company} </span>))
            }
          </p>
          <StoreBadges />
        </div>
        <div className={classes.mainSectionRight}>
          <Lottie
            loop={false}
            animationData={lottieIdokMobile}
            play={true}
            renderer="svg"
            className="banner-image"
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </Grid>
      <Grid container justify="center" className={classes.secondarySection}>
        <Grid item xs={12} md={10} lg={8}>
          <Typography component="h2" className={`${classes.secondarySectionTitle} ${classes.title}`}>
            <span className="box-container">
              { mobileLayout ? <GreenBox /> : <GreenBoxes />}
              {translation('mobile.secondaryTitle.bold')}
            </span>
            <span className="normal">{translation('mobile.secondaryTitle.normal')}</span>
            <span className="color--secondary-dark">{translation('mobile.secondaryTitle.color')}</span>
          </Typography>
          <Typography paragraph variant="body1" className={classes.secondaryParagraph}>
              {translation('mobile.secondaryParagraph')}
          </Typography>
        </Grid>
        <Grid ref={intersectionRef} item xs={12} md={12} className={isImageAnimated ? `${classes.imageContainer} ${classes.imageContainerAnimated}` : classes.imageContainer}>
            <img width="20%" height="100%" src={AppEncaminhamentoDocumentos} alt={translation('mobile.mobileImgAlt.img1')} title={translation('mobile.mobileImgAlt.img1')} />
            <img width="20%" height="100%" src={AppInformacaoDocumento} alt={translation('mobile.mobileImgAlt.img2')} title={translation('mobile.mobileImgAlt.img2')} />
            <img width="20%" height="100%" src={AppEcraInicial} alt={translation('mobile.mobileImgAlt.img3')} title={translation('mobile.mobileImgAlt.img3')} />
            <img width="20%" height="100%" src={AppDocumentosRecebidos} alt={translation('mobile.mobileImgAlt.img4')} title={translation('mobile.mobileImgAlt.img4')} />
            <img width="20%" height="100%" src={AppAssinaturaDigital} alt={translation('mobile.mobileImgAlt.img5')} title={translation('mobile.mobileImgAlt.img5')} />
        </Grid>
      </Grid>
      <Grid container className={classes.thirdSection}>
        <Grid item md={12} lg={5} className={classes.thirdSectionLeft}>
            <Typography component="h1" className={`${classes.thirdSectionTitle} ${classes.title} color--secondary-main`}>
              {translation('mobile.thirdTitle.color')}
              <span className="color--white">{translation('mobile.thirdTitle.normal')}</span>
            </Typography>
            <Typography paragraph variant="body1" className={`${classes.thirdSectionParagraph} color--white`}>
              {translation('mobile.thirdParagraph.text1')}
            </Typography>
            <Typography paragraph variant="body1" className={`${classes.thirdSectionParagraph} color--white`}>
              {translation('mobile.thirdParagraph.text2')}
            </Typography>
            <Typography paragraph variant="body1" className={`${classes.thirdSectionParagraph} color--white`}>
              {translation('mobile.thirdParagraph.text3.init')}
              <a href={gtsUrl} target="_blank" rel="noopener noreferrer">{translation('mobile.thirdParagraph.text3.link1')}</a>
              {translation('mobile.thirdParagraph.text3.middle')}
              <a href={assinaturaDigitalUrl} target="_blank" rel="noopener noreferrer">{translation('mobile.thirdParagraph.text3.link2')}</a>
              {translation('mobile.thirdParagraph.text3.end')}
            </Typography>
            <Typography paragraph variant="body1" className={`${classes.thirdSectionParagraph} color--white`}>
              {translation('mobile.thirdParagraph.text4.init')}
              <span className="bold">{translation('mobile.thirdParagraph.text4.bold')}</span>
            </Typography>
        </Grid>
        <Grid item md={12} lg={7} className={classes.thirdSectionRight}>
          <AnimatedImage
            name="idok-gts"
            options={{ loop: true }}
            divProps={{
              className: classes.thirdSectionImage
            }}
          />
        </Grid>
        <div className={`${classes.square} square--small`} />
        <div className={`${classes.square} square--big`} />
      </Grid>
      <Grid container>
        <Grid item md={12} lg={6} className={classes.forthSectionLeft}>
          <Typography component="h2" className={`${classes.forthSectionTitle} ${classes.title}`}>
            {translation('mobile.forthTitle.normal')}
            <span className={`color--secondary-dark ${i18n.language === 'GB' ? 'break' : ''}`}>{translation('mobile.forthTitle.color')}</span>
          </Typography>
          <ul className={classes.list}>
            {listItem}
          </ul>
          <Typography paragraph variant="body1">
            {translation('mobile.forthParagraph.init')}
            <span className="bold">{translation('mobile.forthParagraph.bold')}</span>
            {translation('mobile.forthParagraph.end')}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.forthSectionRight}>
          <ul className={classes.listCards}>
            {listCards}
          </ul>
        </Grid>
          <Newsletter boxPosition="left" />
      </Grid>
    </Grid>
  )
}

export default MobilePage;
