import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();
const tabletSize = theme.breakpoints.down('sm');
const mobileSize = theme.breakpoints.down('xs');

const typographys: TypographyOptions = {
  fontFamily: 'Poppins, Lato',
  body1: {
    fontSize: 20,
    fontFamily: 'Lato',
    color: '#000000',
    lineHeight: 1.3,
    fontWeight: 300,
    [mobileSize]: {
      fontSize: 18,
    },
  },
  body2: {
    fontSize: 18,
    fontFamily: 'Lato',
    color: '#000000',
    fontWeight: 300,
  },
  h1: {
    fontWeight: 600,
    fontSize: 48,
    [tabletSize]: {
      fontSize: 36,
    },
    [mobileSize]: {
      lineHeight: 1.2,
      fontSize: 30,
    },
    lineHeight: 1,
  },
  h2: {
    fontSize: 36,
    [tabletSize]: {
      fontSize: 30,
    },
  },
  h3: {
    fontSize: 24,
    fontWeight: 600,
    [tabletSize]: {
      fontSize: 20,
    },
  },
  overline: {
    fontWeight: 600,
    fontSize: 48,
    [tabletSize]: {
      fontSize: 36,
    },
    [mobileSize]: {
      lineHeight: 1.2,
      fontSize: 30,
    },
    lineHeight: 1,
  },
};
export default typographys;
