import React, {
  useState,
  useCallback,
  useEffect
} from 'react';
import { SidePane } from "@acin.reactjs/side-pane";
import { ThemeProvider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import homeStyle, {
  sidePaneTheme
} from './Home.styles';
import AnimatedImage from '../../animation/AnimatedImage';
import ColumnLeft from './components/column-left';
import VideoComponent from './components/videoComponent/Video';

function Home() {
  const classes = homeStyle();
  const [open, setOpenState] = useState(false);
  const { t: translation } = useTranslation();
  const closeCallback = useCallback(params => {
    if (!params || params.successful) setOpenState(false);
    // Should Display Error
    // eslint-disable-next-line no-console
    else console.log(params.errorMessage);
  }, []);

  useEffect(() => {
    document.title = 'iDOK - Gestão Documental'
  }, []);

  return (
    <Grid container className={classes.container}>
      <Grid item md={12} lg={6} className={classes.columnLeft}>
        <ColumnLeft
          showVideo={() => setOpenState(true)}
        />
      </Grid>
      <Grid item md={12} lg={6} className={classes.columnRight}>
        <div className={classes.animatedImage}>
          <AnimatedImage
            options={{ loop: false }}
            name="section_home"
            lazyLoad
          />
        </div>
      </Grid>
      <ThemeProvider theme={sidePaneTheme}>
        <SidePane
          open={open}
          content={() => (
            <VideoComponent
              closePane={() => setOpenState(false)}
            />
          )}
          onClose={closeCallback}
          width='100%'
        />
      </ThemeProvider>
    </Grid>
  );
}

export default Home;
