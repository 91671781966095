import React from 'react';
import ReactDOM from "react-dom";
import App from "../js/Pages/App";
import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: import.meta?.env?.VITE_SENTRY_DNS,
});

ReactDOM.render(
  <App />,
  document.getElementById("app")
);