import React, {
  useState,
  useEffect
} from 'react';
import { Hidden, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import titleStyle from './title.styles';

function Title() {
  const tArray = ['lighterTitleHome','lighterTitleHome2','lighterTitleHome3','lighterTitleHome4'];
  const classes = titleStyle();
  const { t } = useTranslation();
  const [title, setTitle] = useState(t(tArray[0]));
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTitle(t(tArray[count]));
      setCount(count + 1);

      if (count === 3) {
        setCount(0);
      };
    }, 3000);
    return () => clearInterval(interval);
  }, [t, tArray, count]);

  return (
    <div className={classes.titleContent}>
      <Typography variant="h1">
        <span className={classes.lowWeigth}>{t('makeYourTitleHome')} </span>
        <Typography variant="overline">
          <span className={classes.title}>{t('managementTitleHome')}{' '}</span>
          <Typography variant="overline" className={classes.overline}>
            {t('documentaryTitleHome')}{' '}
            <span className={classes.textSpotlight}>{title}</span>
          </Typography>
        </Typography>
      </Typography>
      <Hidden xsDown>
        <div className={classes.greenBorder} />
      </Hidden>
    </div>
  );
}

export default Title;
