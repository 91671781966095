import React, {
  FC
} from 'react';
import {
  Route,
} from 'react-router-dom';
import { RedirectRouteProps } from '../../Interfaces';

const RedirectRoute:FC<RedirectRouteProps> = ({
  redirectPath,
  ...items
}) => {
  return (
    <Route  {...items} render={props => (
        redirectPath ? window.location.replace(redirectPath as string) : window.location.replace("/")
      )}
    />
  )
}

export default RedirectRoute
