import React, { useState } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow,
  Autoplay
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useTranslation } from 'react-i18next';
import VisibilitySensor from "react-visibility-sensor";
import integrationsStyles from '../IntegrationsComponent.styles';
import Square from "./Square";
import acinGovImage from '../../../../assets/icons/integrations/acin-gov-integration.svg';
import iGestImage from '../../../../assets/icons/integrations/igest-integration.svg';
import gtsImage from '../../../../assets/icons/integrations/gts-integration.svg';
import ilinkImage from '../../../../assets/icons/integrations/ilink-integration.svg';
import microsoftImage from '../../../../assets/icons/integrations/microsoft-integration.svg';
import primaveraImage from '../../../../assets/icons/integrations/primavera-integration.svg';
import onlyofficeImage from '../../../../assets/icons/integrations/onlyoffice-integration.svg';
import selosImage from '../../../../assets/icons/integrations/selos-integration.svg'
import scannersImage from '../../../../assets/icons/integrations/scanners-integration.svg';
import cartoesImage from '../../../../assets/icons/integrations/cartoes-integration.svg';
import emailsImage from '../../../../assets/icons/integrations/emails-integration.svg';
import salesForceImage from '../../../../assets/icons/integrations/salesForce.svg';
import arrowLeft from '../../../../assets/icons/slider-left-arrow.svg';
import arrowRight from '../../../../assets/icons/slider-right-arrow.svg';


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow, Autoplay]);

function SlideNextArrow(props) {
  const classes = integrationsStyles();
  const { onClick } = props;

  return (
    <img className={classes.sliderArrow} id="slide-next-button" onClick={onClick} src={arrowRight} alt="" />
  )
}

function SlidePrevArrow(props) {
  const classes = integrationsStyles();
  const { onClick } = props;

  return (
    <img className={classes.sliderArrow} id="slide-prev-button" onClick={onClick} src={arrowLeft} alt="" />
  )
}

function IntegrationsSlider() {
  const classes = integrationsStyles();
  const { t: translation } = useTranslation();
  const [swiperInstance, setSwiperInstance] = useState<any | null>(null);
  const slides = [
    {
      id: 1,
      icon: acinGovImage,
      link: 'https://www.acingov.pt/',
      linkDesc: 'www.acingov.pt',
      description: translation('acinGroup.platforms.acingov.desc'),
      externo: true
    },
    {
      id: 2,
      icon: iGestImage,
      link: 'https://www.igest.pt/igest/',
      linkDesc: 'www.igest.pt',
      description: translation('acinGroup.platforms.igest.desc'),
      externo: true
    },
    {
      id: 3,
      icon: gtsImage,
      link: 'https://www.globaltrustedsign.com/',
      linkDesc: 'www.globaltrustedsign.com',
      description: translation('acinGroup.platforms.gts.desc'),
      externo: true
    },
    {
      id: 4,
      icon: ilinkImage,
      link: 'https://www.ilink.pt/ilink/',
      linkDesc: 'www.ilink.pt',
      description: translation('acinGroup.platforms.ilink.desc'),
      externo: true
    },
    {
      id: 5,
      icon: microsoftImage,
      link: 'https://www.office.com/',
      linkDesc: 'www.office.com',
      description: translation('microsoftPlatform'),
      externo: true
    },
    {
      id: 6,
      icon: primaveraImage,
      link: '/primavera',
      linkDesc: '',
      description: 'ERP Primavera',
      externo: false
    },
    {
      id: 7,
      icon: selosImage,
      link: '/selos-temporais',
      linkDesc: '',
      description: translation('selosTemp'),
      externo: false
    },
    {
      id: 8,
      icon: scannersImage,
      link: 'noLink',
      linkDesc: '',
      description: 'Scanners',
      externo: true
    },
    {
      id: 9,
      icon: cartoesImage,
      link: 'noLink',
      linkDesc: '',
      description: translation('cardReader'),
      externo: true
    },
    {
      id: 10,
      icon: onlyofficeImage,
      link: '/onlyoffice',
      linkDesc: 'www.onlyoffice.com',
      description: 'ONLYOFFICE',
      externo: false
    },
    {
      id: 11,
      icon: emailsImage,
      link: 'noLink',
      linkDesc: '',
      description: translation('boxEmails'),
      externo: true
    },
    {
      id: 12,
      icon: salesForceImage,
      link: 'noLink',
      linkDesc: 'www.salesforce.com/eu/',
      description: translation('salesforce'),
      externo: true
    }
  ]

  const handleVisibility = (isVisible) => {
    if (isVisible) {
      if (swiperInstance) {
        swiperInstance.autoplay.start();
      }
    };
  }

  const pauseAutoplay = (swiper) => {
    swiper.autoplay.stop();
  }

  return (
    <VisibilitySensor
        onChange={handleVisibility}
        partialVisibility
        >

      <div className={classes.newSliderContainer}>
        <SlidePrevArrow />
        <Swiper
          id="this-is-slider"
          onSwiper={(swiper) => {
            pauseAutoplay(swiper);
            setSwiperInstance(swiper);
          }}
          loop
          slidesPerView={1}
          navigation={{
            nextEl: '#slide-next-button',
            prevEl: '#slide-prev-button'
          }}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
          breakpoints={{
            1400: {
              loop: true,
              slidesPerView: 5
            },
            1130: {
              loop: true,
              slidesPerView: 4
            },
            875: {
              loop: true,
              slidesPerView: 3
            },
            600: {
              loop: true,
              slidesPerView: 2
            }
          }}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide.id}>
              <Square
                  id={slide.id}
                  icon={slide.icon}
                  link={slide.link}
                  linkDescription={slide.linkDesc}
                  description={slide.description}
                  externo={slide.externo}
                />
            </SwiperSlide>
          ))}

        </Swiper>
        <SlideNextArrow />
      </div>
    </VisibilitySensor>
  );
}

export default IntegrationsSlider;
