import { createStyles, makeStyles } from '@material-ui/core';

const pricesStyle = makeStyles(theme =>
  createStyles({
    container: {
      paddingLeft: '15%',
      paddingRight: '15%',
      paddingTop: '5%',
      paddingBottom: '10%',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '5%',
        paddingRight: '5%',
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '7%',
        paddingRight: '7%',
      },

      [theme.breakpoints.only('xs')]: {
        paddingLeft: '7%',
        paddingRight: '5%',
        paddingTop: '10px',
        paddingBottom: '40px',
        marginTop: '-54px',
      },
    },
    btnContent:{
      paddingTop: '70px',

      [theme.breakpoints.down('md')]: {
        paddingTop: '50px',
      },

      [theme.breakpoints.down('xs')]: {
        width:'100%',
        paddingTop: '15px',
      },
    },
    higlightColor: {
      color: theme.palette.secondary.contrastText,
      fontWeight: 'bold',
    },
    introPrices: {
      textAlign: 'center',
      paddingLeft: '17%',
      paddingRight: '17%',

      [theme.breakpoints.down('md')]: {
        textAlign: 'unset',
        paddingLeft: 'unset',
        paddingRight: 'unset',
      },
      [theme.breakpoints.only('xs')]: {
        padding: 0,
        marginBottom: 30,
        marginLeft: 'unset',
      },
    },
    pricesFormContainer:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start'
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: '0px',
        marginLeft: 'unset',
        justifyContent: 'center',
      },
    },

    requestBtn: {
      height: '50px',
      background: '#1AA732 0% 0% no-repeat padding-box',
      borderRadius: '4px',
      textAlign: 'center',
      font: 'normal normal 600 16px/25px lato',
      letterSpacing: '0px',
      color: '#FFFFFF',
      textTransform: 'uppercase',
      padding: '15px 24px',

      '&:hover': {
        background: '#1AA732 0% 0% no-repeat padding-box',
      },

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    normalTitle: {
      font: 'normal normal 600 48px/52px Poppins',
      letterSpacing: '0px',
      opacity: 1,

      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/52px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/36px Poppins',
      },

    },
    greenTitle: {
      font: 'normal normal 600 48px/52px Poppins',
      letterSpacing: '0px',
      opacity: 1,
      color: '#1AA732',

      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/52px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/36px Poppins',
      },
    },
    titleContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      paddingBottom: '35px',

      [theme.breakpoints.down('md')]: {
        alignItems: 'unset',
        flexDirection: 'column',
        paddingBottom: '12px',
      },

      '& svg': {
        [theme.breakpoints.down('md')]: {
          top: '75px',
          right: '100px',
          position: 'relative',
          marginBottom: '20px',
        },

        [theme.breakpoints.down('xs')]: {
          marginBottom: '20px',
          position: 'relative',
          right: '100px',
          top: '60px',
        },
      }
    },
    title: {
      zIndex: 1,
      marginLeft: '-10px',

      [theme.breakpoints.down('md')]: {
        marginLeft: 'unset',
      },
    }

  })
);

export default pricesStyle;
