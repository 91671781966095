import { makeStyles, createStyles } from '@material-ui/core/styles';
import palette from '../../../../../global-style/colors.styles';

const menuStyle = makeStyles(() =>
  createStyles({
    footerFonts: {
      fontSize: '16px',
      color: '#1D1D1B',
    },
    title: {
      color: palette.secondary.dark,
      fontWeight: 600,
      fontSize: '16px',
    },
    noBullets: {
      listStyleType: 'none',
      padding: '0px',
      marginTop: 13
    },
    list: {
      color: '#1D1D1B',
      cursor: 'pointer',
      textDecoration: 'underline',
      textTransform: 'capitalize'
    },
    marginTop: {
      marginTop: '7px',
      '&:first-of-type': {
        margin: 0
      }
    },
  })
);

export default menuStyle;
