import { createTheme } from '@material-ui/core/styles';
import buttons from './buttons.styles';
import colors from './colors.styles';
import typography from './typography.styles';

const AppTheme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiGrid: {
      container: {
        backgroundColor:'white',
      }
    },
    MuiPaper: {
      // Change boxshadow on header
      elevation4: {
        boxShadow: '0px 3px 8px #D0D0D031',
      },
    },
    MuiButton: buttons,
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#1D1D1B',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& .MuiOutlinedInput-notchedOutline': {
          border: '0.5px solid #263238',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '0.5px solid #263238',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 20.2,
      },
      formControl: {
        minHeight: 50,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 20,
        top: 5,
        '&$focused': {
          color: 'black',
          top: -5,
          fontSize: 20,
          paddingBottom: 15,
        },
      },
      asterisk: {
        color: colors.primary.main,
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid gray'
        },
        '&:after': {
          borderBottom: '2px solid #808080'
        },
      }
    },
  },
  palette: colors,
  typography,
});

export default AppTheme;
