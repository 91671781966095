import React from 'react';
import { 
  Card,
  CardContent,
  CardHeader,
  Typography,
  Link 
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cardFeatureStyles from './CardTabletLayout.styles';

export interface Feature {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  content: string;
  secondContent?: string;
  details: string[];
  className: string;
  color: string;
  background: string;
  digitalSignatureHref?: string;
  batchSubscriptionHref?: string;
  iconExpanded: string;
  classNameRoot?: string;
}

function Feature({
  title,
  icon: Icon,
  content,
  secondContent,
  className,
  digitalSignatureHref,
  classNameRoot
}: Feature) {
  const classes = cardFeatureStyles();
  const { t: translation } = useTranslation();

  return (
    <div className={className}>
      <Card className={classNameRoot}>
        <CardHeader avatar={<Icon style={{ height: 85.7 }} />} className={classes.cardHeader} />
        <CardContent className={classes.card}>
          <Typography variant="h3" component="h2" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.cardContent}>
            {content}
            {digitalSignatureHref ? (
              <Link href={digitalSignatureHref} target="_blank" rel="noopener" className={classes.href}>
                {translation('digitalSignatureHref')}
              </Link>
            ) : (
              ''
            )}
            {secondContent}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default Feature;
