import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const AcinStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
    },
    // LEFT
    leftColumn: {
      paddingLeft: '7.5%',
      backgroundColor: '#E0FCD6',
      paddingTop: '110px',
      paddingBottom: '96px',
      paddingRight: '115px',

      [theme.breakpoints.down(1450)]: {
        paddingRight: '50px',
      },

      [theme.breakpoints.down('md')]: {
        paddingLeft: '7%',
        paddingTop: '92px',
        paddingRight: '7%',
        paddingBottom: '127px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: '7%',
        paddingTop: '65px',
        paddingRight: '5%',
        paddingBottom: '115px',
      },
    },
    contentTop: {
      marginTop: 33.5,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },

      '& .link': {
        color: "#000"
      }
    },
    sponsorMiddleLine:{
      paddingTop: '25px',
      paddingBottom: '17px',
    },
    contentMiddle: {
      marginBottom: '20px',
      marginTop: '20px',

      [theme.breakpoints.down('md')]: {
        marginBottom: '10px',
        marginTop: '10px',
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: '15px',
        marginTop: '15px',
      },
    },
    contentBottom: {
      marginBottom: 30,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    caption: {
      paddingBottom: 15,
      textAlign: 'center',
      fontSize: 18,

      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
        marginRight: 80,
        height: '140px',
        paddingTop: '45px',
      },
    },
    image: {
      width: 130.21,
      height: 118.59,
      margin: '20px 0px',

      [theme.breakpoints.down('md')]: {
        margin: '15px 0 0 0',
      },

      [theme.breakpoints.down('xs')]: {
        width: 91.05,
        height: 82.93,
        margin: '25px 0 25px 80px',
        float: 'left',
      },
    },
    infoContainer: {
      textAlign: 'center',
      marginTop: '78px',
      backgroundColor: 'unset',

      [theme.breakpoints.down('md')]: {
        marginTop: '45px',
        paddingRight: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },

      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        marginTop: 0,
      },
    },
    descriptionContent: {},
    // RIGHT
    rightColumn: {
      paddingTop: 195,
      paddingLeft: 100,
      paddingRight: 100,

      [theme.breakpoints.down(1420)]: {
        paddingLeft: 60,
        paddingRight: 60,
      },

      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    sponsor: {},
    paperContent:{
      boxShadow: '0px 3px 6px #00000029'
    },
    sponsorText: {
      paddingBottom:'40px',
      textAlign: 'left',
      font: 'normal normal 300 30px/35px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
      opacity: 1,

      '& span': {
        font: 'normal normal 600 30px/35px Poppins',
        letterSpacing: '0px',
        color: '#000000',
      }
    },
    title: {
      paddingTop: 22,
      textTransform: 'none',
      display: 'relative',

      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    wrapperFirstLetter: {
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        fontSize: '36px'
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '30px'
      },
    },
    wrapperTitle: {
      marginBottom: 53,

      [theme.breakpoints.down('md')]: {
        marginBottom: '40px',
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    textHighlight: {
      fontWeight: 600,
      color: theme.palette.secondary.dark,

      [theme.breakpoints.down('md')]: {
        fontSize: '36px'
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: '30px'
      },
    },
  })
);

export default AcinStyle;
