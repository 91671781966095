import {makeStyles, Theme} from '@material-ui/core';

const storeBadgesStyles = makeStyles((theme: Theme) => ({
  badgesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  badgeLink: {
    height: 38,

    '&:not(:last-of-type)': {
      marginBottom: 20
    },

    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        marginRight: 24,
        marginBottom: 0
      },
    },
  },
  badgeImage: {
    height: '100%'
  }
}));

export default storeBadgesStyles;
