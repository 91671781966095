import React, {
  FC
} from 'react';
import Carousel from 'react-material-ui-carousel';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './Carroussel.styles';
import Card from './CardComponent';
import LeftArrow from '../../../../assets/images/LeftArrow';
import RightArrow from '../../../../assets/images/RightArrow';

interface CarousselProps {
  cardsLength: number;
  cards: {
    title: string;
    description: string;
    url: string;
    urlToRedirect: string;
    color: string;
  }[][]
};

const Carroussel: FC<CarousselProps> = ({
  cards,
  cardsLength
}) => {
  const classes = useStyles();
  const theme     = useTheme();
  const mobileLayout = useMediaQuery(theme.breakpoints.down('xs'));
  let style = {
    backgroundColor:'inherit',
    height: '80px',
    margin: '0 10px'
  };

  if (mobileLayout) {
    style = {
      backgroundColor:'inherit',
      height: '80px',
      margin: '0'
    }
  };

  return (
    <Carousel
      autoPlay
      animation='slide'
      interval={10000}
      stopAutoPlayOnHover
      timeout={1000}
      indicators
      NextIcon={<RightArrow />}
      PrevIcon={<LeftArrow />}
      navButtonsAlwaysVisible={false}
      fullHeightHover
      navButtonsProps={{
        className:'navBtn',
        style
      }}
    >
      {
        cards.map( (card, i) => {
          const emptyCards = Array.from({ length: cardsLength - card.length });

          return <div key={i} className={classes.carouselPage}>
          {
            card.map( (el, innerIndex) =>
              <Card
                key={innerIndex}
                title={el.title}
                description={el.description}
                url={el.url}
                urlToRedirect={el.urlToRedirect}
                color={el.color}
              />
            )
          }
          {
            emptyCards.map((_, innerIndex) => {
              return (
                <Card
                  key={`${i}-${innerIndex}`}
                />
              )
            })
          }
        </div>
        })
      }
    </Carousel>
  )
};

export default Carroussel;
