import React, { useEffect, useMemo } from "react";
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Meta } from 'Interfaces';
import { animateScroll } from 'react-scroll';
import Newsletter from '../../sections/newsletter/NewsletterComponent';
import Form from '../../sections/free-trail/form';
import gestaoSistemasPageStyles from './GestaoSistemasPage.styles';
import useSetMeta from '../../../hooks/useSetMeta';
import confidencialidadeDadosIcon from '../../../assets/icons/confidencialidade-dados-icon.svg';
import autorizacaoAcessoIcon from '../../../assets/icons/autorizacao-acesso-icon.svg';
import limitacaoUtilizadorIcon from '../../../assets/icons/limitacao-utilizador-icon.svg';
import registoOperacoesIcon from '../../../assets/icons/registo-operacoes-icon.svg';
import areasCheckIcon from '../../../assets/icons/areas-check.svg';
import gestaoAnimation from '../../../assets/animations/banner-gestao-sistemas.json';
import gestaoEficienteImg from '../../../assets/icons/gestao-sistemas-eficiente.svg';
import threeBoxIcon from '../../../assets/icons/three-box-icon.svg';

function GestaoSistemasPage() {
  const classes = gestaoSistemasPageStyles();
  const { t: translation } = useTranslation();

  const metaTags: Array<Meta> = useMemo(() => ([
    { name: 'title', content: translation('gestaoSistemasContent.meta.title') },
    { name: 'description', content: translation('gestaoSistemasContent.meta.description') }
  ]), [translation]);
  useSetMeta(metaTags);

  useEffect(() => {
    document.title = translation('gestaoSistemasContent.meta.title')
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const tools = [
    {
      id: 1,
      icon: confidencialidadeDadosIcon,
      texto: translation('gestaoSistemasContent.tools.confidencialidadeDados'),
      altTitle: translation('gestaoSistemasContent.tools.confidencialidadeDadosAlt')
    },
    {
      id: 2,
      icon: autorizacaoAcessoIcon,
      texto: translation('gestaoSistemasContent.tools.autorizacaoAcesso'),
      altTitle: translation('gestaoSistemasContent.tools.autorizacaoAcessoAlt')
    },
    {
      id: 3,
      icon: limitacaoUtilizadorIcon,
      texto: translation('gestaoSistemasContent.tools.limitacaoUtilizador'),
      altTitle: translation('gestaoSistemasContent.tools.limitacaoUtilizadorAlt')
    },
    {
      id: 4,
      icon: registoOperacoesIcon,
      texto: translation('gestaoSistemasContent.tools.registoOperacoes'),
      altTitle: translation('gestaoSistemasContent.tools.registoOperacoesAlt')
    }
  ]

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      className={classes.root}
      >
        <Grid item className={classes.introductionContainer}>
        <div className={classes.introductionInfoContainer}>
          <h3 className={classes.introductionIntegration}>{translation('gestaoSistemasMainTitle')}</h3>
          <h1>
            {translation('gestaoSistemasContent.introductionContainer.mainTitleOne')}
            <span>{translation('gestaoSistemasContent.introductionContainer.mainTitleTwo')}</span>
            {translation('gestaoSistemasContent.introductionContainer.mainTitleThree')}
          </h1>
          <div className={classes.separator}></div>
          <div className={classes.introductionImgContainerSmall}>
            <Lottie
              loop={false}
              animationData={gestaoAnimation}
              play={true}
              renderer="svg"
              className="banner-image"
              title={translation('gestaoSistemasContent.imgTitles.mainAnimation')}
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          <p className={classes.introductionInfoText}>
            {translation('gestaoSistemasContent.introductionContainer.introductionTextOne')}<br/>
            {translation('gestaoSistemasContent.introductionContainer.introductionTextTwo')}
            <span>{translation('gestaoSistemasContent.introductionContainer.introductionTextThree')}</span>
            {translation('gestaoSistemasContent.introductionContainer.introductionTextFour')}
          </p>
        </div>
        <div className={classes.introductionImgContainer}>
          <Lottie
            loop={false}
            animationData={gestaoAnimation}
            play={true}
            renderer="svg"
            className="banner-image"
            title=""
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </Grid>
      <Grid item className={classes.advantagesContainer}>
        <div className={classes.advantagesParagraphContainer}>
          <h2>
            <span className={classes.advantagesParagraphText}>
              <span className={classes.paragraphBlueBox}></span>
              {translation('gestaoSistemasContent.advantagesContainer.mainTitleOne')}
              <span className={classes.paragraphBlueText}> {translation('gestaoSistemasContent.advantagesContainer.mainTitleTwo')} </span>
              {translation('gestaoSistemasContent.advantagesContainer.mainTitleThree')}
            </span>
          </h2>
        </div>
        <div className={classes.toolCardsContainer}>
          {tools.map((tool) => {
            return (
              <div key={tool.id} className={classes.toolCardContent}>
                <div className={classes.toolCardContentImgContainer}>
                  <img src={tool.icon} alt={tool.altTitle} title={tool.altTitle} />
                </div>
                <div className={classes.toolCardContentTextContainer}>
                  <p>{tool.texto}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Grid>
      <Grid item className={classes.securityContainer}>
        <div className={classes.infoSecurityContainer}>
          <div className={classes.securityTextContainer}>
            <div className={classes.securityInfoTextContainer}>
              <h2>
                {translation('gestaoSistemasContent.essencialContainer.title')}
              </h2>
              <p>{translation('gestaoSistemasContent.essencialContainer.mainParagraphOne')}</p>
              <p>{translation('gestaoSistemasContent.essencialContainer.mainParagraphTwo')}</p>
              <p>
                {translation('gestaoSistemasContent.essencialContainer.mainParagraphThreeFirst')}
                <span>{translation('gestaoSistemasContent.essencialContainer.mainParagraphThreeSecond')}</span>
                {translation('gestaoSistemasContent.essencialContainer.mainParagraphThreeThird')}
              </p>
            </div>
          </div>
          <div className={classes.securityImgContainer}>
            <div>
              <img src={gestaoEficienteImg} alt={translation('gestaoSistemasContent.imgTitles.gestaoEficienteImg')} title={translation('gestaoSistemasContent.imgTitles.gestaoEficienteImg')}/>
            </div>
          </div>
        </div>
        <div className={classes.securityBackgroundBoxOne}></div>
        <div className={classes.securityBackgroundBoxTwo}></div>
        <div className={classes.securityBackgroundBoxThree}></div>
      </Grid>
      <Grid item className={classes.areasContainer}>
          <div className={classes.areasContainerFistHalf}>
            <div className={classes.areasContainerSecondHalf}>

            </div>
            <div className={classes.threeBoxContainer}>
              <img src={threeBoxIcon} alt="" />
            </div>
            <div className={classes.areasInfoTextContainer}>
              <h3>
                {translation('gestaoSistemasContent.areasContainer.titleOne')}
                <span>{translation('gestaoSistemasContent.areasContainer.titleTwo')}</span>
                {translation('gestaoSistemasContent.areasContainer.titleThree')}
              </h3>
              <p>
                {translation('gestaoSistemasContent.areasContainer.mainParagraphOne')}
                <span>{translation('gestaoSistemasContent.areasContainer.mainParagraphTwo')}</span>
                {translation('gestaoSistemasContent.areasContainer.mainParagraphThree')}
              </p>
            </div>
            <div>
              <ul className={`${classes.areasListContainer} ${classes.firstList}`}>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaOne')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaTwo')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaThree')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaFour')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaFive')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaSix')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaSeven')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaEight')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaNine')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaTen')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaEleven')}
                  </h4>
                </li>
                <li>
                  <span className={classes.areasCheckIconContainer}>
                    <img src={areasCheckIcon} alt="" />
                  </span>
                  <h4 className={classes.areasItemTextContainer}>
                    {translation('gestaoSistemasContent.areasContainer.areaTwelve')}
                  </h4>
                </li>
              </ul>
            </div>
          </div>
      </Grid>
      <Grid item className={classes.prioridadeContainer}>
        <div className={classes.advantagesParagraphContainer}>
          <h2>
            <span className={classes.advantagesParagraphText}>
              <span className={classes.paragraphGreenBox}></span>
              {translation('gestaoSistemasContent.prioridadeContainer.titleOne')}<br/>
              <span className={classes.paragraphGreenText}>{translation('gestaoSistemasContent.prioridadeContainer.titleTwo')}</span>
            </span>
          </h2>
        </div>
      </Grid>
      <Grid container className={classes.formContainer}>
        <div className={classes.formTitleContainer}>
          <h3 className={classes.formTitle}>
            {translation('duvidasTitle')}
          </h3>
        </div>
        <Form message="askQuestionPrimavera" supportType="5"/>
      </Grid>
      <Grid item className={classes.newsLetterContainer}>
          <Newsletter boxPosition="" />
      </Grid>
    </Grid>
  )
}

export default GestaoSistemasPage;