import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const acinGroupWebStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '885px',
    },
    leftPanelContent: {},
    leftPanel: {
      padding: '122.54px 0 0 7%',
      paddingRight: '7%'
    },
    greenBox: {
      width: '52px',
      height: '52px',
      background: '#E0FCD6 0% 0% no-repeat padding-box',
    },
    title: {
      position:'relative',
      bottom: '28px',
      left: '25px',

      [theme.breakpoints.between(961, 1650)]: {
        paddingRight: '50px'
      },
    },
    titleText:{
      font: 'normal normal 600 48px/50px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    greenLetter:{
      color: '#1AA732',
    },
    subtitle: {
      font: 'normal normal 300 36px/50px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    dataContent:{},
    isoLink:{
      cursor: 'pointer'
    },
    description:{
      font: 'normal normal 300 20px/24px "Source Sans 3", Helvetica, Arial, sans-serif',
      letterSpacing: '0px',
      color: '#1D1D1B',

      '& a': {
        color: 'inherit',
      }
    },
    isoContent:{
      paddingTop:'25px',
    },
    isoTitle:{
      font: 'normal normal 600 20px/24px Lato',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    container: {
      backgroundColor: 'inherit',
    },
    isoImagesContent:{
      paddingTop:'75px',
      maxWidth:'683px',
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    rightPanel: {
      paddingTop: '122.54px',
      minHeight: '100vh',
      background: '#E0FCD6 0% 0% no-repeat padding-box',
    },
    rightPanelContent: {
      paddingTop:'30px',
      paddingBottom: '30px'
    },
    dataPlatform: {
      paddingLeft: '125px',
    },
    platformTitle:{},
    plusPlatform: {
      font: 'normal normal 600 30px/35px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    normalTitle: {
      font: 'normal normal 300 30px/35px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',

      '& a': {
        font: 'normal normal 300 30px/35px Poppins',
        letterSpacing: '0px',
        color: '#1D1D1B',
      }
    },
    knowMoreDiv:{
      marginTop: '5px',
      '& a': {
        textDecoration: 'underline',
        font: 'normal normal bold 16px/20px Lato',
        letterSpacing: '0px',
        color: '#0A642D',
        opacity: 1,
      }
    },
  })
);

export default acinGroupWebStyles;
