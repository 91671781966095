import React, {
  useEffect
} from 'react';
import {
  Route,
  Switch,
  useRouteMatch
, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {animateScroll as scroll } from 'react-scroll';
import StartledBro from '../../../assets/icons/faqs/Startled-bro.svg';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';
import { questionsArray } from './Utils'
import { constants } from '../../../global-constants';
import SelectSearchComponent from './components/selectSearch/SelectSearchComponent';
import FreeTrial from './components/freeTrial/FreeTrial';
import faqsStyle from './FaqsComponent.styles';
import QuestionsComponent from './components/questions/QuestionsComponent';

function Faqs() {
  const localStorageidokUrl = window.localStorage.getItem('baseUrl');
  const baseUrl = localStorageidokUrl || "";
  const classes = faqsStyle();
  const { t: translation } = useTranslation();
  const { urlList } = constants;
  const { path, url } = useRouteMatch();
  useEffect(() => {
    scroll.scrollToTop({ smooth:true });
  }, []);

  const faqs = questionsArray(translation, classes);
  const selectList = faqs.map((value) => ({ id: value.id,value: value.question}));

  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang.toUpperCase());
    }

  }, [lang]);

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.relative}>
        <Grid item xs={12} className={classes.columnLeft}>
          <div>
            <Typography variant="body2" className={classes.subTitle}>
              {translation('subTitleFaqs')}
            </Typography>
          </div>

          <Typography variant="body2" className={classes.subTitleContent}>
            {translation('normalTitleFaqs')} <span className={classes.greenLetter}> {translation('highlightTitleFaqs')}</span>
          </Typography>

          <SelectSearchComponent
            selectList={selectList}
          />

          <div className={classes.divBorder} />
        </Grid>
        <Grid item xs={12} className={classes.columnRight}>
          <img src={StartledBro} alt="Startled-bro" title="Startled-bro" />
        </Grid>
      </Grid>
      <Switch>
        <Route
          exact path={path}
          component={ () =>
            <QuestionsComponent
              faqsList={faqs}
              url={`${baseUrl}${url}`}
            />
          }
        />
        {
          Object.keys(urlList).map((val, i) => (
            <Route
              key={i}
              exact path={`${path}/${urlList[val]}`}
              component={ () =>
                <QuestionsComponent
                  faqsList={faqs[i]}
                  active
                  url={`${baseUrl}${url}`}
                />
              }
            />
          ))
        }
        <Route
          path='*'
          component={() =>
            <QuestionsComponent
              faqsList={faqs}
              url={`${baseUrl}${url}`}
            />
          }
        />
      </Switch>
      <FreeTrial />
    </Grid>
  );
}

export default Faqs;
