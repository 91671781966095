import React, { useEffect, useState, useRef } from 'react';
import { useTranslation,  } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import cookieComponentStyles from './CookieComponent.styles';

const cookie = {
  key: 'acceptCookies',
  value: '1'
};

const hasCookie = () => {
  if (localStorage.getItem(cookie.key)) {
    return true;
  }

  return false;
}

function CookieInformationIcon() {
  return (
    <svg id="Grupo_34736" data-name="Grupo 34736" xmlns="http://www.w3.org/2000/svg" width="37.501" height="37.5" viewBox="0 0 37.501 37.5">
        <g id="Grupo_34741" data-name="Grupo 34741">
            <path id="Caminho_82994" data-name="Caminho 82994" d="M18.751,0A18.75,18.75,0,1,0,37.5,18.75,18.772,18.772,0,0,0,18.751,0M35.886,18.747A17.138,17.138,0,1,1,18.747,1.611h0A17.158,17.158,0,0,1,35.886,18.747"/>
            <rect id="Retângulo_12477" data-name="Retângulo 12477" width="2.152" height="2.153" transform="translate(18.069 11)"/>
            <rect id="Retângulo_12478" data-name="Retângulo 12478" width="2.152" height="11.058" transform="translate(18.069 15.906)"/>
        </g>
    </svg>
  )
}

function CookieComponent() {
  const [showCookie, setShowCookie] = useState(!hasCookie());
  const { t: translation } = useTranslation();
  const cookieRef = useRef(null);
  const styles = cookieComponentStyles();


  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang.toUpperCase());
    }
  }, [lang]);

  const setCookieHandler = () => {
      localStorage.setItem(cookie.key, cookie.value);
      setShowCookie(false);
  }

  let cookieMessage = translation('cookieText');

  cookieMessage = cookieMessage.replace('{tagopen}', `<a href='https://acin.pt/politica-privacidade' className=${styles.cookieLink} target='_blank' rel='noopener noreferrer'>`);
  cookieMessage = cookieMessage.replace('{tagclose}', "</a>");

  useEffect(() => {
      if (cookieRef.current) {
          const container = (cookieRef.current as HTMLElement);
          container.innerHTML = cookieMessage;
      }
  }, [cookieMessage]);

  if (!showCookie) return null;

  return (
      <div className={styles.cookieContainer}>
          <div className={styles.coockieIconContainer}>
              <CookieInformationIcon />
          </div>
          <p className={styles.cookieText} ref={cookieRef} />
          <button className={styles.cookieButton} onClick={setCookieHandler}>{translation('aceitar')}</button>
      </div>
  )
}

export default CookieComponent;