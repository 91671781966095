import React, {
  FC,
  useState
} from 'react'
import Grid from '@material-ui/core/Grid';
import BoxesStyles from './Platforms.styles';

interface PlatformsProps {
  src1: string;
  src2: string;
  urlToRedirect: string;
  alt: string
}

const Platforms: FC<PlatformsProps> = ({
  src1,
  src2,
  urlToRedirect,
  alt
}) => {
  const [imageUrl, setImageUrl] = useState(src1);
  const classes= BoxesStyles();

  return (
    <Grid item lg={4} className={classes.contentLogo}>
      <a href={urlToRedirect} target="_blank" rel="noopener noreferrer">
        <img
          width='100%'
          height='100%'
          src={imageUrl}
          alt={alt}
          title={alt}
          onMouseEnter={() => setImageUrl(src2)}
          onMouseLeave={() => setImageUrl(src1)}
        />
      </a>
    </Grid>
  )
};

export default Platforms
