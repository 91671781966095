import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const columnLeftStyle = makeStyles((theme: Theme) =>
  createStyles({
    rootContent: {
      [theme.breakpoints.down('md')]: {
        width: 'inherit',
        paddingTop:'30px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop:'0px',
      },
    },

    contentMainContent: {
      paddingRight: '20px',

      [theme.breakpoints.down('md')]: {
        maxWidth: '608px',
      },
    },
    mainContent: {
      marginBottom: 40,
      maxWidth: '664px',
    },
    buttonLink: {
      height: '50px',
      marginRight: '26px',

      [theme.breakpoints.down('md')]: {
        marginRight: '26px',
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: '16px'
      },
    },

    btnContainer:{},
    videoBtn: {
      height: '50px',
    },
  })
);

export default columnLeftStyle;
