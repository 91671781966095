import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import EcrasAppMobile from '../../../assets/images/ecras-app-gestao-documental-idok.png';
import mobileStyles from './styles';
import StoreBadges from '../../StoreBadges';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { gtsUrl, assinaturaDigitalUrl } from '../../../global-constants';

function Mobile() {
  const classes = mobileStyles();
  const history = useHistory();
  const { t: translation } = useTranslation();
  const [animateImage, setAnimateImage] = useState(false);

  const onIntersectionObserver = () => {
    setAnimateImage(true);
  }

  const { intersectionRef } = useIntersectionObserver({intersectionCallback: onIntersectionObserver, observerConfig: { root: null, rootMargin: '0px', threshold: 0.2 }});

  return (
    <Grid container className={classes.container} ref={intersectionRef}>
      <Grid item sm={12} lg={5} className={classes.itemLeft}>
        <div>
          <Typography component="h3" className={classes.secondaryTitle}>
            {translation('mobileHome.secondaryTitle')}
          </Typography>
          <Typography component="h2" className={classes.mainTitle}>
            {translation('mobileHome.mainTitle.line1')}
            <span>
              {translation('mobileHome.mainTitle.line2.default')}
              <span className="color--blue">{translation('mobileHome.mainTitle.line2.color')}</span>
            </span>
          </Typography>
        </div>
        <div>
          <Typography paragraph variant="body1" className={classes.paragraph}>
            {translation('mobileHome.paragraphs.text1')}
          </Typography>
          <Typography paragraph variant="body1" className={classes.paragraph}>
            {translation('mobileHome.paragraphs.text2.init')}
            <a className="link" href={gtsUrl} rel="noopener noreferrer" target="_blank">{translation('mobileHome.paragraphs.text2.link1')}</a>
            {translation('mobileHome.paragraphs.text2.middle')}
            <a className="link" href={assinaturaDigitalUrl} rel="noopener noreferrer" target="_blank">{translation('mobileHome.paragraphs.text2.link2')}</a>
            <span className="bold">{translation('mobileHome.paragraphs.text2.bold')}</span>
          </Typography>
        </div>
        <StoreBadges />
        <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => history.push('/app-gestao-documental')}
          >
            {translation('mobileHome.button')}
        </Button>
      </Grid>
      <Grid item lg={7} className={classes.itemRight}>
        <img width="100%" height="100%" src={EcrasAppMobile} className={animateImage ? `${classes.image} ${classes.imageAnimated}` : classes.image} alt={translation('mobileHome.imageAlt')} title={translation('mobileHome.imageAlt')} />
      </Grid>
    </Grid>
  )
}

export default Mobile;
