import * as React from "react"

function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20.182}
      height={26.304}
      viewBox="0 0 20.182 26.304"
      {...props}
    >
      <path
        data-name="Caminho 90575"
        d="M18.379 10.022L5.257.804A3.4 3.4 0 001.585.337a3.312 3.312 0 00-1.572 3.1v19.406a3.342 3.342 0 001.6 3.161 2.818 2.818 0 001.284.3h.051a5.442 5.442 0 002.268-.681l13.138-9.24a3.689 3.689 0 00.014-6.361zm-.794 3.05a1.4 1.4 0 01-.7 1.094L3.614 23.508a.821.821 0 01-.806.24 1 1 0 01-.286-.874V3.43c0-.337.046-.762.267-.875l.117-.035a1.392 1.392 0 01.857.345l13.194 9.277a1.267 1.267 0 01.628.934z"
        fill="#404040"
      />
    </svg>
  )
};

export default PlayIcon;
