import React, {
  FC
} from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { constants, acinLink } from '../../../global-constants';
import { FooterProps } from './FooterComponent';
import footerStyle from './Footer.styles';
import Menu from './components/menu/Menu';
import Contactos from './components/contact/Contact';
import SocialMedia from './components/social';
import Iso20000 from '../../../assets/images/Iso20000';
import Iso27001 from '../../../assets/images/Iso27001';
import Iso9001 from '../../../assets/images/Iso9001';

const WebLayout: FC<FooterProps> = ({
  footerShadow=false
}) => {
  const classes = footerStyle({footerShadow});
  const { t: translation, i18n } = useTranslation();
  const { isoCertifiedUrl } = constants;

  return (
    <div className={classes.content}>
      <div className={classes.box}>
        <Typography variant="body2" component="span" className={classes.solucao}>
          {translation('solution')}
        </Typography>
        <a href={acinLink[i18n.language]} target="_blank" rel="noopener noreferrer">&nbsp;ACIN - iCloud Solutions, Lda</a>
        <div className={classes.isoImagesContent}>
          <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
            <Iso9001 className={classes.isoLinkNormal}/>
          </a>
          <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
            <Iso27001 className={classes.isoLink} />
          </a>
          <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
            <Iso20000 className={classes.isoLinkNormal}/>
          </a>
        </div>
      </div>
      <div>
        <Menu />
      </div>
      <div>
        <Contactos />
        <SocialMedia />
      </div>
    </div>
  )
};

export default WebLayout;
