import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import palette from '../../../global-style/colors.styles';

const faqsStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: '96px',
      paddingLeft: '7%',
      paddingRight: '7%',

      [theme.breakpoints.down('md')]: {
        minHeight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },
    relative: {
      position: 'relative',
    },
    columnRight: {
      position: 'absolute',
      alignSelf: 'center',
      paddingLeft: 20,
      right: 0,
      [theme.breakpoints.down(1500)]: {
        display: 'none',
      },
    },
    columnLeft: {
      alignSelf: 'center',
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        marginTop: 80,
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: '60px',
        marginBottom: '6px',
      },
    },

    subTitleContent: {
      font: 'normal normal 600 48px/52px Poppins',
      letterspacing: '0px',
      opacity: 1,
      marginBottom: '20px',

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/52px Poppins',
      },

    },

    greenLetter: {
      color: '#1AA732'
    },

    firstIconExpanded: {
      transform: 'rotate(0deg)',
    },
    subTitle: {
      marginBottom: '10px',
      fontWeight: 900,
      color: palette.secondary.contrastText,

      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        marginBottom: 0,
      },
    },
    divBorder: {
      borderBottom: '1px solid #263238',
      marginTop: 110,
      width: '80%',
      opacity: 1,

      [theme.breakpoints.down(1500)]: {
        minWidth: '100%',
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: '60px',
      },
    },
    searchTextField: {
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #DEDEDE',
      },
      maxHeight: 50,
      minWidth: '660px',

      [theme.breakpoints.down('md')]: {
        minWidth: '70%',
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
    input: {
      borderRadius: 0,
      cursor: 'initial',
    },
    noResults: {
      textAlign: 'center',
      marginTop: 20,
      fontWeight: 'bold',
    },
  })
);

export default faqsStyle;
