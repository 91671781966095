import React, {
  FC, useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { constants, acinLink } from '../../../../global-constants';
import useStyles from './AcinGroupWeb.styles';
import Iso20000 from '../../../../assets/images/Iso20000';
import Iso27001 from '../../../../assets/images/Iso27001';
import Iso9001 from '../../../../assets/images/Iso9001';
import Carousel from '../carousselComponent/Carroussel';
import { animateScroll } from 'react-scroll';

const AcinGroupWeb: FC<{cards: any, cardsLength: number}> = ({cards, cardsLength}) => {
  const { isoCertifiedUrl } = constants;
  const { t, i18n }   = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={6} className={classes.leftPanel}>
        <Grid container className= {classes.container}>
          <Grid item xs={12} className={classes.leftPanelContent}>
            <div className={classes.greenBox} />

            {/* Title & Subtitle */}
            <div className={classes.title}>
              <Typography variant="subtitle1" className={classes.titleText} >
                {t('acinGroup.leftSide.title.quality')}
                <span className={classes.greenLetter}>
                  {t('acinGroup.leftSide.title.security')}
                </span>
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                {t('acinGroup.leftSide.title.rest')}
              </Typography>
            </div>

            {/* Paragraph */}
            <div className={classes.dataContent}>

              {/* First line */}
              <Typography variant="body1" className={classes.description}>
                {t('acinGroup.leftSide.firstText.start')}
                <a href={acinLink[i18n.language]} target="_blank" rel="noopener noreferrer">{t('acinGroup.leftSide.firstText.link')}</a>
                {t('acinGroup.leftSide.firstText.end')}
              </Typography>

              {/* Second line */}
              <Typography variant="body1" className={classes.isoContent}>
                <span className={classes.isoTitle}>
                  {t('acinGroup.leftSide.iso9001')}
                </span>
                <span className={classes.description}>
                  {t('acinGroup.leftSide.iso9001Desc')}
                </span>
              </Typography>

              {/* Third Line */}
              <Typography variant="body1" className={classes.isoContent}>
                <span className={classes.isoTitle}>
                  {t('acinGroup.leftSide.iso27001')}
                </span>
                <span className={classes.description}>
                  {t('acinGroup.leftSide.iso27001Desc')}
                </span>
              </Typography>

              {/* Fourth Line */}
              <Typography variant="body1" className={classes.isoContent}>
                <span className={classes.isoTitle}>
                  {t('acinGroup.leftSide.iso20000')}
                </span>
                <span className={classes.description}>
                  {t('acinGroup.leftSide.iso20000Desc')}
                </span>
              </Typography>

              {/* Fifth Line */}
              <Typography variant="body1" className={classes.isoContent}>
                <span className={classes.description}>
                  {t('acinGroup.leftSide.lastText')}
                </span>
              </Typography>
            </div>

            <div className={classes.isoImagesContent}>
              <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
                <Iso9001 className={classes.isoLink}/>
              </a>
              <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
                <Iso27001 className={classes.isoLink}/>
              </a>
              <a href={isoCertifiedUrl} target="_blank" rel="noopener noreferrer">
                <Iso20000 className={classes.isoLink}/>
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} className={classes.rightPanel}>
        <Grid container className= {classes.container} >
          <Grid item xs={12} className={classes.rightPanelContent}>

            <div className={classes.dataPlatform}>
              <div className={classes.platformTitle}>
                {/* Title */}
                <Typography variant="body1">
                  <span className={classes.plusPlatform}>
                    {t('acinGroup.rightSide.platform')}
                  </span>
                  <span className={classes.normalTitle}>
                    {t('acinGroup.rightSide.from')}{' '}
                    <a href={acinLink[i18n.language]} target="_blank" rel="noopener noreferrer">
                      {t('acinGroup.rightSide.acinGroup')}
                    </a>
                  </span>
                </Typography>
              </div>

              <div className={classes.knowMoreDiv} />
            </div>

            <div>
              <Carousel
                cards={cards}
                cardsLength={cardsLength}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AcinGroupWeb;
