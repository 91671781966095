import React, {
  Fragment,
  FC
} from 'react';
import Button from '@material-ui/core/Button';
import { LoginButtonProps } from '../../Interfaces';
import ButtonStyle from './FormComponents.styles';

const ButtonComponent: FC<LoginButtonProps> = ({
  value,
  classname,
  ...rest
}) => {
  const classes = ButtonStyle();

  return (
    <Button
          className={classname || classes.button} 
          color='primary'
          {...rest}
        >
          {value}
        </Button>
  )
}

export default ButtonComponent;
