import React, { FC, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Lottie from 'react-lottie-player';
import { BaseErrorWebProps } from '../../../Interfaces';
import BaseErrorWebStyle from './BaseErrorWeb.styles';

const BaseErrorWeb: FC<BaseErrorWebProps> = ({
  dataOptions,
  messageComponent,
  buttons,
  finalOptions
}) => {
  const classes = BaseErrorWebStyle();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4} className={classes.imageContent}>
        <Lottie
          loop={false}
          animationData={finalOptions.animationData}
          renderer="svg"
          play={true}
        />
      </Grid>
      <Grid item xs={4} className={classes.dataContent}>
        <div>
          <div className={classes.title}>{dataOptions.title}</div>
          <div className={classes.subTitle}>{dataOptions.subTitle}</div>
          <div className={classes.description}>{dataOptions.description}</div>
          {messageComponent}
          <div className={classes.buttons} >
              {buttons}
          </div>
        </div>
      </Grid>
    </Grid>
  )
};

export default BaseErrorWeb
