import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';
import { Meta } from 'Interfaces';
import { animateScroll } from 'react-scroll';
import Newsletter from '../../sections/newsletter/NewsletterComponent';
import Form from '../../sections/free-trail/form';
import selosTemporaisPageStyles from './SelosTemporaisPage.styles';
import useSetMeta from '../../../hooks/useSetMeta';
import selosDocumentoVinculado from '../../../assets/icons/selos-documento-vinculado.svg';
import selosDocumentoModificado from '../../../assets/icons/selos-documento-modificado.svg';
import bannerAnimation from '../../../assets/animations/banner-selos-temporais.json';
import securityShield from '../../../assets/images/security-shield.png';
import gtsIdokIntegrationImg from '../../../assets/images/computadores-idok-gts.png';

function SelosTemporaisPage() {
  const classes = selosTemporaisPageStyles();
  const { t: translation } = useTranslation();

  const metaTags: Array<Meta> = useMemo(() => ([
    { name: 'title', content: translation('selosTemporais.meta.title') },
    { name: 'description', content: translation('selosTemporais.meta.description') }
  ]), [translation]);
  useSetMeta(metaTags);

  useEffect(() => {
    document.title = translation('selosTemporais.meta.title')
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const tools = [
    {
      id: 1,
      icon: selosDocumentoVinculado,
      texto: translation('selosTemporais.tools.documentoVinculado'),
      altTitle: translation('selosTemporais.tools.documentoVinculadoAlt')
    },
    {
      id: 2,
      icon: selosDocumentoModificado,
      texto: translation('selosTemporais.tools.documentoModificado'),
      altTitle: translation('selosTemporais.tools.documentoModificadoAlt')
    }
  ]

  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      className={classes.root}
    >
      <Grid item className={classes.introductionContainer}>
        <div className={classes.introductionInfoContainer}>
          <h3 className={classes.introductionIntegration}>{translation('integracaoIdok')}</h3>
          <h1>
            <span>{translation('selosTemporais.introductionContainer.mainTitleOne')} </span><br />
            {translation('selosTemporais.introductionContainer.mainTitleTwo')}
          </h1>
          <div className={classes.separator}></div>
          <div className={classes.introductionImgContainerSmall}>
            <Lottie
              loop={false}
              animationData={bannerAnimation}
              play={true}
              renderer="svg"
              className="banner-image"
              title={translation('selosTemporais.introductionContainer.question')}
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          <h3 className={classes.whatIsStamps}>{translation('selosTemporais.introductionContainer.question')}</h3>
          <p className={classes.whatIsStampsAnswer}>{translation('selosTemporais.introductionContainer.questionAnswer')}</p>
        </div>
        <div className={classes.introductionImgContainer}>
          <Lottie
            loop={false}
            animationData={bannerAnimation}
            play={true}
            renderer="svg"
            className="banner-image"
            title={translation('selosTemporais.introductionContainer.question')}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </Grid>
      <Grid item className={classes.advantagesContainer}>
        <div className={classes.advantagesParagraphContainer}>
          <h2>
            <span className={classes.advantagesParagraphText}>
              <span className={classes.paragraphBlueBox}></span>
              {translation('selosTemporais.advantagesContainer.mainTitleOne')}
              <span className={classes.paragraphBlueText}> {translation('selosTemporais.advantagesContainer.mainTitleTwo')} </span>
              {translation('selosTemporais.advantagesContainer.mainTitleThree')}
            </span>
          </h2>
        </div>
        <div className={classes.toolCardsContainer}>
          {tools.map((tool) => {
            return (
              <div key={tool.id} className={classes.toolCardContent}>
                <div className={classes.toolCardContentImgContainer}>
                  <img src={tool.icon} alt={tool.altTitle} title={tool.altTitle} />
                </div>
                <div className={classes.toolCardContentTextContainer}>
                  <p>{tool.texto}</p>
                </div>
              </div>
            )
          })}
        </div>
      </Grid>
      <Grid item className={classes.securityContainer}>
        <div className={classes.infoSecurityContainer}>
          <div className={classes.securityTextContainer}>
            <h2>
              {translation('selosTemporais.securityContainer.title')}
            </h2>
            <p>{translation('selosTemporais.securityContainer.mainParagraphOne')}</p>
            <p>{translation('selosTemporais.securityContainer.mainParagraphTwo')}</p>
            <p>{translation('selosTemporais.securityContainer.mainParagraphThree')}</p>
          </div>
          <div className={classes.securityImgContainer}>
            <img src={securityShield} alt={translation('selosTemporais.securityContainer.securityShieldTitle')} title={translation('selosTemporais.securityContainer.securityShieldTitle')}/>
          </div>
        </div>
        <div className={classes.securityBackgroundBoxOne}></div>
        <div className={classes.securityBackgroundBoxTwo}></div>
        <div className={classes.securityBackgroundBoxThree}></div>
      </Grid>
      <Grid item className={classes.gtsIntegrationContainer}>
        <div className={classes.gtsIntegrationImgContainer}>
          <img src={gtsIdokIntegrationImg} alt={translation('selosTemporais.gtsIntegrationContainer.gtsIntegrationAlt')} title={translation('selosTemporais.gtsIntegrationContainer.gtsIntegrationAlt')} />
        </div>
        <div className={classes.gtsIntegrationTextContainer}>
          <h3 className={classes.gtsIntegrationTextTitle}>
            {translation('selosTemporais.gtsIntegrationContainer.mainTitleOne')}
            <span> {translation('selosTemporais.gtsIntegrationContainer.mainTitleTwo')}</span>
          </h3>
          <div className={classes.gtsIntegrationTextParagraph}>
            <p>{translation('selosTemporais.gtsIntegrationContainer.mainParagraphOne')}</p>
            <p>
              {translation('selosTemporais.gtsIntegrationContainer.mainParagraphTwo')}
              <a className={classes.bolderText} href="https://globaltrustedsign.com/" target="_blank" rel="noopener noreferrer">{translation('selosTemporais.gtsIntegrationContainer.mainParagraphThree')}</a>
              {translation('selosTemporais.gtsIntegrationContainer.mainParagraphFour')}
            </p>
          </div>
          <div className={classes.gtsIntegrationSmallImgContainer}>
            <img src={gtsIdokIntegrationImg} alt={translation('selosTemporais.gtsIntegrationContainer.gtsIntegrationAlt')} title={translation('selosTemporais.gtsIntegrationContainer.gtsIntegrationAlt')} />
          </div>
          <div className={classes.gtsIntegrationTextButton}>
            <a href='https://www.globaltrustedsign.com/public_c/selos-temporais-ilimitados' target="_blank" rel="noopener noreferrer">{translation('selosTemporais.gtsIntegrationContainer.buyNow')}</a>
          </div>
        </div>
      </Grid>
      <Grid container className={classes.formContainer}>
        <div className={classes.formTitleContainer}>
          <h3 className={classes.formTitle}>
            {translation('duvidasTitle')}
          </h3>
        </div>
        <Form message="askQuestionPrimavera" supportType="4"/>
      </Grid>
      <Grid item className={classes.newsLetterContainer}>
          <Newsletter boxPosition="" />
      </Grid>
    </Grid>
  )
}

export default SelosTemporaisPage;
