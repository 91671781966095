import { constants } from '../../../global-constants';

export const questionsArray = (translation, classes) => {
  const { urlList } = constants;

  return [
    {
      id: urlList.aboutUs,
      question: translation('aboutUsQuestionFaqs'),
      answer: translation('aboutUsAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.advantages,
      question: translation('advantagesQuestionFaqs'),
      answer: translation('advantagesAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id:urlList.adaptation,
      question: translation('adaptationQuestionFaqs'),
      answer: translation('adaptationAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.possibleBrowsers,
      question: translation('possibleBrowsersQuestionFaqs'),
      answer: translation('possibleBrowsersAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.digitalSignature,
      question: translation('digitalSignatureQuestionFaqs'),
      answer: translation('digitalSignatureAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.sendAndReceiveDocument,
      question: translation('sendAndReceiveDocumentQuestionFaqs'),
      answer: translation('sendAndReceiveDocumentAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.documentReception,
      question: translation('documentReceptionQuestionFaqs'),
      answer: translation('documentReceptionAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.flowCharts,
      question: translation('flowChartsQuestionFaqs'),
      answer: translation('flowChartsAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id:urlList.govAutentication,
      question: translation('govAutenticationQuestionFaqs'),
      answer: translation('govAutenticationAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.users,
      question: translation('usersQuestionFaqs'),
      answer: translation('usersAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.customPermissions,
      question: translation('customPermissionsQuestionFaqs'),
      answer: translation('customPermissionsAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id:urlList.restrictAccess,
      question: translation('restrictAccessQuestionFaqs'),
      answer: translation('restrictAccessAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
    {
      id: urlList.support,
      question: translation('supportQuestionFaqs'),
      answer: translation('supportAnswerFaqs'),
      telephone: translation('telephone'),
      answerTwo: translation('supportTwoAnswerFaqs'),
      idokSupportEmail: translation('idokSupportEmail'),
      answerThree: translation('supportThreeAnswerFaqs'),
      iconExpanded: classes.firstIconExpanded,
    },
  ];
};