const TOKEN_KEY = 'SESSION_TOKEN';

export const login = (hash: string) => {
  localStorage.setItem(TOKEN_KEY, hash);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
      return true;
  };

  return false;
};

export const generateUniqueId = () => {
  return Date.now().toString(36) + Math.random().toString(36);
}
