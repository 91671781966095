import { Overrides } from '@material-ui/core/styles/overrides';
import colors from './colors.styles';

const { primary, secondary } = colors;

const buttons: Overrides['MuiButton'] = {
  label: {
    fontSize: 16,
  },
  root: {
    paddingLeft: 54,
    paddingRight: 54,
    paddingTop: 14,
    paddingBottom: 13,
    fontWeight: 600,
  },
  sizeSmall: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 15,
    paddingBottom: 12,
  },
  text: {
    fontWeight: 300,
    textTransform: 'none',
    color: '#1D1D1B',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // Entrar
  contained: {
    boxShadow: 'none',
    color: 'white',
    backgroundColor: secondary.contrastText,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: secondary.dark,
    },
  },
  // Pedir Teste
  containedPrimary: {
    color: 'white',
    backgroundColor: primary.dark,
    '&:hover': {
      backgroundColor: primary.main,
      color: 'white'
    },
  },
  // Pedir Teste Small
  containedSizeSmall: {
    color: primary.dark,
    backgroundColor: primary.light,
    '&:hover': {
      backgroundColor: primary.main,
      color: 'white',
    },
  },
  // Fale Conosco
  textSecondary: {
    color: secondary.contrastText,
    textTransform: 'uppercase',
    fontWeight: 600,
    backgroundColor: secondary.light,
    '&:hover': {
      backgroundColor: secondary.main,
      color: 'white',
    },
  },
  // Subescrever
  colorInherit: {
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 600,
    backgroundColor: secondary.dark,
    '&:hover': {
      backgroundColor: secondary.main,
      color: 'white',
    },
  },
  outlinedPrimary: {
    paddingLeft: 44,
    paddingRight: 44,
    paddingTop: 12,
    paddingBottom: 11,
    color: '#1D1D1B',
    backgroundColor: 'white',
    border: '2px solid #1D1D1B',
    '&:hover': {
      backgroundColor: '#1D1D1B',
      color: 'white',
      border: '2px solid #1D1D1B',
    },
  },
};
export default buttons;
