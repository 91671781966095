import React, {
  FC, useEffect, useRef
} from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import { cardPlatforms } from './Utils';
import AcinGroupWeb from './acinGroupLayout/AcinGroupWeb';
import AcinGroupTablet from './acinGroupLayout/AcinGroupTablet';

const AcinGroup: FC = () => {
  const firstRender = useRef(true);
  const { t } = useTranslation();
  const theme     = useTheme();
  const webLayout = useMediaQuery(theme.breakpoints.up('md'));
  const tabletLayout = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const mobileLayout = useMediaQuery(theme.breakpoints.down('xs'));

  const { lang } = useParams();

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang.toUpperCase());
    }

  }, [lang]);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  if (firstRender.current) return null;

  let cards:any = [];

  if (webLayout) {
    cards = cardPlatforms(t, 'web')
  } else if (tabletLayout && !mobileLayout) {
    cards = cardPlatforms(t, 'tablet');
  } else if (mobileLayout) {
    cards = cardPlatforms(t, 'mobile');
  }

  return webLayout ? <AcinGroupWeb cards={cards.cards} cardsLength={cards.length} /> :  <AcinGroupTablet cards={cards.cards} cardsLength={cards.length} />
};

export default AcinGroup;
