import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { PlayIcon } from '../../icons';

function Buttons() {
  return (
    <Grid container spacing={2} alignContent="space-between">
      <Button variant="contained"> Entrar </Button>

      <Button variant="contained" color="primary">
        Pedir Teste
      </Button>

      <Button variant="contained" size="small">
        Pedir Teste
      </Button>

      <Button variant="text" size="small" color="secondary">
        Fale Conosco
      </Button>

      <Button size="small" color="inherit">
        Subscrever
      </Button>

      <Button variant="outlined" color="primary" endIcon={<PlayIcon />}>
        Ver Video
      </Button>
    </Grid>
  )
}

export default Buttons;
