import { createStyles, makeStyles } from '@material-ui/core';

const newsletterStyle = makeStyles(theme =>
  createStyles({
    container: {
    },
    greenDivRight: {
      width: 350,
      height: 120,
      background: '#F0FEEB',

      [theme.breakpoints.down('md')]: {
        background: 'unset',
      },

      [theme.breakpoints.down('md')]: {
        background: 'unset',
        height: 57,
      },
    },
    greenDivLeft: {
      marginLeft: 'auto',
      width: 350,
      height: 120,
      background: '#F0FEEB',

      [theme.breakpoints.down('md')]: {
        background: 'unset',
      },

      [theme.breakpoints.down('md')]: {
        background: 'unset',
        height: 57,
      },
    },
    containerSubscription:{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
      },
    },
    subscription: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 10px 20px #0000001A',
      opacity: 1,
      zIndex: 7,

      [theme.breakpoints.down('md')]: {
        boxShadow: '0px 10px 10px #0000001A',
      },
    },
    textContent:{},
    center: {
      maxWidth:1324,
      minHeight:300,
      marginRight: '15%',
      paddingLeft: '13%',
      paddingTop: '22px',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 3px 10px 0px rgba(0,0,0,0.12)',
      [theme.breakpoints.only('sm')]: {
        paddingTop: '10%',
        margin: 0,
      },
      [theme.breakpoints.only('xs')]: {
        paddingTop: '15%',
        margin: 0,
      },
    },
    checkContainers: {
      marginTop: '10px',
    },
    titleMargin: {},
    label: {
      fontSize: 16,
      lineHeight: 1.1,
      paddingLeft: '3px',
      color: '#404040',

      '& span':{
        textDecoration: 'underline',
        font: 'normal normal bold 16px/17px Lato',
        letterSpacing: '0px',
        color: '#404040',
      }
    },
    link: {
      color: '#1D1D1B',
      fontWeight: 700,
      textDecoration: 'underline',
    },
    subscribe: {
      marginLeft: '20px',
      height: '50px',
      [theme.breakpoints.only('xs')]: {
        margin: 0,
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px',
      },
    },
    paddingRight: {
      display: 'flex',
      flexDirection: 'row',
    },
    checkbox: {
      '& .MuiButtonBase-root': {
        padding:'0 9px 0 9px',
      },

      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      },
    },
    lastCheckBox:{
      [theme.breakpoints.down('xs')]: {
        paddingTop: '10px',
      },

    },
    squareImg: {
      float: 'right'
    },
    afterSubmitted: {
      paddingBottom: 302,
      marginBottom: 174,
    },

    title: {
      textTransform: 'none',
      display: 'relative',

      [theme.breakpoints.down('xs')]: {
        paddingRight: 'unset',
      },
    },
    wrapperTitle: {
      marginBottom: '30px',
    },
    successImage: {
      display: 'flex',
      justifyContent: 'center',
    },
    successTextContent: {
      display: 'flex',
      justifyContent: 'center',
    },
    successText: {
      paddingTop:'25px',
      font: 'normal normal 600 20px/26px lato',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    dataContent:{
      maxWidth:'920px',
      paddingTop: '90px',

      [theme.breakpoints.down('md')]: {
        paddingTop: '25px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: '7%',
        paddingRight: '5%',
      },

    },
    rail: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '0 10%',

      [theme.breakpoints.down('md')]: {
        margin: '0 7%',
      },

      [theme.breakpoints.down('xs')]: {
        margin: '0',
      },
    },
    wrapperFirstLetter: {
      position: 'relative',

      [theme.breakpoints.down(1550)]: {
        fontSize: '40px'
      },

      [theme.breakpoints.down(1295)]: {
        fontSize: '36px'
      },

      [theme.breakpoints.down(820)]: {
        fontSize: '30px'
      },
    },
    textHighlight: {
      fontWeight: 600,
      color: theme.palette.secondary.dark,

      [theme.breakpoints.down(1550)]: {
        fontSize: '40px'
      },

      [theme.breakpoints.down(1295)]: {
        fontSize: '36px'
      },

      [theme.breakpoints.down(820)]: {
        fontSize: '30px'
      },
    },
  })
);

export default newsletterStyle;
