import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { PlayIcon } from '../../../../../components/icons';
import { HashLink as Link} from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TrialFormContext } from '../../../../../context/TrialFormContext';
import Title from '../title';
import columnLeftStyle from './column-left.styles';
import LayoutMobile from './MobileLayout';

function ColumnLeft({
  showVideo
}) {
  const classes            = columnLeftStyle();
  const { t: translation } = useTranslation();
  const theme              = useTheme();
  const mobileLayout       = useMediaQuery(theme.breakpoints.down('xs'));
  const { onToggleForm, showForm } = useContext(TrialFormContext);

  const onShowForm = () => {
    if(!showForm) onToggleForm();
  }

  let component = (
    <>
      <Title />
      <div className={classes.contentMainContent}>
        <Typography variant="body1" className={classes.mainContent}>
          {translation('mainContentHome')}
        </Typography>
      </div>
      <div className={classes.btnContainer}>
        <Link to="/#demo" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
          <Button variant="contained" color="primary" className={classes.buttonLink} onClick={onShowForm}>
            { translation('requestTest') }
          </Button>
        </Link>
        <Button
          variant="outlined"
          color="primary"
          endIcon={<PlayIcon />}
          className={classes.videoBtn}
          onClick={() => showVideo()}
        >
          {translation('watchVideo')}
        </Button>
      </div>
    </>
  );

  if (mobileLayout) {
    component = <LayoutMobile showVideo={showVideo} />
  };

  return (
    <div className={classes.rootContent}>
      { component }
    </div>
  );
}

export default ColumnLeft;
