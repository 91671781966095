import Axios from 'axios';

type GetRequest = (
  url: string,
  config: {
    successRequest?: (data) => void,
    errorRequest?: (error) => void
  }) => void;

type GetRequestAPI = (
  apiMethod: string,
  config: {
    source: string,
    successRequest?: (data) => void,
    errorRequest?: (error) => void
  }) => void;

// Make normal requests
export const getRequest:GetRequest = (url, {
  successRequest = (data) => {},
  errorRequest = (error) => {},
}) => {
  Axios.get(url)
    .then(data => {
      successRequest(data);
    })
    .catch(error => {
      errorRequest(error);
    });
};

export const getRequestAPI:GetRequestAPI = (apiMethod, {
  source,
  successRequest = (data) => {},
  errorRequest = (error) => {},
}) => {
  const payload = {
    apiMethod,
    httpMethod: 'GET',
    source
  };

  Axios.get("api/"+apiMethod, {params: payload})
    .then(data => {
      successRequest(data);
    })
    .catch(error => {
      errorRequest(error);
    });
};