import React from 'react';
import { Grid, Typography, Collapse } from '@material-ui/core';
import { MinusIcon, PlusIcon } from '../../../../../components/icons';
import clsx from 'clsx';
import qnaStyles from './QnaComponent.styles';

export interface QuestionAndAnswerProps {
  question: string;
  answer: string;
  id: string;
  answerTwo?: string;
  answerThree?: string;
  telephone?: string;
  idokSupportEmail?: string;
  iconExpanded: string;
  active?: boolean;
  url?: string;
}

function Qna({
  question,
  answer,
  answerTwo,
  answerThree,
  telephone,
  idokSupportEmail,
  iconExpanded,
  id,
  url='',
  active=false
}: QuestionAndAnswerProps) {
  const [checked, setChecked] = React.useState(active);
  const handleChange = () => setChecked(prev => !prev);
  const classes = qnaStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.containerItem}>
        <Typography variant="body1" className={classes.question} onClick={() => handleChange()}>
          {question}
        </Typography>
          <div className={classes.icons} onClick={() => handleChange()} data-href={`${url}/${id}`}>
            {checked ? <MinusIcon className={clsx({ iconExpanded }, { [iconExpanded]: checked })} /> : <PlusIcon />}
          </div>
      </Grid>
      <Collapse in={checked} className={classes.collapse}>
        <Typography variant="body1" component="span">
          {answer}
        </Typography>

        <Typography variant="body1" component="span" className={classes.bold}>
          {telephone}
        </Typography>
        <Typography variant="body1" component="span">
          {answerTwo}
        </Typography>
        <Typography variant="body1" component="span" className={classes.bold}>
          {idokSupportEmail}
        </Typography>
        <Typography variant="body1" component="span">
          {answerThree}
        </Typography>
      </Collapse>
    </Grid>
  );
}

export default Qna;
