import { makeStyles, Theme, createStyles } from '@material-ui/core';

const integrationsStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      justifyContent:"center",
      alignItems: "center",
      marginBottom: '149px',
      flexDirection: 'column'
    },
    integrationSubtitleContainer: {
      paddingBottom: 80,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: 76,
        alignSelf: 'flex-start',
        paddingLeft: '7%'
      }
    },
    integrationSubtitleText: {
      font: 'normal normal 300 20px/24px Lato'
    },
    newSliderContainer: {
      width: '100%',
      maxWidth: '1374px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '& .swiper': {
        '& .swiper-wrapper': {
          alignItems: 'center',
          height: '350px',
          '& .swiper-slide-active': {
            '& .slide-item-container': {
              WebkitTransition: 'height 0.2s',
              MozTransition: 'height 0.2s',
              msTransition: 'height 0.2s',
              OTransition: 'height 0.2s',
              transition: 'height 0.2s',
              height: 255,
            },
            '& + .swiper-slide': {
              '& .slide-item-container': {
                WebkitTransition: 'height 0.2s',
                MozTransition: 'height 0.2s',
                msTransition: 'height 0.2s',
                OTransition: 'height 0.2s',
                transition: 'height 0.2s',
                height: 293,
                [theme.breakpoints.down(875)]: {
                  height: 255
                },
              },
              '& + .swiper-slide': {
                '& .slide-item-container': {
                  WebkitTransition: 'height 0.2s',
                  MozTransition: 'height 0.2s',
                  msTransition: 'height 0.2s',
                  OTransition: 'height 0.2s',
                  transition: 'height 0.2s',
                  height: 331,
                  [theme.breakpoints.down(1400)]: {
                    height: 293
                  },
                  [theme.breakpoints.down(1130)]: {
                    height: 255
                  },
                },
                '& + .swiper-slide': {
                  '& .slide-item-container': {
                    WebkitTransition: 'height 0.2s',
                    MozTransition: 'height 0.2s',
                    msTransition: 'height 0.2s',
                    OTransition: 'height 0.2s',
                    transition: 'height 0.2s',
                    height: 293,
                    [theme.breakpoints.down(1400)]: {
                      height: 255
                    },
                  },
                  '& + .swiper-slide': {
                    '& .slide-item-container': {
                      WebkitTransition: 'height 0.2s',
                      MozTransition: 'height 0.2s',
                      msTransition: 'height 0.2s',
                      OTransition: 'height 0.2s',
                      transition: 'height 0.2s',
                      height: 255,
                    },
                  }
                }
              }
            }
          }
        },
        '& .swiper-slide': {
          display: 'flex',
          justifyContent: 'center',
          width: '100% !important',
          maxWidth: '266px',
          alignItems: 'center'
        }
      },
      [theme.breakpoints.down(1400)]: {
        maxWidth: '1108px'
      },
      [theme.breakpoints.down(1130)]: {
        maxWidth: '842px'
      },
      [theme.breakpoints.down(875)]: {
        maxWidth: '576px'
      },
      [theme.breakpoints.down(600)]: {
        maxWidth: '335px',
        gap: 23
      }
    },
    sliderArrow: {
      cursor: 'pointer'
    },
    newSquareContainer: {
      margin: '8px',
      boxShadow: '0 0 5px #00000029',
      borderRadius: 26,
      '&:hover': {
        background: '#f5f5f5'
      }
    },
    titleContent: {
      display:'flex',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 20,
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        paddingLeft: '7%',
        paddingRight: '7%',
        paddingBottom: 20,
      },

      [theme.breakpoints.down('xs')]: {
        paddingBottom: '10px',
      },
    },
    title: {
      font: 'normal normal 600 48px/75px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
      zIndex: 1,
      position: 'relative',

      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/44px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/36px Poppins',
      },
    },
    greenBox: {
      width: '52px',
      height: '52px',
      background: '#E0FCD6 0% 0% no-repeat padding-box',
      position: 'absolute',
      zIndex: -1,

      [theme.breakpoints.down('md')]: {
        bottom: '52%',
        left: -20
      },

      [theme.breakpoints.down('xs')]: {
        bottom: '52%',
        left: -23
      },

      [theme.breakpoints.up(805)]: {
        bottom: '0%',
        left: -20
      },

      [theme.breakpoints.up('lg')]: {
        bottom: '17%',
        left: -35
      },

    },
    greenLetter: {
      color: '#1AA732'
    }
  })
);

export default integrationsStyles;