import { makeStyles } from '@material-ui/core';

const cardFeatureStyles = makeStyles(theme => ({
  title: {
    marginTop: 5,
    marginBottom: 8,
  },
  card: {
    paddingBottom: '36px',

    [theme.breakpoints.down('md')]: {
      paddingBottom: '16px',
    },
  },
  cardContent: {
    paddingTop: 0,
    height: '116.17px',
    width: '401px',

    [theme.breakpoints.down(1370)]: {
      height: '135px',
      width: '350px',
    },

    [theme.breakpoints.down('md')]: {
      height: 'unset',
      width: 'unset',
    },
  },
  cardHeader: {
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    left: -35,
  },
  href: {
    color: 'black',
    textDecoration: 'underline',
  },
}));

export default cardFeatureStyles;