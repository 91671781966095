import { makeStyles } from '@material-ui/core/styles';
import DefaultColor from './ColorConstants';

const cookieComponentStyles = makeStyles((theme) => ({
    cookieContainer: {
        position: 'fixed',
        zIndex: 9999,
        padding: '5px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `${DefaultColor.grayScales.ligtherGray}`,
        color: '#1d1d1d',
        minHeight: 72,
        borderTop: '1px solid #EBEBEB',
        width: '100%',
        maxWidth: 745,
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0px 0px 6px #00000040'
    },
    cookieText: {
        margin: 0,
        font: 'normal 300 13px "Source Sans 3", Helvetica, Arial, sans-serif'
    },
    cookieLink: {
        color: '#1d1d1d',
        font: 'normal normal 600 14px "Source Sans 3", Helvetica, Arial, sans-serif',

        '&:hover': {
            color: 'inherit'
        }
    },
    cookieButton: {
        marginLeft: 30,
        fontSize: 14,
        fontWeight: 600,
        fontFamily: '"Source Sans 3", Helvetica, Arial, sans-serif',
        cursor: 'pointer',
        borderRadius: 3,
        color: `${DefaultColor.grayScales.white}`,
        background: `${DefaultColor.primary.dark}`,
        border: 'none',
        padding: '9px 18px',

        '&:hover': {
            background: `${DefaultColor.primary.primary}`,
        }
    },
    coockieIconContainer: {
        marginRight: 20,
        display: 'flex',

        '& svg': {
            fill: '#1d1d1d'
        },

        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    }
}));

export default cookieComponentStyles;