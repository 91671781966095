export const listMobile = [
  'Aplicación compatible con los sistemas operativos Android (Google), iOS (Apple) y HarmonyOS (Huawei);',
  'Autentíquese con sus credenciales iDOK o con sus credenciales GTS, proveedor de firmas digitales (únicamente para clientes GTS);',
  'Acceda a todas las entidades asociadas a su perfil de usuario;',
  'Consulte y realice filtros de búsqueda por documentos enviados, recibidos, entre otros;',
  'Vea los documentos, las entidades asociadas y las remisiones realizadas;',
  'Asocie decisiones, opiniones o informaciones;',
  'Asigne plazos y niveles de prioridad;',
  'Remita documentos a departamentos y/o usuarios específicos;',
  'Marque los documentos a firmar;',
  'Firme un documento o varios documentos simultáneamente con GTS (firma por lotes);',
  'Valide su firma digital GTS a través de un código OTP, pin, huella digital (o Face ID, para los usuarios del sistema operativo iOS);',
  'Reciba notificaciones, en tiempo real, sobre la recepción de nuevos documentos.'
];

export default {
  translation: {
    idokLogo: 'Gestión de Documentos iDOK',
    login: 'Inicio de Sesión',
    features: 'Características',
    gestaoSistemas: 'Gestión de Sistemas',
    prices: 'Precios',
    news: 'Noticias',
    requestTest: 'PRUEBA GRATUITA',
    requestPrices: 'Solicitar Presupuesto',
    request: 'Solicitar',
    confirmTest: 'Confirmar',
    watchVideo: 'VER VÍDEO',
    talkToUs: 'Comuníquese con nosotros',
    telephone: '707 451 451. ',
    idokSupportEmail: 'apoio@idok.pt. ',
    enter: 'Entrar',
    mainContentHome:
      'iDOK es un software de gestión documental sencillo e intuitivo que permite registrar, consultar y archivar toda la documentación, en cualquier lugar. Desmaterialice y organice el funcionamiento interno de su entidad.',
    makeYourTitleHome: 'HAGA QUE LA',
    managementTitleHome: 'GESTIÓN',
    documentaryTitleHome: 'DE SUS DOCUMENTOS',
    lighterTitleHome: 'SEA MÁS LIGERA',
    lighterTitleHome2: 'SEA MÁS EFICIENTE',
    lighterTitleHome3: 'SEA MÁS SOSTENIBLE',
    lighterTitleHome4: 'SEA MÁS FÁCIL',
    normalTitleFeatures: '¡Lo esencial para que su',
    highlightTitleFeatures: 'Entidad fluya!',
    normalTitleIntegrations: 'Conozca las',
    highlightTitleIntegrations: 'integraciones de iDOK',
    subtitleIntegrations: 'Automatice su Gestión de Documentos',
    integrations: 'Integraciones',
    featuresDetails: 'Funcionalidades',
    knowMore: 'Más información',
    documentManagementTitle: 'Gestión de Documentos',
    documentManagementContent:
      'Ya sea por escaneo o por upload, los documentos subidos están siempre disponibles para su clasificación y posteriormente son archivados en el sistema. Estos documentos también podrán establecerse como plantillas.',
    modelManagement: 'Gestión de plantillas',
    documentClassification: 'Clasificación de documentos',
    opticalCharacterRecognition: 'Reconocimiento Óptico de Caracteres',
    filesManagement: 'Gestión de archivos',
    documentSeries: 'Series de documentos',
    customisableFields: 'Campos personalizables',
    mef: 'MEF (Macroestructura)',
    associationOfResponseDeadlines: 'Asociación de plazos de respuesta',
    workflowTitle: 'Flujo de Trabajo',
    workflowContent:
      'Cree procesos de flujo de trabajo para el seguimiento de documentos. Las etapas son manejadas únicamente por un grupo restringido de personas establecidas por la entidad. De este modo, usted gestiona el ciclo de vida completo de la documentación de su entidad.',
    search: 'Búsqueda',
    documentsDistribution: 'Distribución de documentos',
    processingFlows: 'Tramitación de flujos',
    deadlinesAndReports: 'Plazos e informes',
    flowchartsCreation: 'Creación de diagramas de flujo',
    sendingAlertsAndEmails: 'Envío de alertas y correos electrónicos',
    rulesOfCommunicationWithWebServices: 'Reglas de comunicación con servicios web',
    processesTitle: 'Gestión de Procesos',
    processesContent:
      'Una carpeta virtual, agregadora de documentos, que permite asociar ficheros a un determinado proceso, para asegurar que toda la información esté organizada y centralizada. También podrá asignar gestores, consultar y exportar información.',
    personalProcessManagement: 'Gestión personal de procesos',
    exportProcessListing: 'Exportación del listado de procesos',
    assignmentOfManagers: 'Asignación de gestores',
    safetyTitle: 'Seguridad',
    safetyContent:
      'Acceda a un área de gestión y grupos de usuarios en la que sea posible definir permisos y responsabilidades para cada uno de ellos y definir el acceso por usuario y perfil de usuario a determinadas funcionalidades y contenidos.',
    restrictedUserAccess: 'Acceso restringido de usuarios',
    reports: 'Informes',
    accessRegistration: 'Registro de acceso',
    digitalSignatureTitle: 'Firma digital',
    digitalSignatureContent:
      'Firme digitalmente en su ordenador los documentos subidos y enviados, a través del ',
    digitalSignatureSecondContent:
      ' de GTS - Global Trusted Sign o desde el Documento Nacional de identidad. De este modo, se garantiza la autoría de sus documentos.',
    digitalSignatureHref: 'certificado digital cualificado',
    ownershipGuarantee: 'Garantía de titularidad',
    batchSubscription: 'Firma por lotes',
    severalSignatures: 'Posibilidad de tener varias firmas en el mismo documento',
    customSolutionTitle: 'Solución personalizada',
    customAutomationTitle: 'Automatización',
    customGestaoSistemasTitle: 'Módulo de Gestión de Sistemas',
    customPortalServicosTitle: 'Portal de Servicios',
    customSolutionContent:
      'iDOK es totalmente configurable, a fin de que se adapte a su entidad. Puede añadir nuevas funcionalidades que complementen la gestión de documentos, lo que proporciona un entorno más intuitivo.',
    customAutomationContent: 'Nuestro software garantiza un progreso sin precedentes en los procesos administrativos. iDOK hace especial hincapié en la automatización para garantizar eficiencia y reducción de errores. Modernice su gestión de documentos.',
    customGestaoSistemasContent: 'El Módulo de Gestión de Sistemas es la respuesta que busca en materia de Auditorías. Es un fuerte aliado para el cumplimiento de políticas, procesos y procedimientos, con la posibilidad de limitar el acceso a determinados documentos.',
    customPortalServicosContent: 'La plataforma iDOK permite integrar portales externos que facilitan el acceso a los servicios.',
    automaticIndex: 'Indexación automática',
    notificationAlert: 'Notificaciones y alertas',
    fluxoDinamico: 'Flujo de trabajo dinámico',
    integracaoSistemasExterno: 'Integración con sistemas externos',
    gestaoVersaoAutomatica: 'Gestión automática de versiones',
    gestaoModelos: 'Gestión de plantillas',
    registoAutenticacao: 'Registro y autenticación del público destinatario',
    solicitacaoPedidos: 'Solicitudes en línea y consulta de los servicios de información',
    envioDadosPedidos: 'Cumplimentación/envío de datos y presentación de solicitudes para análisis',
    geracaoPagamentos: 'Generación de pagos',
    envioSmsEmail: 'Envío de notificaciones por SMS y correo electrónico',
    gestaoReservas: 'Gestión de reservas',
    geracaoDocumentosDinamicos: 'Generación dinámica de documentos',
    emailStructure: 'Estructura del correo electrónico',
    departmentManagement: 'Gestión de departamentos',
    billingInquiry: 'Consulta de facturación',
    entityManagement: 'Gestión de Entidades',
    normalTitleFreeTrial: 'Pruebe',
    highlightTitleFreeTrial: 'Gratuitamente',
    introFreeTrial:
      '¿Quiere saber más sobre la desmaterialización de documentos? Pídanos una demostración y conozca todas las ventajas de un software de gestión de documentos como iDOK, sin costes ni limitaciones adicionales.',
    makeRegisterFreeTrial: 'Haga su pre-registro y nos pondremos en contacto con usted.',
    askQuestionPrimavera: '¡Estamos a su disposición para responder a sus preguntas! Simplifique ahora su gestión de documentos.',
    contactForFreeTrial: 'Póngase en contacto con nosotros para adherir o hacer una prueba sin compromiso.',
    entity: 'Nombre de la Entidad',
    email: 'Correo Electrónico',
    phone: 'Teléfono',
    country: 'País',
    portugal: 'Portugal',
    brazil: 'Brasil',
    canada: 'Canadá',
    requiredFields: 'Campos de cumplimentación obligatoria.',
    readTerms: 'Declaro que he leído y acepto las ',
    conditionsTerms: 'Condiciones Generales de Registro',
    codicoesGeraisPdf: 'cgadesao_es.pdf',
    registerTerms: 'Declaro conocer y aceptar la ',
    thanksForm: 'Gracias por su prerregistro, el cual se realizó con éxito.',
    thanksFormPrimavera: 'Gracias por su interés en integrarse con ERP Primavera.',
    thanksFormSelosTemporais: 'Gracias por su interés en los Sellos de Tiempo GTS.',
    soonForm: 'En breve nos pondremos en contacto con usted a través de nuestra línea de ayuda.',
    freePrice: '0.00€',
    cloudPrice: '9,90€',
    fiveGbPrice: '40€',
    tenGbPrice: '75€',
    twentyGbPrice: '140€',
    aboveTwentyGbPrice: '+15€',
    eachTwoGb: '/cada 2GB',
    oneGb: '1GB',
    fiveGb: '5GB',
    tenGb: '10GB',
    twentyGb: '20GB',
    free: 'Gratis',
    perMonth: '/mes',
    until: 'Hasta ',
    enjoy: 'Disfrute de',
    overThan: 'Superior a ',
    thirtyDaysFree: '30 Días Gratis',
    withoutCommitment: '¡sin compromiso!',
    plansCloud: 'Planes en la Nube',
    otherPlans: 'OTROS PLANES',
    normalTitlePrices: 'Un Plan a la medida de ',
    highlightTitlePrices: 'Su Entidad',
    introPrices: 'Cada entidad merece un plan de gestión de documentos personalizado para aprovechar al máximo todas sus funcionalidades.',
    titlePrices: 'Invierta en la desmaterialización de documentos y en un plan adaptado a sus necesidades.',
    tryItForFree: '¡Pruebe ',
    duringThirtyDays: 'durante 30 días!',
    normalContentPrice: 'Almacenamiento ',
    highlightContentPrice: 'limitado',
    every: 'Todas las ',
    idokFunctionalities: 'Funcionalidades iDOK',
    cloud: 'Nube',
    since: 'Desde',
    try: 'Pruebe ',
    freeForThirtyDays: 'gratuitamente durante 30 días',
    cloudStorage: 'Almacenamiento en la Nube ',
    fromOneGb: 'desde 1GB',
    onPremise: 'On-Premise',
    priceOnRequest: 'Precio bajo pedido',
    normalEntityPlan: '¿Necesita un plan adaptado a la realidad de su entidad?',
    highlightEntityPlan: 'Tenemos una solución adaptada a sus necesidades.',
    fullPriceList: 'LISTA COMPLETA DE PRECIOS',
    priceFormText: 'Indíquenos sus datos, sin compromiso, y le enviaremos una propuesta que se ajuste a su realidad.',
    select: 'Seleccione',
    workers: 'Nº de empleados',
    entityType: 'Tipo de Entidad',
    thanks: '¡Gracias!',
    normalTitleAcin: 'Una solución del',
    highlightTitleAcin: 'ACIN - iCloud Solutions Lda',
    firstParagraphAcinInit: 'El ',
    firstParagraphAcinLink: 'ACIN - iCloud Solutions Lda',
    firstParagraphAcinEnd: ' es pionero en el área de Computación en la Nube y apuesta por soluciones prácticas, económicas y fáciles de usar, que responden a las diferentes necesidades del mercado y de los clientes.',
    highlightSecondParagraphAcin:'Con más de 20 años en el mercado, el Grupo ACIN está en constante expansión, contando en la actualidad con 4 empresas y 14 plataformas de software.',
    normalSecondParagraphAcin: 'Pero las perspectivas son que estas cifras sigan creciendo.',
    thirdParagraphAcin:
      'Cada día marcamos la diferencia. Prueba de ello son los más de 180 mil clientes que confían en nuestro trabajo y creen en nuestras soluciones de vanguardia. Su satisfacción es nuestro mayor propósito.',
    highlightNumberClientAcin: '+ 180.000',
    clients: 'clientes',
    normalNumberClientAcin: 'en todo el Mundo',
    highlightBillsAcin: '+ 15.000.000 €',
    normalBillsAcin: 'de facturación',
    highlightEmployeesAcin: '+ 350',
    normalEmployeesAcin: 'empleados',
    normalTitleNewsletter: '¡Conozca nuestras últimas',
    highlightTitleNewsletter: 'Novedades!',
    subscribe: 'Suscríbase',
    menu: 'Menú',
    contacts: 'Contactos',
    contactsCost: 'Importe máximo a pagar por minuto: 0,09€ (+IVA) para las llamadas procedentes de redes fijas y 0,13€ (+IVA) para llamadas procedentes de redes móviles.',
    serviceHoursText: 'Estamos a su disposición los días laborables, de 9h00 a 18h00.',
    addresses: 'Direcciones',
    solution: 'Una solución',
    demo: 'Demostración',
    maps: 'Ver mapa',
    copyright: 'Todos los derechos reservados',
    terms: 'Términos y Condiciones',
    privacyPolicy: 'Política de Privacidad',
    suggestions: 'Elogios, Sugerencias y Reclamos',
    subTitleFaqs: "FAQ GESTIÓN DE DOCUMENTOS iDOK",
    normalTitleFaqs: '¿En qué podemos ',
    highlightTitleFaqs: 'Ayudarle?',
    searchQuestion: 'Buscar pregunta',
    aboutUsQuestionFaqs: '¿Qué es iDOK?',
    aboutUsAnswerFaqs:
      'iDOK es un software de gestión documental que permite registrar, consultar y archivar toda la documentación de su entidad. Esta plataforma totalmente Web, alojada en la Nube, garantiza un estricto seguimiento de los procedimientos internos y de la seguridad de la información, disponible las 24 horas del día, a través de cualquier dispositivo.',
    advantagesQuestionFaqs: '¿Cuáles son las ventajas del software de gestión de documentos?',
    advantagesAnswerFaqs:
      'La gestión de documentos puede ser una actividad exigente y que requiere mucho tiempo. Pero la desmaterialización de los documentos evita errores, tales como la dispersión y la pérdida de documentos importantes, y permite ahorrar tiempo y recursos.',
    adaptationQuestionFaqs: '¿iDOK se adapta a cualquier entidad?',
    adaptationAnswerFaqs: 'Sí, ya sean organismos públicos o privados.',
    possibleBrowsersQuestionFaqs: '¿Qué navegadores puedo utilizar para acceder a la Plataforma de Gestión de Documentos iDOK?',
    possibleBrowsersAnswerFaqs:
      'El acceso a iDOK, así como el uso correcto de las funcionalidades disponibles, está garantizado en los siguientes navegadores web: Google Chrome, Mozilla Firefox, Safari, Opera, Edge.',
    digitalSignatureQuestionFaqs: '¿Es posible utilizar firmas digitales en la Plataforma iDOK?',
    digitalSignatureAnswerFaqs:
      'iDOK está diseñado para el uso de firmas digitales certificadas, ya sean avanzadas o cualificadas.',
    sendAndReceiveDocumentQuestionFaqs: '¿iDOK permite enviar y recibir documentos de otras entidades?',
    sendAndReceiveDocumentAnswerFaqs:
      'Sí. Siempre y cuando las entidades estén registradas en la Plataforma, sólo es necesario configurar esa opción.',
    documentReceptionQuestionFaqs: '¿Es posible recibir documentos de otras plataformas?',
    documentReceptionAnswerFaqs:
      'iDOK permite en tiempo real la integración de documentos de otras plataformas a través de mecanismos de servicios web.',
    flowChartsQuestionFaqs: '¿iDOK permite la creación de flujos?',
    flowChartsAnswerFaqs:
      'Sí. Podrá establecer flujos para el seguimiento de los documentos, entre los distintos departamentos, en función de la información introducida. De esta manera, los pasos quedan bajo el cuidado de un grupo restringido de usuarios.',
    govAutenticationQuestionFaqs: '¿Para qué sirve la aplicación AUTENTICAÇÃO.GOV?',
    govAutenticationAnswerFaqs: 'La aplicación AUTENTICAÇÃO.GOV permite utilizar y recoger los datos del Documento Nacional de Identidad portugués.',
    usersQuestionFaqs: '¿Cuántos usuarios puedo crear en iDOK?',
    usersAnswerFaqs: 'El número de usuarios es ilimitado.',
    customPermissionsQuestionFaqs: '¿Se pueden personalizar los permisos de los usuarios?',
    customPermissionsAnswerFaqs:
      'Sí, puede definir qué documentos pueden verse o editarse, o qué información está disponible.',
    restrictAccessQuestionFaqs: '¿Es posible restringir el acceso a determinados usuarios?',
    restrictAccessAnswerFaqs:
      'De forma sencilla, podrá restringir el acceso de los usuarios a determinados departamentos, ya sea para el enrutamiento, la clasificación o la búsqueda de documentos. Determine qué información se puede introducir, modificar, eliminar y consultar, limitando el acceso a los menús y módulos de la plataforma.',
    supportQuestionFaqs: '¿Ofrece iDOK un servicio gratuito de asistencia telefónica?',
    supportAnswerFaqs: 'Sí. Ponemos a su disposición una línea telefónica exclusiva: ',
    supportTwoAnswerFaqs: 'También puede ponerse en contacto con nosotros a través del correo electrónico ',
    supportThreeAnswerFaqs:
      'Estamos a su disposición, todos los días laborables, de 8:30 a 19:00, sin coste adicional.',
    tryAnotherQuestion: 'Pruebe con otra pregunta...',
    user:'Usuario',
    password: 'Contraseña',
    forgetPassword: '¿Ha olvidado la contraseña?',
    authenticate: 'Autenticar',
    loginWithCCContent: 'La autenticación con el Documento Nacional de Identidad portugués permite iniciar la sesión en el portal de forma práctica y segura.',
    loginWithGtsIdContent: 'La autenticación mediante GTS ID le permite acceder, de forma segura y con un único inicio de sesión, a las plataformas iDOK y GTS.',
    aceitar: 'Aceptar',
    cookieText: 'iDOK utiliza cookies para mejorar su experiencia en este sitio web. Al navegar en el mismo, Ud. da su consentimiento a su utilización. Conozca más sobre nuestra {tagopen} política de cookies {tagclose}',
    menuLogin: {
      opt1: 'Iniciar Sesión',
      opt2: 'Documento de Identidad portugués',
      opt3: 'GTS ID',
      opt4: 'Recuperar contraseña',
      opt5: 'Cambiar contraseña',
      opt6: '¿Cómo ingresar con el DNI portugués?',
    },
    recoveryPass: 'Introduzca su dirección de correo electrónico',
    goBack: 'Volver',
    recovery: 'Recuperar',
    footerText: {
      text1: '¿Aún no tiene una cuenta en iDOK?',
      text2: '¡Pídanos una demostración',
      text3: 'y pruebe gratuitamente iDOK!'
    },
    loginBannerText:{
      text1: 'Firme en cualquier lugar',
      text2: 'con la Firma Electrónica GTS',
      knowMore: 'Más información',
    },
   wrongCredentials: 'Usuario o Contraseña incorrecta',
    error: 'Se ha producido un error',
    goback: 'Volver',
    page404: {
      title: '¡Vaya!',
      subTitle: '¡Parece perdido en medio de tantos documentos!',
      description: 'Regrese a iDOK y le ayudaremos.'
    },
    page500: {
      title: 'Crash!',
      subTitle: 'Error interno del servidor',
      description: '¡Afortunadamente, todo está alojado en la nube!'
    },
    page403: {
      title: '¡Oh, no!',
      subTitle: 'Acceso Denegado',
      description: 'Lo sentimos, pero no está autorizado a acceder a lo solicitado'
    },
    page401: {
      title: '¡Vaya!',
      subTitle: 'Autorización Requerida',
      description: 'Por favor, inicie sesión y acceda a su Gestión de Documentos en la nube.'
    },
    acinGroup: {
      leftSide: {
        title: {
          quality: 'Calidad y ',
          security: 'Seguridad ',
          rest: 'en la Gestión de Documentos'
        },
        firstText: {
          start: 'La alta calidad que caracteriza a los productos de ',
          end: ', empresa del Grupo ACIN, es el resultado de un riguroso seguimiento de los procedimientos internos que garantizan la mejora continua de los servicios y promueven el aumento de los niveles de satisfacción de los clientes.',
          link: 'ACIN - iCloud Solutions Lda'
        },
        iso9001: 'NORMA ISO 9001: ',
        iso9001Desc: 'Garantiza los compromisos de calidad y satisfacción de los servicios proporcionados por ACIN.',
        iso27001: 'NORMA ISO 27001: ',
        iso27001Desc: 'Garantiza la confidencialidad, integridad y privacidad de la información gestionada por nuestras plataformas.',
        iso20000: 'NORMA ISO 20000: ',
        iso20000Desc: 'Garantiza el cumplimiento de los niveles de servicio establecidos para nuestros clientes y proveedores.',
        lastText: 'Sólo un pequeño número de empresas portuguesas cuentan con una certificación tecnológica tan rigurosa.'
      },
      rightSide: {
        platform: '+ plataformas ',
        rest: 'del ACIN - iCloud Solutions Lda',
        knowMore: 'Más información',
        from: 'del',
        acinGroup: 'ACIN - iCloud Solutions Lda',
      },
      platforms: {
        igest: {
          title: 'iGest',
          desc: 'Software de Facturación',
          url:'igest.pt'
        },
        juntas: {
          title: 'Juntas Online',
          desc: 'Servicio de Consejos Parroquiales',
          url: 'juntasonline.pt'
        },
        ifreg: {
          title: 'iFREG',
          desc: 'Software de Gestión de Parroquias Civiles',
          url: 'ifreg.pt'
        },
        paypay: {
          title: 'PayPay',
          desc: 'Solución de Pagos',
          url: 'paypay.pt'
        },
        gts: {
          title: 'GTS',
          desc: 'Proveedora de Servicios de Confianza',
          url: 'globaltrustedsign.com'
        },
        acingov: {
          title: 'acinGov',
          desc: 'Plataforma Electrónica de Contratación Pública',
          url: 'acingov.pt'
        },
        imed: {
          title: 'imed',
          desc: 'Software de Gestión Clínica',
          url: 'imed.pt'
        },
        iParque: {
          title: 'iParque',
          desc: 'Software de Gestión de Aparcamientos',
          url: 'iparque.pt'
        },
        irh: {
          title: 'iRH',
          desc: 'Software de Gestión de Asistencia',
          url: 'irh.pt'
        },
        cestado: {
          title: 'C. Estado',
          desc: 'Oportunidades de Negocios',
          url: 'comprasdoestado.pt'
        },
        ilink: {
          title: 'iLink',
          desc: 'EDI y Facturación Electrónica',
          url: 'ilink.pt'
        },
        taxi: {
          title: 'omeutáxi',
          desc: 'Software de facturación para Taxistas',
          url: 'omeutaxi.pt'
        },
        agromarket: {
          title: 'Agromarket',
          desc: 'Plataforma de venta de productos agrícolas',
          url: 'agromarket.pt'
        },
        denuncias: {
          title: 'P. Denúncias',
          desc: 'Plataforma para la gestión de denuncias',
          url: 'portaldedenuncias.pt'
        }
      }
    },
    acin: 'ACIN group',
    microsoftPlatform: 'Plataforma de Productividad Avanzada',
    salesforce: 'Salesforce',
    selosTemp: 'Sellos de Tiempo',
    cardReader: 'Lectores de Tarjetas',
    boxEmails: 'Buzones de Correos Electrónicos',
    emailRequired: 'Correo electrónico obligatorio',
    validEmail: 'Debe ser una dirección de correo electrónico válida',
    passwordRequired: 'Contraseña obligatoria',
    recoveryPassError: 'Se ha producido un error al intentar recuperar la contraseña',
    nameRequired: 'Nombre obligatorio',
    phoneRequired: 'Teléfono obligatorio',
    onlyNumbers: 'El número de teléfono es obligatorio',
    countryRequired: 'El país es obligatorio',
    termsRequired: 'Deverá confirmar as Condições Gerais de Adesão',
    policyRequired: 'Deverá confirmar a Política de Privacidade',
    policyLink: 'https://acin.pt/es/politica-privacidade/',
    workersRequired: 'El número de empleados es obligatorio',
    entityTypeRequired: 'El tipo de entidad es obligatorio',
    recoveryPassEmailSent: 'El correo electrónico para la recuperación de su contraseña fue enviado con éxito.',
    newPassword: 'Nueva contraseña',
    newPasswordConfirmation: 'Confirmación de la nueva contraseña',
    passwordRules: {
      title: 'Su contraseña deberá:',
      rule1: 'Tener al menos 10 caracteres.',
      rule2: 'Contener 3 de estos elementos: ',
      rule3: 'Letras minúsculas; ',
      rule4: 'Letras mayúsculas; ',
      rule5: 'Números; ',
      rule6: 'Caracteres especiales.',
      rule7: 'Alcanzar nivel fuerte.'
    },
    veryWeak:'Muy Débil',
    weak: 'Débil',
    moderate: 'Moderada',
    hard: 'Fuerte',
    modify: 'Cambiar',
    wrongConfirmPassword: 'La contraseña no coincide',
    passwordAlterSuccessfully: ' Su contraseña ha sido cambiada con éxito',
    enterWithCC: '¿Cómo ingresar con el DNI portugués?',
    subscribeCCError: 'Se ha producido un error al intentar registrarse con el DNI portugués',
    submit: 'Enviar',
    subscribeCCText: 'Para acceder con su Documento Nacional de Identidad portugués, deberá activar esta forma de acceso.',
    sentEmail: 'El email fue enviado con exito',
    acinGroupPlatforms: 'Plataformas',
    conectionWithPortals: 'Possibilidad de interconexión con portal de servicios',
    accessCentralized: 'Acceso centralizado de información',
    alertsAndNotifications: 'Alertas y notificaciones',
    clasificationPlan: 'Plano de clasificación',
    protectedInformation: 'Clasificación de información protegida',
    signOnAuthentication: 'Autenticación Single Sign-On',
    relatories: 'Relatórios y estadísticas',
    quality: 'Calidad',
    mainAnimation: 'Gestión de documentos',
    mobileAnimation: 'Aplicación de gestión de documentos',
    phonesAnimation: 'Firma digital en la aplicación idok',
    acinVideo: 'Transformación digital',
    mobileHome: {
      mainTitle: {
        line1: '¿Ya conoce la aplicación',
        line2: {
          default: 'de ',
          color: 'Gestión de Documentos?'
        },
      },
      secondaryTitle: 'app idok',
      paragraphs: {
        text1: 'Le presentamos la ampliación de su solución de Gestión de Documentos, que le ofrece mayor flexibilidad y movilidad al momento de gestionar su información.',
        text2: {
          init: 'Nuestra aplicación de gestión de documentos está integrada con el proveedor de servicios de confianza ',
          middle: ' y le permite, si tiene una ',
          bold: ' firmar digitalmente documentos desde su teléfono móvil.',
          link1: 'Global Trusted Sign',
          link2: 'firma cualificada de GTS,'
        },
      },
      button: 'Más información',
      imageAlt: 'Pantallas de la aplicación de Gestión de Documentos iDOK'
    },
    mobile: {
      linkBold: 'nuevo',
      mainTitle: {
        line1: 'Aplicación innovadora de ',
        line2: {
          color: 'Gestión de Documentos'
        },
      },
      mainParagraph: {
        init: '¡Una aplicación de Gestión Documental que le garantiza ',
        bold: 'total movilidad',
        end: '! Gestione sus documentos y fírmelos digitalmente de forma rápida, sencilla y segura. Disponible para sistemas operativos '
      },
      secondaryTitle: {
        bold: 'Movilidad total ',
        normal: 'con total ',
        color: 'Seguridad'
      },
      secondaryParagraph: 'La aplicación iDOK es una extensión de su solución de Gestión de Documentos, un complemento que le ofrece mayor flexibilidad y movilidad a la hora de gestionar su información.',
      thirdTitle: {
        color: 'Firma Digital',
        normal: 'en la palma de su mano'
      },
      mobileImgAlt: {
        img1: "Remisión de documentos a usuarios o departamentos, en la aplicación de Gestión de Documentos.",
        img2: "Pantalla con la información del documento, entidades asociadas y remisiones realizadas en la aplicación idok.",
        img3: "Pantalla de inicio de la aplicación de Gestión de Documentos con los dos tipos de solución.",
        img4: "Lista de documentos recibidos, con filtros de búsqueda en la aplicación idok.",
        img5: "Pantalla con la inserción de la firma digital en el documento en la aplicación idok."
      },
      thirdParagraph: {
        text1: 'Cada vez se realizan más trámites electrónicos de documentos, que requieren autenticación.',
        text2: 'Esta validación puede realizarse mediante la colocación de una firma digital cualificada, promoviendo la transformación digital en su organización.',
        text3: {
          init: 'La aplicación de Gestión Documental iDOK está integrada con el proveedor de servicios de confianza ',
          link1: 'Global Trusted Sign (GTS)',
          middle: ', que proporciona ',
          link2: 'certificados de firma digital',
          end: ' de varios tipos, a saber, para personas físicas y jurídicas.'
        },
        text4: {
          init: 'Prescinda de métodos de autenticación que requieran la instalación de hardware o software y ',
          bold: 'firme sus documentos directamente en la aplicación iDOK, con la firma digital GTS.'
        }
      },
      forthTitle: {
        normal: 'Conozca las funcionalidades principales de la ',
        color: 'aplicación iDOK'
      },
      list: listMobile,
      forthParagraph: {
        init: 'Aproveche las ventajas de una aplicación de Gestión de Documentos sencilla e intuitiva, ',
        bold: 'integrada con el proveedor de servicios de confianza Global Trusted Sign',
        end: ', que le permite, si dispone de una firma cualificada de GTS, firmar digitalmente los documentos desde su teléfono móvil.'
      },
      listCards: [
        { bold: 'Reciba notificaciones', normal: 'en tiempo real', alt: 'Notificaciones push de la aplicación iDOK' },
        { bold: 'Consulte', normal: 'toda la información', alt: 'Acceso a todos los documentos a través de la aplicación iDOK' },
        { bold: 'Firme digitalmente', normal: 'com a GTS', alt: 'Firma digital con GTS' },
        { bold: 'Remita', normal: 'os documentos', alt: 'Envío de documentos a través de la aplicación iDOK' },
      ],
      meta: {
        title: 'Aplicación iDOK – Gestión de Documentos con total seguridad',
        description: 'Una aplicación de Gestión de Documentos que le garantiza una total movilidad. Gestione sus documentos y fírmelos digitalmente de forma rápida, sencilla y segura.',
        keywords: 'segurança, autenticação, gestão Documental, documentos, assinatura digital, desmaterialização, transição digital'
      }
    },
    primavera: {
      meta: {
        title: 'Integración de iDOK con ERP Primavera - Automatice su Gestión de Documentos',
        description: 'La integración con Primavera ERP permite generar y recuperar datos financieros directamente desde la gestión documental. De este modo, las empresas pueden agilizar toda su gestión financiera de forma eficiente y contribuye a que los flujos de trabajo sean más eficaces. En definitiva, permite informatizar todo el proceso de solicitud, reduce el riesgo de duplicación de información y evita errores.'
      }
    },
    gestaoSistemasContent: {
      meta: {
        title: 'Gestión Eficaz de Sistemas - iDOK',
        description: 'Consiga la máxima eficacia en la Gestión de Sistemas en Portugal. Automatice procesos, promueva la desmaterialización y garantice la trazabilidad de sus documentos. ¡Obtenga más información ahora!'
      },
      imgTitles: {
        mainAnimation: 'Soluciones Avanzadas de Gestión de Sistemas para una Eficiencia Empresarial',
        gestaoEficienteImg: 'Solución Integral para una Gestión Eficiente de los Sistemas. Automatización y Desmaterialización para optimizar los Flujos de Trabajo'
      },
      tools: {
        confidencialidadeDados: 'Confidencialidad de los datos privados de los usuarios',
        confidencialidadeDadosAlt: 'Garantía de Confidencialidad de los Datos del Usuario para una Gestión de Sistemas Segura y Fiable',
        autorizacaoAcesso: 'Acceso autorizado a la plataforma y a la documentación, también para los Auditores',
        autorizacaoAcessoAlt: 'Control de Accesos: Garantía de Acceso Autorizado a la Plataforma y a la Documentación',
        limitacaoUtilizador: 'Limita el acceso de los usuarios a determinados documentos',
        limitacaoUtilizadorAlt: 'Restricción en el Acceso a los Documentos',
        registoOperacoes: 'Registra todas las operaciones, así como el agente ejecutor, mediante el control de los participantes en la Gestión de Documentos',
        registoOperacoesAlt: 'Registro de operaciones y usuarios: Garantía de Transparencia en la Gestión de Documentos'
      },
      introductionContainer: {
        mainTitleOne: 'Realice una ',
        mainTitleTwo: 'Gestión de Sistemas eficaz ',
        mainTitleThree: 'con iDOK',
        introductionTextOne: 'La eficiencia operativa de una organización, independientemente de su sector o tamaño, puede suponer todo un reto.',
        introductionTextTwo: 'Con este reto sobre la mesa, iDOK, un software atento a las necesidades del mercado, ha lanzado el ',
        introductionTextThree: 'Módulo de Gestión de Sistemas',
        introductionTextFour: ', a fin de que los usuarios tengan en sus manos el conjunto de herramientas adecuado para alcanzar sus objetivos.'
      },
      advantagesContainer: {
        mainTitleOne: '¿Qué permite la ',
        mainTitleTwo: 'nueva funcionalidad de iDOK',
        mainTitleThree: 'al respecto?'
      },
      essencialContainer: {
        title: '¿Qué hace que iDOK sea esencial para una Gestión de Sistemas eficiente?',
        mainParagraphOne: 'Aunque es esencial para el éxito en el mundo empresarial, existen pocas soluciones con capacidades suficientes para garantizar una gestión integrada que se adapte a las necesidades cotidianas de una Organización.',
        mainParagraphTwo: 'Así pues, esta nueva funcionalidad es una ventaja que optimiza los flujos de trabajo, permite automatizar tareas, se centra en la desmaterialización de la información y garantiza la trazabilidad de los documentos.',
        mainParagraphThreeFirst: 'En definitiva, iDOK con el ',
        mainParagraphThreeSecond: 'Módulo de Gestión de Sistemas',
        mainParagraphThreeThird: ', conjuntamente con la Gestión Documental, es la respuesta que está buscando en materia de Auditoría, ya que será un fuerte aliado en el cumplimiento de políticas, procesos y procedimientos, de acuerdo a responsabilidades definidas, niveles de acceso, así como el estado de actualización de la documentación.'
      },
      prioridadeContainer: {
        titleOne: 'Nuestra prioridad es garantizar',
        titleTwo: 'la gestión, integridad y seguridad de los documentos'
      },
      areasContainer: {
        titleOne: '¿En qué ámbitos puede marcar la diferencia el ',
        titleTwo: 'Módulo de Gestión de Sistemas iDOK',
        titleThree: '?',
        mainParagraphOne: 'Con esta ',
        mainParagraphTwo: 'nueva funcionalidad de iDOK',
        mainParagraphThree: ', podrá centrarse en una gestión integrada para promover una cultura organizativa de excelencia.',
        areaOne: 'Alimentos',
        areaTwo: 'Capacitación',
        areaThree: 'Medio ambiente',
        areaFour: 'Seguridad de la información',
        areaFive: 'Gestión de la calidad',
        areaSix: 'RGPD',
        areaSeven: 'Gestión de equipos',
        areaEight: 'Gestión de Recursos Humanos',
        areaNine: 'Salud y seguridad en el trabajo',
        areaTen: 'Antocorrupción',
        areaEleven: 'Servicios',
        areaTwelve: 'Gestión de reclamaciones',
      }
    },
    onlyoffice: {
      meta: {
        title: 'Edite y Cree Documentos con la integración iDOK-ONLYOFFICE',
        description: 'Edite y cree documentos en varios formatos directamente en iDOK, eliminando la necesidad de herramientas externas. Optimice la gestión de documentos con la integración iDOK-ONLYOFFICE y garantice la eficiencia y practicidad de sus equipos.'
      },
      introductionContainer: {
        mainOpening: '¿QUÉ ES ONLYOFFICE?',
        mainTitleOne: 'Edite y Cree Documentos con la ',
        mainTitleTwo: 'Integración iDOK-ONLYOFFICE',
        bannerTitle: 'ONLYOFFICE: Herramienta de Edición de Documentos en Línea integrada con iDOK',
        startInfo: '¡Trabajar con iDOK es ahora aún más fácil con esta integración!',
        mainInfo: 'ONLYOFFICE es una herramienta que permite editar documentos en línea. Ofrece una solución para crear ficheros, tales como textos, hojas de cálculo y presentaciones. Es una alternativa a Microsoft 365, con la posibilidad de crear, editar y guardar documentos en formatos compatibles con Word, Excel, PowerPoint y PDF.'
      },
      allowContainer: {
        mainTitleOne: '¿Qué permite la ',
        mainTitleTwo: 'integración con ONLYOFFICE?',
        mainText: 'Permitirá a sus equipos gestionar eficazmente la creación y edición de documentos de forma sencilla y sofisticada, lo que la convierte en una de las plataformas más completas del mercado.'
      },
      infoOnlyoffice: {
        titleImg: 'Integración iDOK-ONLYOFFICE: Creación y Edición Eficaz de Documentos',
        fileGroup: 'ONLYOFFICE: Formatos compatibles Word, Excel, PowerPoint y PDF',
        paragraphOne: 'El uso de un Sistema de Gestión de Documentos se traduce en una fuente de información sobre las actividades de su negocio, y es fundamental para la toma de decisiones. Los documentos son un recurso valioso que contienen información crítica para cualquier Entidad u Organización, siendo importante que conserven los documentos de forma organizada y válida.',
        paragraphTwo: 'El proceso de circulación de documentos internos o externos, así como el tratamiento de la información de los mismos, requiere mucho tiempo y es propenso a errores, lo que puede evitarse si las Entidades u Organizaciones optan por soluciones que les permitan gestionar sus documentos de forma automatizada.'
      },
      pros: {
        mainTitleOne: '¿Qué ',
        mainTitleTwo: 'ventajas ofrece la integración ',
        mainTitleThree: 'con ONLYOFFICE?',
        paragraphOne: 'Esta nueva solución ayudará a su entidad a ',
        paragraphTwo: 'organizar, mantener y controlar fácilmente los documentos, ',
        paragraphThree: 'permitiendo hacer un seguimiento automatizado de todo el ciclo de vida de los documentos.',
        listTitle: 'Estas son algunas de las nuevas ventajas:',
        listFirstOne: 'Crear y editar ficheros en varios formatos, ',
        listFirstTwo: 'como docx, xlsx, pptx, odt, ott, entre otros, proporcionando mayor flexibilidad a los usuarios. Esta funcionalidad estará disponible para los demás módulos de la plataforma iDOK, como Plantillas, Flujos, Procesos y Portal de Servicios.',
        listSecondOne: 'Integración de plataformas, ',
        listSecondTwo: 'sin necesidad de cambiar entre distintas herramientas o interfaces.',
        listThirdOne: 'Integración con los flujos de trabajo existentes ',
        listThirdTwo: 'en la plataforma iDOK, lo que garantiza una transición agradable y una experiencia de usuario consistente.',
        listFourth: 'Posibilidad de editar documentos Word, Excel y PowerPoint, incluso sin tener instalado Office.'
      }
    },
    selosTemporais: {
      meta: {
        title: 'Sellos de Tiempo en la Gestión de Documentos - Integración iDOK',
        description: 'Los Sellos de Tiempo certifican la fecha/hora de creación, envío o recepción de un documento o transacción electrónica y/o comercial. Por tanto, son esenciales para una Gestión de Documentos rigurosa y eficaz. Aporte autenticidad, integridad y seguridad a sus documentos enviados electrónicamente.'
      },
      tools: {
        documentoVinculado: 'Certifican la existencia de un documento vinculado a un momento específico (fecha/hora)',
        documentoVinculadoAlt: 'Garantía de la hora de creación/modificación del documento',
        documentoModificado: 'Demuestran que el documento no ha sido modificado',
        documentoModificadoAlt: 'Evita la manipulación de documentos'
      },
      introductionContainer: {
        mainTitleOne: 'Sellos de Tiempo',
        mainTitleTwo: 'en la Gestión de Documentos',
        question: '¿Qué son los Sellos de Tiempo?',
        questionAnswer: 'Los Sellos de Tiempo certifican la fecha/hora de creación, envío o recepción de un documento o una transacción electrónica y/o comercial. Por tanto, son esenciales para una Gestión de Documentos rigurosa y eficaz.'
      },
      advantagesContainer: {
        mainTitleOne: '¿Qué garantizan los',
        mainTitleTwo: 'Sellos de Tiempo',
        mainTitleThree: 'para una entidad?'
      },
      securityContainer: {
        title: '¿Cuál es la importancia de los Sellos de Tiempo en la Gestión de Documentos?',
        mainParagraphOne: 'En el ámbito de la Gestión de Documentos, es esencial poder garantizar la autenticidad, integridad y seguridad de sus documentos, ya que en la actualidad prácticamente todas las transacciones de documentos se realizan electrónicamente.',
        mainParagraphTwo: 'En este sentido, es esencial garantizar que sus documentos sigan siendo fiables a lo largo del tiempo y puedan así utilizarse como pruebas en procedimientos judiciales o administrativos. De hecho, el uso de Sellos de Tiempo impide la manipulación de documentos tramitados digitalmente.',
        mainParagraphThree: 'Al adquirir Sellos de Tiempo, está aumentando la solidez y seguridad de su Gestión de Documentos. En última instancia, también está invirtiendo en la credibilidad de sus documentos, algo esencial para auditorías, registros administrativos o procedimientos judiciales.',
        securityShieldTitle: 'Seguridad en la Gestión de Documentos'
      },
      gtsIntegrationContainer: {
        mainTitleOne: 'Sellos de Tiempo GTS',
        mainTitleTwo: 'Global Trusted Sign',
        mainParagraphOne: 'Para garantizar la mejor Gestión de Documentos del mercado para su entidad, iDOK está integrado con el Proveedor de Servicios de Confianza GTS - Global Trusted Sign, que ofrece Sellos de Tiempo Interoperables válidos y confiables.',
        mainParagraphTwo: 'Mantenga la credibilidad y seguridad de su Gestión Documental con la ayuda de la integración de iDOK con ',
        mainParagraphThree: 'Global Trusted Sign',
        mainParagraphFour: '. Consulte la lista de precios de los Sellos de Tiempo y ¡empiece ya a invertir en la autenticidad de su documentación!',
        buyNow: 'Comprar ahora',
        gtsIntegrationAlt: 'Integración del Software de Gestión de Documentos iDOK con los Sellos de Tiempo de GTS'
      }
    },
    integracaoIdok: 'INTEGRACIÓN iDOK',
    gestaoSistemasMainTitle: 'GESTIÓN DE SISTEMAS',
    whatIs: 'Qué es',
    conceitoPrimaveraUm: '',
    conceitoPrimaveraTres: 'ERP Primavera',
    conceitoPrimaveraQuatro: 'es un sistema integrado de gestión de los procesos de finanzas, contabilidad, compras, inventarios, producción, activos, marketing, recursos humanos, entre otros.',
    conceitoPrimaveraDois: 'Es un software que se enfoca en la centralización de la información, proporcionando funcionalidades que permiten optimizar los procesos internos de las organizaciones.',
    toolsTitleOne: '¿Qué permite la ',
    toolsTitleTwo: 'integración de iDOK',
    toolsTitleThree: 'con ERP Primavera?',
    sincronizacaoArtigosText: 'Sincronización de proveedores, artículos, centros de coste, actividades, entre otros.',
    sincronizacaoArtigosAlt: 'Sincronización de proveedores, artículos, costes',
    documentosFinanceirosText: 'Creación y obtención de detalles de documentos financieros',
    documentosFinanceirosAlt: 'Creación de Documentos Financieros',
    estadoEncomendasText: 'Consulta del estado de los pedidos',
    estadoEncomendasAlt: 'Consultar el estado del pedido',
    elaboracaoProcedimentosText: 'Elaboración de procedimientos de contratación pública en el módulo de contratos',
    elaboracaoProcedimentosAlt: 'Creación de procedimientos de contratación pública',
    usabilidadeTitleUm: '¿Cómo funciona ',
    usabilidadeTitleDois: 'el proceso de usabilidad para el usuario final?',
    usabilidadePassoUm: 'La solicitud se inicia en la gestión de documentos a través de un flujo adaptado al funcionamiento de su organización.',
    usabilidadePassoDois: 'Se recurre a ERP Primavera para obtener información relevante, como artículos, centros de costos, entre otros.',
    usabilidadePassoTres: 'ERP Primavera devuelve la información o los documentos financieros',
    usabilidadePassoQuatro: 'El proceso de integración ha finalizado',
    imagemPrimaveraAltTitle: 'Pantallas del Software de Gestión de Documentos iDOK y del ERP Primavera',
    integracoesVantagensTitleUm: '¿Cuáles son las',
    integracoesVantagensTitleDois: 'ventajas',
    integracoesVantagensTitleTres: 'de esta integración?',
    integracoesVantagensTextoUm: 'La integración con ERP Primavera permite generar y obtener datos financieros directamente desde la gestión de documentos. Esto permite a las empresas',
    integracoesVantagensTextoDois: 'agilizar toda la gestión financiera de forma eficiente y precisa',
    integracoesVantagensTextoTres: 'y contribuye a que los flujos de trabajo sean más eficaces. En resumen, informatiza todo el proceso de pedidos, reduce el riesgo de duplicación de información y evita errores',
    integracoesProcessoTitleUm: '¿Un',
    integracoesProcessoTitleDois: 'proceso integrado',
    integracoesProcessoTitleTres: 'simplifica la gestión de documentos?',
    integracoesProcessoTextoUm: 'La integración directa de iDOK con la solución',
    integracoesProcessoTextoDois: 'EDI ilink - Facturas Electrónicas',
    integracoesProcessoTextoTres: 'agiliza el proceso de transmisión de facturas, de forma automática, eficiente y segura.',
    integracoesProcessoTextoQuatro: 'Otra función integrada es la',
    integracoesProcessoTextoCinco: 'Firma Digital de GTS - Global Trusted Sign',
    integracoesProcessoTextoSeis: 'que hace posible firmar documentos a distancia, permitiendo más flexibilidad en su gestión de documentos.',
    duvidasTitle: '¿Tiene dudas?',
    integrationDiagramImg: 'esDiagramaIntegracao',
    integrationDiagramTitleAlt: 'Integración del Software de Gestión de Documentos iDOK con ERP Primavera'
  },
};
