import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Meta } from 'Interfaces';
import { animateScroll } from 'react-scroll';
import Lottie from 'react-lottie-player';
import Newsletter from '../../sections/newsletter/NewsletterComponent';
import Form from '../../sections/free-trail/form';
import useSetMeta from '../../../hooks/useSetMeta';
import bannerAnimation from '../../../assets/animations/banner-onlyoffice.json';
import titleImg from '../../../assets/images/title-icon-onlyoffice.svg';
import fileGroup from '../../../assets/images/grupo-ficheiros-onlyoffice.svg'
import prosCheckIcon from '../../../assets/icons/areas-check.svg';

import onlyofficePageStyles from './OnlyofficePage.styles';

function OnlyofficePage() {
  const classes = onlyofficePageStyles();
  const { t: translation } = useTranslation();

  const metaTags: Array<Meta> = useMemo(() => ([
    { name: 'title', content: translation('onlyoffice.meta.title') },
    { name: 'description', content: translation('onlyoffice.meta.description') }
  ]), [translation]);
  useSetMeta(metaTags);

  useEffect(() => {
    document.title = translation('onlyoffice.meta.title')
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  return (
    <Grid
      container
      direction='column'
      alignItems='stretch'
      className={classes.root}
    >
      <Grid item className={classes.introductionContainer}>
        <div className={classes.introductionInfoContainer}>
          <h3 className={classes.introductionIntegration}>{translation('onlyoffice.introductionContainer.mainOpening')}</h3>
          <h1>
            {translation('onlyoffice.introductionContainer.mainTitleOne')}
            <span>{translation('onlyoffice.introductionContainer.mainTitleTwo')}</span>
          </h1>
          <div className={classes.separator}></div>
          <div className={classes.introductionImgContainerSmall}>
            <Lottie
              loop={false}
              animationData={bannerAnimation}
              play={true}
              renderer="svg"
              className="banner-image"
              title={translation('onlyoffice.introductionContainer.bannerTitle')}
              style={{
                width: '100%',
                height: '100%'
              }}
            />
          </div>
          <h3 className={classes.whatIsStamps}>{translation('onlyoffice.introductionContainer.startInfo')}</h3>
          <p className={classes.whatIsStampsAnswer}>{translation('onlyoffice.introductionContainer.mainInfo')}</p>
        </div>
        <div className={classes.introductionImgContainer}>
          <Lottie
            loop={false}
            animationData={bannerAnimation}
            play={true}
            renderer="svg"
            className="banner-image"
            title={translation('onlyoffice.introductionContainer.bannerTitle')}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </div>
      </Grid>
      <Grid item className={classes.allowContainer}>
        <div className={classes.allowParagraphContainer}>
          <h2>
            <span className={classes.allowParagraphText}>
              <span className={classes.allowGreenBox}></span>
              {translation('onlyoffice.allowContainer.mainTitleOne')}
              <span className={classes.allowGreenText}>{translation('onlyoffice.allowContainer.mainTitleTwo')}</span>
            </span>
          </h2>
        </div>
        <div className={classes.allowTextContainer}>
          <p>{translation('onlyoffice.allowContainer.mainText')}</p>
        </div>
      </Grid>
      <Grid item className={classes.infoOnlyofficeContainer}>
        <div className={classes.infoOnlyofficeSubContainer}>
          <div className={classes.infoOnlyofficeTextContainer}>
            <div>
              <img src={titleImg} title={translation('onlyoffice.infoOnlyoffice.titleImg')} alt={translation('onlyoffice.infoOnlyoffice.titleImg')} />
              <p>{translation('onlyoffice.infoOnlyoffice.paragraphOne')}</p>
              <p>{translation('onlyoffice.infoOnlyoffice.paragraphTwo')}</p>
            </div>
          </div>
          <div className={classes.infoOnlyofficeImgContainer}>
            <div>
              <img src={fileGroup} title={translation('onlyoffice.infoOnlyoffice.fileGroup')} alt={translation('onlyoffice.infoOnlyoffice.fileGroup')} />
            </div>
          </div>
        </div>
        <div className={classes.infoBackgroundBoxOne}></div>
        <div className={classes.infoBackgroundBoxTwo}></div>
        <div className={classes.infoBackgroundBoxThree}></div>
      </Grid>
      <Grid item className={classes.prosContainer}>
        <div className={classes.prosFirstHalfContainer}>
          <div>
            <h2>
              {translation('onlyoffice.pros.mainTitleOne')}
              <span>{translation('onlyoffice.pros.mainTitleTwo')}</span>
              {translation('onlyoffice.pros.mainTitleThree')}
            </h2>
            <p>
              {translation('onlyoffice.pros.paragraphOne')}
              <span>{translation('onlyoffice.pros.paragraphTwo')}</span>
              {translation('onlyoffice.pros.paragraphThree')}
            </p>
          </div>
        </div>
        <div className={classes.prosSecondHalfContainer}>
          <div className={classes.prosSecondContentContainer}>
            <div>
              <p className={classes.listTitle}>{translation('onlyoffice.pros.listTitle')}</p>
            </div>
            <div>
              <ul className={classes.listContainer}>
                <li className={classes.listItemContainer}>
                  <div className={classes.listItemContent}>
                    <div className={classes.listItemImgContainer}>
                      <img src={prosCheckIcon} alt="" />
                    </div>
                    <div className={classes.listItemTextContainer}>
                      <span>{translation('onlyoffice.pros.listFirstOne')}</span>
                      {translation('onlyoffice.pros.listFirstTwo')}
                    </div>
                  </div>
                </li>
                <li className={classes.listItemContainer}>
                  <div className={classes.listItemContent}>
                    <div className={classes.listItemImgContainer}>
                      <img src={prosCheckIcon} alt="" />
                    </div>
                    <div className={classes.listItemTextContainer}>
                      <span>{translation('onlyoffice.pros.listSecondOne')}</span>
                      {translation('onlyoffice.pros.listSecondTwo')}
                    </div>
                  </div>
                </li>
                <li className={classes.listItemContainer}>
                  <div className={classes.listItemContent}>
                    <div className={classes.listItemImgContainer}>
                      <img src={prosCheckIcon} alt="" />
                    </div>
                    <div className={classes.listItemTextContainer}>
                      <span>{translation('onlyoffice.pros.listThirdOne')}</span>
                      {translation('onlyoffice.pros.listThirdTwo')}
                    </div>
                  </div>
                </li>
                <li className={classes.listItemContainer}>
                  <div className={classes.listItemContent}>
                    <div className={classes.listItemImgContainer}>
                      <img src={prosCheckIcon} alt="" />
                    </div>
                    <div className={classes.listItemTextContainer}>
                      {translation('onlyoffice.pros.listFourth')}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Grid>
      <Grid container className={classes.formContainer}>
        <div className={classes.formTitleContainer}>
          <h3 className={classes.formTitle}>
            {translation('duvidasTitle')}
          </h3>
        </div>
        <Form message="askQuestionPrimavera" supportType="6"/>
      </Grid>
      <Grid item className={classes.newsLetterContainer}>
          <Newsletter boxPosition="" />
      </Grid>
    </Grid>
  )
}

export default OnlyofficePage;
