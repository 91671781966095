import {useEffect} from 'react';
import {Meta} from 'Interfaces'

const createMetaTag = ({name, content}: Meta) => {
  const meta = document.createElement('meta');
  meta.name = name;
  meta.content = content;
  return meta;
}

const head = document.querySelector('head');

const useSetMeta = (metas: Array<Meta>) => {

  useEffect(() => {

    const prevMetaValues: Array<string> = [];
    const currentMetaTags = document.getElementsByTagName('meta');

    metas.forEach(({name, content}) => {
      const currentMeta = currentMetaTags[name as keyof HTMLCollectionOf<HTMLMetaElement>] as HTMLMetaElement;

      if(currentMeta) {
        prevMetaValues.push(currentMeta.content);
        currentMeta.content = content;
      } else {
        prevMetaValues.push('');
        head?.append(createMetaTag({name, content}));
      }
    });

    return () => {
      metas.forEach(({name}, index) => {
        const currentMeta = currentMetaTags[name as keyof HTMLCollectionOf<HTMLMetaElement>] as HTMLMetaElement;

        if(prevMetaValues[index]) {
          currentMeta.content = prevMetaValues[index];
        } else {
          currentMeta.remove();
        }
      });
    };

  }, [metas]);

}

export default useSetMeta;
