import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const baseErrorWebStyle = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "90vh",
      backgroundColor: 'white',
    },

    imageContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'center',
      maxWidth:'585.96px',
      maxHeight:'548.79px',
    },

    dataContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'center',
      paddingLeft: '80px'
    },

    title: {
      font: 'normal normal 600 48px/48px Poppins',
      width: '100%',
      marginBottom: '13px',
      letterSpacing: '0px',
      color: '#1d1d1d',
    },

    subTitle: {
      font: 'normal normal 600 24px/24px Poppins',
      color: '#1d1d1d',
      width: '80%',
      marginBottom: '13px',
    },

    description: {
      font: 'normal normal 300 18px/22px Lato',
      color: '#1d1d1d',
      opacity: 1,
      width: '100%',
    },

    buttons: {
      marginTop: '25px',
    },
  })
);

export default baseErrorWebStyle;
