import React, {
  useContext
} from 'react';
import { Arrow } from '../../../components/icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import AnimatedImage from '../../animation/AnimatedImage';
import freeTrialStyle from './FreeTrial.styles';
import Form from './form';
import { TrialFormContext } from '../../../context/TrialFormContext';

function FreeTrial() {
  const classes = freeTrialStyle();
  const {showForm, onToggleForm} = useContext(TrialFormContext);
  const theme = useTheme();
  const mediaMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const { t: translation } = useTranslation();

  return (
    <Grid container direction="row" spacing={0} className={classes.container} id="demo">
      <Grid
        item
        md={12}
        lg={6}
        className={clsx(classes.columnLeft, {
          [classes.desapearColumnLeft]: mediaMdDown && showForm,
        })}
      >
        <Typography variant="body1" className={classes.contentTitle}>
          <span className={classes.title}>{translation('normalTitleFreeTrial')}{' '}</span>
          <span className={classes.titleBlue}>{translation('highlightTitleFreeTrial')}</span>
        </Typography>
        <Typography variant="body1" className={classes.content}>
          {translation('introFreeTrial')}
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {translation('contactForFreeTrial')}
        </Typography>
        {!showForm && (
          <Button
            onClick={onToggleForm}
            variant="contained"
            color="primary"
            className={classes.buttonHeight}
          >
            {translation('requestTest')}
          </Button>
        )}
        <AnimatedImage
          name="section_free_trial"
          options={{ loop: false }}
          divProps={{
            className: clsx(classes.animation, {
              [classes.animationActive]: showForm,
            }),
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        className={clsx(classes.columnRigth, {
          [classes.animecolumnRigth]: showForm,
        })}
      >
        <Hidden lgUp>
          <Arrow className={classes.arrowIcon} onClick={onToggleForm} />
        </Hidden>
        {showForm && <Form message="makeRegisterFreeTrial" supportType="1"/>}
      </Grid>
    </Grid>
  );
}

export default FreeTrial;
