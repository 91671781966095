import React, {
  FC
} from 'react';
import {
  SelectSearch
} from '@acin.reactjs/form-components';
import {
  useRouteMatch,
  useHistory
} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Search } from '../../../../../components/icons';
import { ThemeProvider } from '@material-ui/core';
import Theme from './SelectSearchComponent.styles';

interface SelectSearchProps{
  selectList: {
    id: string,
    value: string
  }[]
};

const SelectSearchComponent: FC<SelectSearchProps> = ({
  selectList
}) => {
  const history = useHistory();
  const {path} = useRouteMatch();
  const theme = Theme;
  const { t } = useTranslation();

  const handleOnChange = (param) => {
    if (param) {
      const url = param.id;
      history.push(`${path}/${url}`);
      return;
    };

    history.push(`${path}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <SelectSearch
        name="selectSearch"
        dataResolver={selectList}
        configuration={{ strLenForSearch: 0 }}
        disableUnderline
        shrink
        placeholder={t('searchQuestion')}
        endAdornments={() => <Search />}
        onChangeSelect={handleOnChange}
      />
    </ThemeProvider>
  )
};

export default SelectSearchComponent;
