import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Carousel from 'react-material-ui-carousel';
import Feature from './components/feature';
import Card from './components/feature/CardTabletLayout';
import Expanded from './components/feature/Expanded';
import featuresStyles from './FeaturesComponent.styles';
import LeftArrow from '../../../assets/images/LeftArrow';
import RightArrow from '../../../assets/images/RightArrow';
import { features as featuresArray } from './utils';

function Features() {
  const { t: translation } = useTranslation();
  const theme = useTheme();
  const mediaMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const mobileLayout = useMediaQuery(theme.breakpoints.down(680));
  const classes = featuresStyles();
  const features = useMemo(() => featuresArray(classes, translation), [classes, translation]);
  const featureTablet = useMemo(() => ([
    [
      features[0],
      features[1]
    ],
    [
      features[2],
      features[3]
    ],
    [
      features[4],
      features[5]
    ],
    [
      features[6],
      features[7]
    ],
    [
      features[8]
    ]
  ]), [features]);

  return (
    <Grid className={classes.container} id="features">
      <div className={classes.titleContent}>
        <Typography variant="body2" className={classes.title} >
          <div className={classes.greenBox} />
          {translation('normalTitleFeatures')} <span className={classes.greenLetter}>{translation('highlightTitleFeatures')}</span>
        </Typography>
      </div>

      {mediaMdDown ? (
        <Grid item xs={12}>
          <Carousel
            className={classes.carousel}
            autoPlay={false}
            animation='slide'
            indicators={false}
            NextIcon={<RightArrow />}
            PrevIcon={<LeftArrow />}
            navButtonsAlwaysVisible
            fullHeightHover
            navButtonsProps={{
              className:'navBtn',
              style: {
                backgroundColor:'inherit',
                height: '80px',
                margin: '0 10px'
              }
            }}
            navButtonsWrapperProps={{
              className:'navBtnWrapper',
              style: {
                height: '250px',
              }
            }}
          >
            {
              mobileLayout ?
                features.map(feature => (
                  <Feature {...feature} key={feature.title} />
                ))
              :
                featureTablet.map( feature => {
                  feature[0].classNameRoot = classes.firstCard;

                  return (
                    <div className={classes.carouselItemContent} key={feature[0].title}>
                      <Grid item md={12} className={classes.cardsContent}>
                        <Grid item sm={6}>
                          <Card {...feature[0]} />
                        </Grid>
                        {feature[1] &&
                          <Grid item sm={6}>
                            <Card {...feature[1]} />
                          </Grid>
                        }
                      </Grid>
                      <Grid item md={12} className={classes.expandedContent}>
                        <Grid item sm={6}>
                          <Expanded {...feature[0]} />
                        </Grid>
                        {feature[1] &&
                          <Grid item sm={6}>
                            <Expanded {...feature[1]} />
                          </Grid>
                        }
                      </Grid>
                    </div>
                  )
                })
            }
          </Carousel>
        </Grid>
      ) : (
        <Grid container>
          {features.map(feature => (
            <Grid item md={6} lg={4} key={feature.title}>
              <Feature {...feature} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
}

export default Features;
