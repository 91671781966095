import React, {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Select } from '../../../../components/select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import { useAlerts } from "@acin.reactjs/alert";
import { scroller } from 'react-scroll';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as yup from 'yup';
import clsx from 'clsx';
import Options from './CountryOption';
import formStyle from './form.styles';
import { getRequestAPI } from '../../../requestComponent/getRequest';
import { postRequest } from '../../../requestComponent/postRequest';
import AnimatedImage from '../../../animation/AnimatedImage';
import { TrialFormContext } from '../../../../context/TrialFormContext';

type Inputs = {
  email?: string;
  name?: string;
  password?: string;
  phone?: string;
  country?: string;
  terms?: boolean;
  termsPolicy?: boolean;
  entityType?: string;
  workers?: string;
};

function Form(props) {
  const localStorageIdokUrl = window.localStorage.getItem('idokUrl');
  const baseUrl = localStorageIdokUrl || "";
  const emptyOption = [{ id: '', name: '' }];
  const classes = formStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const { error } = useAlerts();
  const tabletLayout = useMediaQuery(theme.breakpoints.down('md'));
  const [countryList, setCountryList] = useState(emptyOption);
  const [workersList, setWorkersList] = useState(emptyOption);
  const [entityList, setEntityList] = useState(emptyOption);
  const [formSubmited, setFormSubmited] = useState(false);
  const { leadsId } = useContext(TrialFormContext);
  const recaptcha = useRef();
  const hasReCaptchaToken = Boolean(import.meta?.env?.VITE_CAPTCHA_KEY);

  // Validation Messages
  const validationFreeTrialForm = yup.object().shape({
    name: yup.string().required(''),
    email: yup.string().email(t('validEmail')).required(''),
    phone: yup.number().typeError(''),
    country: yup.string().required(''),
    entityType: yup.string().required(''),
    workers: yup.string().required(''),
    terms: yup.bool().oneOf([true], t('termsRequired')),
    termsPolicy: yup.bool().oneOf([true], t('policyRequired'))
  });

  const { register, handleSubmit, errors, getValues } = useForm<Inputs>({
    resolver: yupResolver(validationFreeTrialForm),
  });

  useEffect(() => {
    const source = '10';
    const errorRequest = error => {
      console.log(error);
    };

    // Country list
    getRequestAPI('v1/type/countries?limit=300', {
      source,
      successRequest: (response) => {
        const countries = response.data.data;
        setCountryList([
          ...countryList,
          ...countries
        ]);
      },
      errorRequest,
    });

    // Worker list
    getRequestAPI('v1/notification/customerSupport/quantityCollaboratorNumbers', {
      source,
      successRequest: (response) => {
        const workers = response.data.data;
        setWorkersList([
          ...workersList,
          ...workers
        ]);
      },
      errorRequest,
    });

    // Entity List
    getRequestAPI('v2/notification/customerSupport/entityTypes', {
      source,
      successRequest: (response) => {
        const newEntityList: [{id: string, name: string}] = response.data.data;

        setEntityList([
          ...entityList,
          ...newEntityList
        ]);
      },
      errorRequest,
    });
    // eslint-disable-next-line
  }, []);

  const onSubmitted = data => {
    const newData = {
      email: data.email,
      countryId: data.country,
      customerSupportTypeId: props.supportType,
      name: data.name,
      phone: data.phone,
      acceptanceRules: data.terms,
      acceptancePolicy: data.termsPolicy,
      quantityCollaboratorId: data.workers,
      entityTypeId: data.entityType,
    };

    const recaptchaValue = recaptcha?.current?.getValue();
    const isValidCaptcha = (hasReCaptchaToken && recaptchaValue) || !hasReCaptchaToken;

    if(!isValidCaptcha){
      error(
        t('O reCAPTCHA não foi completo'),
        { title: t('Erro') + '!' }
      );
      return; 
    }

    const successRequest = response => {
      if (!response.data.status || (!isValidCaptcha)) {
        error(
          t('Não foi possível processar o seu pedido'),
          { title: t('Erro') + '!' }
        );
        return;
      };
      setFormSubmited(true);
      scroller.scrollTo('demo', { smooth: true })
    };
    const errorRequest = error => {
      console.log(error);
    };

    postRequest('v1/notification/customerSupport', {
      postData: newData,
      source: '10',
      successRequest,
      errorRequest,
    });
  };

  const onSendLeadData = () => {
    const { name, email, phone } = getValues();

    postRequest('v1/leads', {
      postData: {
        hash: leadsId,
        name,
        email,
        phone
      },
      source: '10',
    });
  };

  const fromHomePage = props.supportType === '1';

  let afterSubmitMessage = '';

  switch (props.supportType) {
    case '3':
      afterSubmitMessage = 'thanksFormPrimavera';
      break;
    case '4':
      afterSubmitMessage = 'thanksFormSelosTemporais';
      break;
    default:
      afterSubmitMessage = 'thanksForm';
      break;
  }

  return (
    <>
      <form
        noValidate
        className={clsx(classes.form, {
          [classes.formApllied]: formSubmited,
          [classes.formHomeLayout]: fromHomePage && formSubmited
        })}
        onSubmit={handleSubmit(onSubmitted)}
        autoComplete="off"
      >
        {formSubmited ? (
          <Typography variant="body1" className={clsx(classes.titleSucess, {
            [classes.titleHomeLayout]: fromHomePage
          })}>
            <p className={classes.thanks}> {t(afterSubmitMessage)}</p>
            <p className={classes.contactSoon}> {t('soonForm')}</p>
          </Typography>
        ) : (
          <div className={classes.inputContent}>
            <Typography variant="body1" className={classes.title}>
              {t(props.message)}
            </Typography>
            <Grid container className="form-container-trial" spacing={3}>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  inputRef={register}
                  helperText={errors.name ? errors.name.message : ''}
                  error={!!errors.name}
                  size="small"
                  name="name"
                  label={t('entity')}
                  InputLabelProps={{
                    style: { paddingTop: '2px' },
                  }}
                  variant="outlined"
                  fullWidth
                  onBlur={onSendLeadData}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  inputRef={register}
                  helperText={errors.email ? errors.email.message : ''}
                  error={!!errors.email}
                  size="small"
                  name="email"
                  label={t('email')}
                  InputLabelProps={{
                    style: { paddingTop: '2px' },
                  }}
                  variant="outlined"
                  fullWidth
                  onBlur={onSendLeadData}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  inputRef={register}
                  helperText={errors.phone ? errors.phone.message : ''}
                  error={!!errors.phone}
                  size="small"
                  name="phone"
                  label={t('phone')}
                  InputLabelProps={{
                    style: { paddingTop: '2px' },
                  }}
                  variant="outlined"
                  fullWidth
                  onBlur={onSendLeadData}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  name="country"
                  label={t('country')}
                  inputRef={register}
                  helperText={errors.country ? errors.country.message : ''}
                  error={!!errors.country}
                >
                  <Options
                    options={countryList}
                  />
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  required
                  name="workers"
                  label={t('workers')}
                  inputRef={register}
                  helperText={errors.workers ? errors.workers.message : ''}
                  error={!!errors.workers}
                >
                  <Options
                    options={workersList}
                  />
                </Select>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  required
                  name="entityType"
                  label={t('entityType')}
                  inputRef={register}
                  helperText={errors.entityType ? errors.entityType.message : ''}
                  error={!!errors.entityType}
                >
                  <Options
                    options={entityList}
                  />
                </Select>
              </Grid>
            </Grid>
            <div className={classes.smallText}>
              <span className={classes.asterisk}>* </span>
              {t('requiredFields')}
            </div>
            <FormControl name="terms" error={!!errors.terms} component="fieldset">
              <FormControlLabel
                control={<Checkbox name="terms" inputRef={register} />}
                label={
                  <Typography className={classes.label}>
                    {t('readTerms')}
                    <a href={`${baseUrl}/cgadesao/${t('codicoesGeraisPdf')}`} target="_blank" rel="noopener noreferrer">
                      <span>
                        {t('conditionsTerms')}
                      </span>.
                    </a>
                  </Typography>
                }
              />
              <FormHelperText>{errors.terms ? t('termsRequired') : ''}</FormHelperText>
            </FormControl>

            <FormControl name="termsPolicy" error={!!errors.termsPolicy} component="fieldset">
              <FormControlLabel
                control={<Checkbox name="termsPolicy" inputRef={register} />}
                label={
                  <Typography className={classes.label}>
                    {t('registerTerms')}
                    <a href={`${t('policyLink')}`} target="_blank" rel="noopener noreferrer">
                      <span>
                        {t('privacyPolicy')}
                      </span>.
                    </a>
                  </Typography>
                }
              />
              <FormHelperText>{errors.termsPolicy ? t('policyRequired') : ''}</FormHelperText>
            </FormControl>
            <div className={hasReCaptchaToken ? classes.buttonSubmitContainerWithCaptcha : classes.buttonSubmitContainer}>
              <Button className={classes.submitButton} variant="contained" color="primary" type="submit">
                {t('confirmTest')}
              </Button>
              {hasReCaptchaToken &&
                <div>
                  <ReCAPTCHA ref={recaptcha} sitekey={import.meta.env.VITE_CAPTCHA_KEY} />
                </div>
              }
            </div>
          </div>
        )}
      </form>
      {formSubmited &&
        tabletLayout &&
        (
          <div className={classes.imageContent}>
            <AnimatedImage
              name="section_free_trial"
              options={{ loop: false }}
              divProps={{
                className: classes.animation,
              }}
            />
          </div>

        )
      }
    </>
  );
}

export default Form;
