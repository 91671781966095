import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as Play } from '../../assets/icons/play-button.svg';
import { ReactComponent as Plus } from '../../assets/icons/icons_idok_plus.svg';
import { ReactComponent as Minus } from '../../assets/icons/icons_idok_minus.svg';
import { ReactComponent as Linkedin } from '../../assets/icons/social/linkedin.svg';
import { ReactComponent as Youtube } from '../../assets/icons/social/youtube.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as Open } from '../../assets/icons/open.svg';
import { ReactComponent as Close } from '../../assets/icons/icon-close.svg';
import { ReactComponent as Hamburger } from '../../assets/icons/hamburger_menu.svg';
import { ReactComponent as SelectArrow } from '../../assets/icons/icon_select.svg';
export { ReactComponent as BackArrow } from '../../assets/icons/back.svg';
export { ReactComponent as NextArrow } from '../../assets/icons/next.svg';
export { ReactComponent as Safety } from '../../assets/icons/features/safety.svg';
export { ReactComponent as DigitalSignature } from '../../assets/icons/features/digital-signature.svg';
export { ReactComponent as Solution } from '../../assets/icons/features/custom-solution.svg';
export { ReactComponent as Automation } from '../../assets/icons/features/automatizacao.svg';
export { ReactComponent as GestaoSistemas } from '../../assets/icons/features/custom-gestao-sistemas.svg';
export { ReactComponent as PortalServicos } from '../../assets/icons/features/custom-portal-servicos.svg';
export { ReactComponent as Document } from '../../assets/icons/features/doc.svg';
export { ReactComponent as Processes } from '../../assets/icons/features/processes.svg';
export { ReactComponent as Workflow } from '../../assets/icons/features/workflow.svg';
export { ReactComponent as Search } from '../../assets/icons/faqs/search.svg';

export function PlayIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Play} />
}
export function PlusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Plus} />
}
export function MinusIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Minus} />
}
export function Arrow(props: SvgIconProps) {
  return <SvgIcon {...props} component={ArrowIcon} />
}
export function OpenIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Open} />
}
export function LinkedinIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Linkedin} />
}
export function YoutubeIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Youtube} />
}
export function HamburgerIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Hamburger} />
}
export function CloseIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={Close} />
}
export function SelectArrowIcon(props: SvgIconProps) {
  return <SvgIcon {...props} component={SelectArrow} />
}
