import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const BoxesStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'row',
      width: '100%',
      height: '140px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 3px 6px #00000029',
      opacity: 1,
      margin: '12px'
    },
    image:{
      marginBottom: '25px',
      marginTop: '25px',
    },
    textContent: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    }
  })
);

export default BoxesStyle;