import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from '@material-ui/core/Slider';

const ProgressBarStyles = makeStyles((theme) => {
  return {
    container: {
      left: '32.5%',
      width: '35%',
      bottom: '3%',
      height: '35px',
      padding: '10px',
      position: 'absolute',

      [theme.breakpoints.down('md')]: {
        left: '25%',
        width: '50%',
      },

      [theme.breakpoints.down('xs')]: {
        left: '12.5%',
        width: '75%',
      }
    },
    progressBar: {
      border: '1px solid #39D155',
      height: '100%',
      padding: '3px',
      boxSizing: 'border-box',
      borderRadius: '50px',

      display: 'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems: 'center',
    },

    progressBarContent: {
      width: '100%',
      cursor: 'pointer',
      height: '35px',
      padding: '10px',
      position: 'absolute',
      boxShadow: '1px 1px 3px #00000029',
      boxSizing: 'border-box',
      borderRadius: '50px',
      backgroundColor: '#fff',
    },
  };
});

export const StyledSlider = withStyles({
  root: {
    color: '#39D155',
    padding: 0,
    height: '7px',
  },
  rail: {
    height: 0,
  },
  track: {
    height: '7px',
    borderRadius: '50px',
  },
  thumb: {
    height: 0,
    marginTop: '3.5px',
    marginLeft: 0,
    width: 0,
    
    '&:hover': {
      boxShadow: 'unset',
    }
  }
})(Slider);

export default ProgressBarStyles;