import React, {
  FC,
  Fragment
} from 'react';
import freeTrialStyle from '../FreeTrial.styles';

interface OptionProps {
  options: {
    id: string,
    name: string
  }[]
};

const CountryOption:FC<OptionProps> = ({
  options
}) => {
  const classes = freeTrialStyle();
  return (
    <>
      {options.map( opt =>
        <option className={classes.options} value={opt.id} key={opt.id}>{opt.name}</option>
      )}
    </>
  )
};

export default CountryOption
