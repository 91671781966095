import { makeStyles, Theme } from "@material-ui/core";

const gestaoSistemasPageStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  },
  introductionContainer: {
    backgroundColor: '#e0fcd6',
    minHeight: 'calc(100vh - 80px)',
    height: '100%',
    display: 'flex',
    padding: '0 7%',
    paddingBottom: 60
  },
  introductionInfoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      font: 'normal normal 600 48px/56px Poppins',
      margin: 0,
      color: '#1d1d1d',
      '& span': {
        color: '#1aa732'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      paddingTop: 100
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: 55
    }
  },
  introductionIntegration: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#0a642d',
    margin: 0
  },
  separator: {
    width: '150px',
    height: '12px',
    backgroundColor: '#1aa732',
    margin: '20px 0 50px',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  introductionImgContainerSmall: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .banner-image': {
        maxHeight: 324
      },
      marginBottom: 30
    }
  },
  introductionInfoText: {
    margin: 0,
    font: 'normal normal 300 20px/24px Lato',
    color: '#1d1d1d',
    '& span': {
      fontWeight: 500
    }
  },
  introductionImgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    '& .banner-image': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 474,
        maxHeight: 444
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 532,
        maxHeight: 498
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  advantagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '110px 7% 130px',
    gap: 85,
    [theme.breakpoints.down('md')]: {
      paddingTop: 70,
      paddingBottom: 80
    }
  },
  advantagesParagraphContainer: {
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    },
    '& h2': {
      margin: 0,
      font: 'normal normal 600 48px/56px Poppins',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    }
  },
  advantagesParagraphText: {
    position: 'relative',
    zIndex: 1
  },
  paragraphBlueBox: {
    width: 52,
    height: 52,
    backgroundColor: '#e2fdff',
    position: 'absolute',
    zIndex: -1,
    top: '-13px',
    left: '-34px',
    [theme.breakpoints.down('md')]: {
      top: '-19px'
    }
  },
  paragraphBlueText: {
    color: '#00ACFF'
  },
  toolCardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down(890)]: {
      flexDirection: 'column'
    }
  },
  toolCardContent: {
    maxWidth: 398,
    width: '100%',
    boxShadow: '0 0 5px #00000029',
    borderRadius: 26,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      flex: 1,
      minHeight: 70
    }
  },
  toolCardContentImgContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  toolCardContentTextContainer: {
    font: 'normal normal 300 18px/22px Lato',
    color: '#1d1d1d',
    '& p': {
      margin: 0
    }
  },
  securityContainer: {
    position: 'relative',
    backgroundColor: '#263238'
  },
  infoSecurityContainer: {
    display: 'flex',
    padding: '0 7%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  securityTextContainer: {
    flex: 1
  },
  securityInfoTextContainer: {
    height: '100%',
    padding: '85px 7% 160px 0',
    '& h2': {
      font: 'normal normal 600 48px/60px Poppins',
      color: '#ffffff',
      margin: 0,
      marginBottom: 45,
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins',
        marginBottom: 30
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    '& p': {
      font: 'normal normal 300 20px/24px Lato',
      color: '#ffffff',
      margin: 0,
      '& span': {
        fontWeight: 500,
      },
      '&:not(first-child)': {
        marginTop: 20,
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 300 20px/24px Lato',
        margin: 0
      },
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 300 18px/22px Lato',
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0
    }
  },
  securityImgContainer: {
    flex: 1,
    '& div': {
      height: '100%',
      paddingLeft: '7%',
      position: 'relative',
      '& img': {
        zIndex: 1,
        position: 'absolute',
        top: '37%',
        left: '50%',
        transform: 'translateX(-50%)',
        [theme.breakpoints.down('md')]: {
          position: 'relative',
          top: '90px',
          width: '100%',
        }
      },
      [theme.breakpoints.down('md')]: {
        maxWidth: '600px',
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  securityBackgroundBoxOne: {
    position: 'absolute',
    height: 224,
    width: 224,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 0,
    [theme.breakpoints.down('md')]: {
      height: 94,
      width: 94,
      top: 74
    }
  },
  securityBackgroundBoxTwo: {
    position: 'absolute',
    height: 177,
    width: 177,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 0,
    right: 224,
    [theme.breakpoints.down('md')]: {
      width: 74,
      height: 74,
      right: 94
    }
  },
  securityBackgroundBoxThree: {
    position: 'absolute',
    height: 88,
    width: 88,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 401,
    [theme.breakpoints.down('md')]: {
      width: 37,
      height: 37,
      top: 74,
      right: 168
    }
  },
  formContainer: {
    backgroundColor: '#E2FDFF',
    justifyContent: 'center',
    paddingTop: '165px',
    paddingLeft: '7%',
    paddingRight: '7%',
    paddingBottom: '230px',
    flexDirection: 'column',
    alignItems: 'center',
    '& form': {
      padding: 0,
      paddingTop: 40,
      height: 'auto',
      minHeight: 'unset',
      maxWidth: '812px',
      boxShadow: 'none',
      '& .form-container-trial': {
        backgroundColor: '#e2fdff'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 100,
      marginBottom: 60
    }
  },
  formTitleContainer: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start'
    }
  },
  formTitle: {
    font: 'normal normal 600 40px/56px Poppins',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 600 38px/50px Poppins'
    },
    [theme.breakpoints.down('xs')]: {
      font: 'normal normal 600 30px/35px Poppins',
    }
  },
  newsLetterContainer: {
    '& .container-subscription': {
      [theme.breakpoints.up('lg')]: {
        marginTop: '-100px'
      }
    }
  },
  prioridadeContainer: {
    padding: '180px 7% 200px',
    '& h3': {
      margin: 0,
      textAlign: 'center',
      font: 'normal normal 600 48px/60px Poppins',
      color: '#1d1d1d',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 100,
      paddingBottom: 100
    }
  },
  paragraphGreenBox: {
    width: 52,
    height: 52,
    backgroundColor: '#e0fcd6',
    position: 'absolute',
    zIndex: -1,
    top: '-13px',
    left: '-34px',
    [theme.breakpoints.down('md')]: {
      top: '-19px'
    }
  },
  paragraphGreenText: {
    color: '#1aa732'
  },
  areasContainer: {
    position: 'relative'
  },
  areasContainerFistHalf: {
    '& h3': {
      margin: 0,
      font: 'normal normal 600 48px/60px Poppins',
      color: '#1d1d1d',
      position: 'relative',
      zIndex: 1,
      '& span': {
        color: '#1aa732'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 38px/50px Poppins'
      },
      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    '& p': {
      margin: 0,
      marginTop: 24,
      font: 'normal normal 300 20px/24px Lato',
      color: '#1d1d1d',
      position: 'relative',
      '& span': {
        fontWeight: 500
      }
    },
    padding: '135px 7%',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 120,
      paddingTop: 160,
      background: '#e0fcd6'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 120,
      paddingBottom: 100
    }
  },
  areasInfoTextContainer: {
    width: '50%',
    paddingRight: 146,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: 0
    }
  },
  threeBoxContainer: {
    position: 'absolute',
    left: '50%',
    top: 200,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  areasContainerSecondHalf: {
    background: '#e0fcd6',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  areasListContainer: {
    position: 'relative',
    listStyleType: 'none',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    margin: 0,
    padding: 0,
    rowGap: 16,
    columnGap: 16,
    '& li': {
      display: 'flex',
      gap: 16
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  areasCheckIconContainer: {
    '& img': {
      marginTop: 4
    }
  },
  areasItemTextContainer: {
    flexBasis: '100%',
    font: 'normal normal 300 20px/24px Lato',
    display: 'flex',
    margin: 0
  },
  firstList: {
    marginTop: 80
  },
  secondList: {

  }
}));

export default gestaoSistemasPageStyles;