import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const baseErrorStyle = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      margin: '15px 0 15px 0',
      font: 'normal normal bold 18px/22px Lato',
      letterSpacing: '0px',
      color: '#1d1d1d',
    },

    backButton: {
      width:'90px',
      height: '32px',
      backgroundColor: "#1d1d1d",
      borderRadius: '4px',
      opacity: 1,
      color: '#fff',
      textTransform: 'capitalize',
      padding: '6px 18px',

      "& span": {
        font: 'normal normal 600 14px/25px "Source Sans 3", Helvetica, Arial, sans-serif',
      },

      "&:hover": {
        opacity: 0.8,
        backgroundColor: "#1d1d1d",
      },
    },
  })
);

export default baseErrorStyle;
