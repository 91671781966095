import React, { FC, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Lottie from 'react-lottie-player';
import { BaseErrorMobileProps } from '../../../Interfaces';
import BaseErrorMobileStyle from './BaseErrorMobile.styles';

const BaseErrorMobile:FC<BaseErrorMobileProps> = ({
  dataOptions,
  messageComponent,
  buttons,
  finalOptions
}) => {
  const classes = BaseErrorMobileStyle();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.dataContent}>
        <div>
          <div className={classes.title}>{dataOptions.title}</div>
          <div className={classes.subTitle}>{dataOptions.subTitle}</div>
          <div className={classes.description}>{dataOptions.description}</div>
          {messageComponent}
          <div className={classes.buttons} >
              {buttons}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.imageContent}>
        <Lottie
          loop={false}
          animationData={finalOptions.animationData}
          play={true}
          renderer="svg"
          className='image'
        />
      </Grid>
    </Grid>
  );
}

export default BaseErrorMobile
