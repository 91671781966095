import { constants } from '../../../global-constants';

type Layout = 'web'|'tablet'|'mobile';
type CardFunction = (t, typeLayout: Layout) => void;

export const cardPlatforms:CardFunction = (t, typeLayout) => {
  const { platforms } = constants;
  const platformsArray = [
    {
      title: t('acinGroup.platforms.igest.title'),
      description: t('acinGroup.platforms.igest.desc'),
      url: t('acinGroup.platforms.igest.url'),
      urlToRedirect: platforms.igest,
      color: '#158BCC'
    },
    {
      title: t('acinGroup.platforms.taxi.title'),
      description: t('acinGroup.platforms.taxi.desc'),
      url: t('acinGroup.platforms.taxi.url'),
      urlToRedirect: platforms.omeutaxi,
      color: '#00E09B'
    },
    {
      title: t('acinGroup.platforms.ifreg.title'),
      description: t('acinGroup.platforms.ifreg.desc'),
      url: t('acinGroup.platforms.ifreg.url'),
      urlToRedirect: platforms.ifreg,
      color: '#CC6E10'
    },
    {
      title: t('acinGroup.platforms.paypay.title'),
      description: t('acinGroup.platforms.paypay.desc'),
      url: t('acinGroup.platforms.paypay.url'),
      urlToRedirect: platforms.paypay,
      color: '#2B4D89'
    },
    {
      title: t('acinGroup.platforms.gts.title'),
      description: t('acinGroup.platforms.gts.desc'),
      url: t('acinGroup.platforms.gts.url'),
      urlToRedirect: platforms.gts,
      color: '#076964'
    },
    {
      title: t('acinGroup.platforms.acingov.title'),
      description: t('acinGroup.platforms.acingov.desc'),
      url: t('acinGroup.platforms.acingov.url'),
      urlToRedirect: platforms.acingov,
      color: '#CE131A'
    },
    {
      title: t('acinGroup.platforms.imed.title'),
      description: t('acinGroup.platforms.imed.desc'),
      url: t('acinGroup.platforms.imed.url'),
      urlToRedirect: platforms.imed,
      color: '#ED8666'
    },
    {
      title: t('acinGroup.platforms.iParque.title'),
      description: t('acinGroup.platforms.iParque.desc'),
      url: t('acinGroup.platforms.iParque.url'),
      urlToRedirect: platforms.iparque,
      color: '#1D509C'
    },
    {
      title: t('acinGroup.platforms.irh.title'),
      description: t('acinGroup.platforms.irh.desc'),
      url: t('acinGroup.platforms.irh.url'),
      urlToRedirect: platforms.irh,
      color: '#269784'
    },
    {
      title: t('acinGroup.platforms.cestado.title'),
      description: t('acinGroup.platforms.cestado.desc'),
      url: t('acinGroup.platforms.cestado.url'),
      urlToRedirect: platforms.comprasDoEstado,
      color: '#E2CC00'
    },
    {
      title: t('acinGroup.platforms.ilink.title'),
      description: t('acinGroup.platforms.ilink.desc'),
      url: t('acinGroup.platforms.ilink.url'),
      urlToRedirect: platforms.ilink,
      color: '#5951FD'
    },
    {
      title: t('acinGroup.platforms.agromarket.title'),
      description: t('acinGroup.platforms.agromarket.desc'),
      url: t('acinGroup.platforms.agromarket.url'),
      urlToRedirect: platforms.agroMarket,
      color: '#4b7d30'
    },
    {
      title: t('acinGroup.platforms.denuncias.title'),
      description: t('acinGroup.platforms.denuncias.desc'),
      url: t('acinGroup.platforms.denuncias.url'),
      urlToRedirect: platforms.denuncias,
      color: '#028ef2'
    }
  ]

  if (typeLayout === 'web') {
    return {
      cards: [
        [
          platformsArray[0],
          platformsArray[1],
          platformsArray[2],
          platformsArray[3],
          platformsArray[4],
          platformsArray[5],
        ],
        [
          platformsArray[6],
          platformsArray[7],
          platformsArray[8],
          platformsArray[9],
          platformsArray[10],
          platformsArray[11]
        ],
        [
          platformsArray[12]
        ]
      ],
      length: 6
    }
  } if(typeLayout === 'tablet') {
    return {
      cards: [
        [
          platformsArray[0],
          platformsArray[1],
          platformsArray[2],
        ],
        [
          platformsArray[3],
          platformsArray[4],
          platformsArray[5],
        ],
        [
          platformsArray[6],
          platformsArray[7],
          platformsArray[8],
        ],
        [
          platformsArray[9],
          platformsArray[10],
          platformsArray[11]
        ],
        [
          platformsArray[12]
        ]
      ],
      length: 3
    }
  } if(typeLayout === 'mobile') {
    return {
      cards: [
        [
          platformsArray[0],
          platformsArray[1],
        ],
        [
          platformsArray[2],
          platformsArray[3],
        ],
        [
          platformsArray[4],
          platformsArray[5],
        ],
        [
          platformsArray[6],
          platformsArray[7],
        ],
        [
          platformsArray[8],
          platformsArray[9],
        ],
        [
          platformsArray[10],
          platformsArray[11],
        ],
        [
          platformsArray[12]
        ]
      ],
      length: 2
    }
  }
};