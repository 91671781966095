import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const acinGroupMobileStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dataContent: {
      paddingTop: '120px',
      paddingLeft: '7%',
      maxWidth:'605px',

      [theme.breakpoints.down('md')]: {
        paddingTop: '100px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 'unset',
        paddingTop: '70px',
      },
    },
    isoLink:{
      cursor: 'pointer'
    },
    greenBox: {
      backgroundColor: '#E0FCD6',
      width: '52.46px',
      height: '52.46px',

      [theme.breakpoints.down('xs')]: {
        marginLeft: '10px'
      },
    },
    title: {
      marginTop: '-40px',
      marginLeft: '25px',

      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        paddingLeft: '7%',
        paddingRight: '6%',
      },
    },
    titleText:{
      font: 'normal normal 600 48px/50px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',

      [theme.breakpoints.down('sm')]: {
        font: 'normal normal 600 36px/50px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/36px Poppins',
      },

    },
    textContent:{
      paddingLeft: '25px',
      paddingTop: '45px',

      [theme.breakpoints.down('xs')]: {
        paddingTop: '21px',
        paddingLeft: '7%',
        paddingRight: '6%',
      },
    },
    isoTitle:{
      font: 'normal normal 600 20px/24px Lato',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },

    isoContent: {
      paddingTop:'25px',
    },
    greenLetter:{
      color: '#1AA732',
    },
    description:{
      font: 'normal normal 300 20px/24px "Source Sans 3", Helvetica, Arial, sans-serif',
      letterSpacing: '0px',
      color: '#1D1D1B',

      '& a': {
        color: 'inherit',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 300 18px/24px "Source Sans 3", Helvetica, Arial, sans-serif',
      },
    },
    isoImagesContent: {
      paddingTop:'75px',
      maxWidth:'683px',
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',

      [theme.breakpoints.down('md')]: {
        paddingBottom:'40px',
      },

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'inherit',
        alignItems: 'center',
        paddingBottom:'inherit',
      },
    },

    imageBottom: {
      [theme.breakpoints.down('xs')]: {
        paddingBottom: '43px',
      },
    },
    platformContent: {
      marginTop: '27px',
      background: '#E0FCD6 0% 0% no-repeat padding-box',
    },
    dataPlatform: {
      paddingLeft: '80px',

      [theme.breakpoints.down('md')]: {
        paddingTop: '80px',
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: '62px',
        paddingLeft: '36px',
      },
    },
    platformTitle: {},
    plusPlatform: {
      font: 'normal normal 600 30px/35px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    normalTitle: {
      font: 'normal normal 300 30px/35px Poppins',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    knowMoreDiv: {
      marginTop: '5px',
      '& a': {
        textDecoration: 'underline',
        font: 'normal normal bold 16px/20px Lato',
        letterSpacing: '0px',
        color: '#0A642D',
        opacity: 1,
      },

      [theme.breakpoints.down('xs')]: {
        paddingBottom: '15px',
      },
    },
    carousel:{
      paddingBottom: '30px',
    },
  })
);

export default acinGroupMobileStyles;
