import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const freeTrialStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      color: 'white',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
      },
      [theme.breakpoints.up('lg')]: {
        minHeight: '1120px',
        maxHeight: '1120px',
      },
    },
    columnLeft: {
      paddingLeft: '7.5%',
      backgroundColor: '#263238',
      paddingTop: 210,
      paddingRight: '30px',

      [theme.breakpoints.down('md')]: {
        paddingLeft: '80px',
        paddingTop: '170px',
        paddingRight: '31%',
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: 85,
        paddingRight: '10%',
      },

      [theme.breakpoints.down('xs')]: {
        paddingTop: 85,
        paddingLeft: '7%',
        paddingRight: '6%',
      },
    },
    desapearColumnLeft: {
      display: 'none',
    },
    title: {
      font: 'normal normal 600 48px/75px Poppins',
      letterSpacing: '0px',
      color: '#FFFFFF',
      textTransform: 'capitalize',


      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/26px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/20px Poppins',
        fontSize: 30,
      },
    },
    titleBlue: {
      font: 'normal normal 600 48px/38px Poppins',
      letterSpacing: '0px',
      color: '#00ACFF',
      textTransform: 'capitalize',

      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 36px/26px Poppins',
      },

      [theme.breakpoints.down('xs')]: {
        font: 'normal normal 600 30px/20px Poppins',
      },
    },
    contentTitle: {
      fontSize: '30px',
      lineHeight: 1.2,
    },
    content: {
      color: 'white',
      marginBottom: 30,
      marginTop: 33.5,
      maxWidth: '579.16px',

      [theme.breakpoints.down('md')]: {
        maxWidth: 'unset',
      },
    },
    subtitle: {
      marginBottom: 41,
      color: 'white',
      fontWeight: 700,
      paddingRight: '16%',

      [theme.breakpoints.down('md')]: {
        paddingRight: 'unset',
      },
    },
    animation: {
      width: '100%',
      marginTop: 45,
      marginBottom: 60,
      maxWidth: '800px',

      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: '36%',
        bottom: 0,
      },

      [theme.breakpoints.up('md')]: {
        marginBottom: '90px'
      },

      [theme.breakpoints.up(1920)]: {
        marginBottom: '128px !important',
        maxWidth: '900px'
      }
    },
    animationActive: {
      transition: 'linear 0.4s',
      '& div:first-child': {
        width: '90% !important'
      },
      [theme.breakpoints.up(1280)]: {
        left: '4%',
      },
      [theme.breakpoints.up(1410)]: {
        left: '8%',
      },
      [theme.breakpoints.up(1620)]: {
        left: '14%',
      }
    },
    columnRigth: {
      opacity: 0,
      transition: 'ease-in 0.5s',
    },
    animecolumnRigth: {
      opacity: 1,

      [theme.breakpoints.down('md')]: {
        marginBottom: '75px',
      },
    },
    arrowIcon: {
      transform: 'rotate(180deg)',
      position: 'absolute',
      left: '7%',
      top: '10%',
      color: '#263238',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        top: '8%',
      },
      [theme.breakpoints.down('xs')]: {
        top: '5%',
      },
    },
    buttonHeight: {
      height: 50,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    contentForm: {},
    options: {
      fontSize: '16px',
    }
  })
);

export default freeTrialStyle;
