import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const videoStyle = makeStyles((theme: Theme) =>
  createStyles({
    videoContent:{
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    backgroundColor: '#000',
    },

    videoPlayer: {
      width: '100%',
      height: '100%',
    },

    buttonsContainer: {
      top: '50px',
      right: 0,
      display: 'flex',
      position: 'fixed',
      alignItems: 'center',
      marginRight: '40px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },

    closeBtn: {
      width: '55px',
      height: '55px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      opacity: 1,
      borderRadius: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    videoControlBtn: {
      width: '55px',
      height: '55px',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      opacity: 1,
      borderRadius: '50%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginTop: '15px',
    }
  })
);

export default videoStyle;