import React, {
  Fragment, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link} from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import Typography from '@material-ui/core/Typography';
import TitleList from '../../../../../components/layout/footer/components/title';
import { TrialFormContext } from '../../../../../context/TrialFormContext';
import menuStyle from './Menu.styles';
import { acinLink } from '../../../../../global-constants'
import {MenuList} from '../../../../../Interfaces';

function Menu() {
  const classes = menuStyle();
  const { t: translation, i18n } = useTranslation();
  const { onToggleForm } = useContext(TrialFormContext)
  const links: MenuList[] = [
    {
      name: 'ACIN Group',
      to: acinLink[i18n.language],
    },
    {
      name: "FAQ",
      to: '/faqs',
    },
    {
      name: translation('features'),
      to: '/#features',
    },
    {
      name: translation('requestTest').toLowerCase(),
      to: '/#demo',
    },
    {
      name: translation('prices'),
      to: '/#prices',
    },
  ];

  const pageLink = (name: string, to: string) => (
    <li className={classes.marginTop} key={name}>
      {
        (name === 'ACIN Group') ?
          <a href={to} target="blank" className={classes.list}>
            <Typography variant="body2" component="span" className={classes.footerFonts}>
              {name}
            </Typography>
          </a>
        :
          <Link to={to} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} } className={classes.list} onClick={to === '/#demo' ? onToggleForm : null}>
            <Typography variant="body2" component="span" className={classes.footerFonts}>
              {name}
            </Typography>
          </Link>
      }
    </li>
  );

  return (
    <>
      <TitleList name={translation('menu')} className={classes.title} />
      <ul className={classes.noBullets}>
        {links.map(link => pageLink(link.name, link.to))}
      </ul>
    </>
  );
}

export default Menu;
