import { makeStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import { SidePaneTheme } from "@acin.reactjs/side-pane";

declare module "@material-ui/core/styles" {
  interface ThemeOptions extends SidePaneTheme {}

  interface Theme {
    sidePane?: SidePaneTheme["sidePane"];
  }
}

const homeStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      paddingLeft: '7%',
      marginBottom: '53PX',

      '@media screen and (min-height: 900px)': {
        minHeight: '90vh',
      },

      '@media screen and (min-height: 1000px)': {
        minHeight: '80vh',
      },

      '@media screen and (min-height: 1150px)': {
        minHeight: '70vh',
      },

      '@media screen and (min-height: 1350px)': {
        minHeight: '60vh',
      },

      [theme.breakpoints.down('md')]: {
        minHeight: 0,
        marginBottom: 18,
      },

      [theme.breakpoints.down('xs')]: {
        marginBottom: '60px',
        paddingRight: '6%',
      },
    },
    columnRight: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 53,
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    columnLeft: {
      marginTop: '274px',

      [theme.breakpoints.down(1280)]: {
        marginTop: '80px',
        marginBottom: '42px',
      },

      [theme.breakpoints.down('xs')]: {
        marginTop: 70,
      },
    },
    animatedImage:{
      paddingTop: '190px',
      width:'100%',

      [theme.breakpoints.down('md')]: {
        paddingTop: '5px',
        maxHeight: '563px',
        maxWidth: '713px',
        height: 'unset',
        display: 'unset',
        alignItems: 'unset',
        justifyContent: 'unset',
      },

      [theme.breakpoints.down(960)]: {
        paddingTop: 0,

        '& div > div': {
          display: 'flex',

          '& svg': {
            width: 'unset !important',
            maxWidth: '100%',
          }
        }
      }
    }
  })
);

export const sidePaneTheme = createTheme({
  palette: {
    primary: {
      main: "#13253C",
    },
  },
  typography: {
    fontFamily: "Arial",
  },
  sidePane: {
    root: {
      padding: 0,
      backgroundColor: '#000',
    },
    buttonBack: {
      display: 'none',
    },
    widths: {
      small: "100%",
      medium: "100%",
      large: "100%",
    },
    mobileWidth: "100%",
    tabletWidth: "100%",
  },
});;

export default homeStyle;
