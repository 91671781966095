import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const socialStyle = makeStyles((theme: Theme) =>
  createStyles({
    socialMedia: {
      paddingTop: '25px',
      minWidth: '150px',
      marginLeft: '-7px',
      
      [theme.breakpoints.only('xs')]: {
        justifyContent: 'center',
        marginLeft: 'unset',
      },
    },
    flexBasis: {
      flexBasis: '40px',
    },
    icon: {
      color: '#1D1D1B',
    },
  })
);

export default socialStyle;
