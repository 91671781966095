import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const baseErrorMobileStyle = makeStyles((theme: Theme) => 
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      backgroundColor: 'white',
    },

    dataContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'center',
      paddingLeft: '7%',
      paddingTop:'55px'
    },

    title: {
      font: 'normal normal 600 36px/36px Poppins',
      width: '100%',
      marginBottom: '8px',
      letterSpacing: '0px',
      color: '#1d1d1d',
    },

    subTitle: {
      font: 'normal normal 600 20px/24px Poppins',
      color: '#1d1d1d',
      width: '80%',
      marginBottom: '8px',
    },

    description: {
      font: 'normal normal 300 16px/22px Lato',
      color: '#1d1d1d',
      opacity: 1,
      width: '100%',
    },

    buttons: {
      marginTop: '14px',
    },

    imageContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent:'center',
      height:'100vh',

      '& .image': {
        height: '70%',
      },
    },
  })
);

export default baseErrorMobileStyle;