const palette = {
  primary: {
    light: '#E2FDFF',
    main: '#00ACFF',
    dark: '#0099E6',
    contrastText: '#005580',
  },
  secondary: {
    light: '#E0FCD6',
    main: '#39D155',
    dark: '#1AA732',
    contrastText: '#0A642D',
  },
  error: {
    light: '#FEE0D3',
    main: '#FB4628',
    dark: '#D72A1D',
    contrastText: '#780F06',
  },
  warning: {
    light: '#FFF5D8',
    main: '#FFBF01',
    dark: '#E3AA00',
    contrastText: '#B38600',
  },
};

export default palette;
