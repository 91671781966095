import React, { useContext } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HashLink as Link} from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import { TrialFormContext } from '../../../../../context/TrialFormContext';
import freeTrialStyle from './FreeTrial.styles';

function FreeTrial() {
  const { t: translation } = useTranslation();
  const classes = freeTrialStyle();
  const { onToggleForm, showForm } = useContext(TrialFormContext);

  const onShowForm = () => {
    if(!showForm) onToggleForm();
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.gridInfo}>
        <Typography variant="body1">{translation('introFreeTrial')}</Typography>
        <Typography component="span" className={classes.colorHighlighted}>
          {translation('tryItForFree')}
        </Typography>
        <Typography component="span" className={classes.bold}>
          {translation('duringThirtyDays')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.gridButton}>
        <Link to="/#demo" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
          <Button variant="contained" size="small" color="primary" className={classes.button} onClick={onShowForm}>
            {translation('requestTest')}
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
}

export default FreeTrial;
