import React, {
  FC
} from 'react';
import { constants } from '../../../../../global-constants';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import creditStyle from './Credit.styles';
import W3c from '../../../../../assets/images/w3c.svg';

const Credit: FC = () => {
  const classes = creditStyle();
  const { t: translation, i18n } = useTranslation();

  const {
    links: { terms, suggestions },
    languages,
    w3Org
  } = constants;

  return (
    <>
      <Grid item xs={12} className={classes.w3cIcon}>
        <a href={w3Org} target="_blank" rel="noopener noreferrer">
          <img width='88px' height='31px' src={W3c} alt="w3c icon" title="w3c icon" />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.credits}>
        <Typography variant="body2" component="span" className={classes.footerFonts}>
          &copy; iDOK {new Date().getFullYear()} - {translation('copyright')}
        </Typography>
        <Typography variant="body2" className={classes.footerFonts}>
          <Link href={`${translation('policyLink')}`} target="_blank" rel="noopener" className={classes.list}>
            {translation('privacyPolicy')}
          </Link>
          <Typography component="span" className={classes.footerFonts}>
            {' '}
            |{' '}
          </Typography>
          <Link href={`${terms}_${languages[i18n.language].toLowerCase() || 'pt'}.pdf`} target="_blank" rel="noopener" className={classes.list}>
            {translation('terms')}
          </Link>
          <Typography component="span" className={classes.footerFonts}>
            {' '}
            |{' '}
          </Typography>
          <Link href={suggestions} target="_blank" rel="noopener" className={classes.list}>
            {translation('suggestions')}
          </Link>
        </Typography>
      </Grid>
    </>
  );
};

export default Credit;
