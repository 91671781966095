import {makeStyles, Theme} from '@material-ui/core';

const selosTemporaisPageStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 17,
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  },
  introductionContainer: {
    backgroundColor: '#f5f5f5',
    minHeight: 'calc(100vh - 80px)',
    height: '100%',
    display: 'flex',
    padding: '0 7%',
    paddingBottom: 60
  },
  introductionInfoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      font: 'normal normal 600 48px/56px Poppins',
      margin: 0,
      color: '#1d1d1d',
      '& span': {
        color: '#d72a1d'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      paddingTop: 45
    }
  },
  introductionIntegration: {
    font: 'normal normal 600 18px/27px Poppins',
    color: '#780F06',
    margin: 0
  },
  separator: {
    width: '150px',
    height: '12px',
    backgroundColor: '#d72a1d',
    margin: '20px 0 50px',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    }
  },
  whatIsStamps: {
    margin: '0 0 20px 0',
    font: 'normal normal bold 20px/24px Lato',
    color: '#1d1d1d',
    [theme.breakpoints.down('md')]: {
      font: 'normal normal bold 18px/24px Lato',
      marginBottom: 15
    }
  },
  whatIsStampsAnswer: {
    margin: 0,
    font: 'normal normal 400 20px/24px Lato',
    color: '#1d1d1d',
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 400 18px/24px Lato'
    }
  },
  introductionImgContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    '& .banner-image': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600,
        maxHeight: 600
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 674,
        maxHeight: 720
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  introductionImgContainerSmall: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .banner-image': {
        maxHeight: 324
      },
      marginBottom: 30
    }
  },
  advantagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 110,
    paddingBottom: 130,
    gap: 85,
    [theme.breakpoints.down('md')]: {
      paddingTop: 70,
      paddingBottom: 80
    }
  },
  advantagesParagraphContainer: {
    position: 'relative',
    textAlign: 'center',
    margin: '0 22%',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      margin: '0 0 0 35px'
    },
    '& h2': {
      margin: 0,
      font: 'normal normal 600 48px/56px Poppins',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    }
  },
  paragraphBlueText: {
    color: '#00ACFF'
  },
  paragraphBlueBox: {
    width: 52,
    height: 52,
    backgroundColor: '#e2fdff',
    position: 'absolute',
    zIndex: -1,
    top: '-13px',
    left: '-34px',
    [theme.breakpoints.down('md')]: {
      top: '-19px'
    }
  },
  advantagesParagraphText: {
    position: 'relative',
    zIndex: 1
  },
  toolCardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
    [theme.breakpoints.down(890)]: {
      flexDirection: 'column'
    }
  },
  toolCardContent: {
    maxWidth: 398,
    width: '100%',
    boxShadow: '0 0 5px #00000029',
    borderRadius: 26,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      flex: 1,
      minHeight: 70
    }
  },
  toolCardContentImgContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  toolCardContentTextContainer: {
    font: 'normal normal 400 18px/22px Lato',
    color: '#1d1d1d',
    '& p': {
      margin: 0
    }
  },
  securityContainer: {
    position: 'relative',
    backgroundColor: '#263238'
  },
  securityBackgroundBoxOne: {
    position: 'absolute',
    height: 224,
    width: 224,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 0,
    [theme.breakpoints.down('md')]: {
      height: 94,
      width: 94,
      top: 74
    }
  },
  securityBackgroundBoxTwo: {
    position: 'absolute',
    height: 177,
    width: 177,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 0,
    right: 224,
    [theme.breakpoints.down('md')]: {
      width: 74,
      height: 74,
      right: 94
    }
  },
  securityBackgroundBoxThree: {
    position: 'absolute',
    height: 88,
    width: 88,
    backgroundColor: '#ffffff',
    opacity: '0.1',
    top: 177,
    right: 401,
    [theme.breakpoints.down('md')]: {
      width: 37,
      height: 37,
      top: 74,
      right: 168
    }
  },
  infoSecurityContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '130px 7% 130px 7%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 0,
      flexDirection: 'column'
    },
    [theme.breakpoints.up(1920)]: {
      paddingBottom: 0
    }
  },
  securityTextContainer: {
    flex: 1,
    '& h2': {
      font: 'normal normal 600 48px/56px Poppins',
      color: '#ffffff',
      margin: 0,
      marginBottom: 45,
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins',
        marginBottom: 30
      }
    },
    '& p': {
      font: 'normal normal 400 20px/24px Lato',
      color: '#ffffff',
      margin: 0,
      '&:not(first-child)': {
        marginTop: 20,
        [theme.breakpoints.down('md')]: {
          margin: 0
        }
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 400 18px/24px Lato',
        margin: 0
      }
    }
  },
  securityImgContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: 30
    },
    '& img': {
      position: 'relative',
      top: 70,
      zIndex: 1,
      height: 500,
      [theme.breakpoints.up(1920)]: {
        height: 650,
        top: 95
      },
      [theme.breakpoints.down('md')]: {
        height: 350,
        top: 55
      },
      [theme.breakpoints.down('xs')]: {
        height: 200
      }
    }
  },
  gtsIntegrationContainer: {
    display: 'flex',
  },
  gtsIntegrationImgContainer: {
    flex: 1,
    backgroundColor: '#e0fcd6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '150px 7% 170px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      right: '-135px',
      [theme.breakpoints.down(1920)]: {
        height: 430
      },
      [theme.breakpoints.down(1620)]: {
        height: 372,
        right: '-105px'
      },
      [theme.breakpoints.down(1400)]: {
        height: 322,
        right: '-89px'
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  gtsIntegrationSmallImgContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 30
    },
    '& img': {
      width: '100%'
    }
  },
  gtsIntegrationTextContainer: {
    flex: 1,
    padding: '150px 7% 150px',
    [theme.breakpoints.down('md')]: {
      paddingTop: 100,
      paddingBottom: 100
    }
  },
  gtsIntegrationTextTitle: {
    font: 'normal normal 600 48px/56px Poppins',
    color: '#1d1d1d',
    margin: 0,
    marginBottom: 45,
    '& span': {
      color: '#1aa732'
    },
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 600 30px/35px Poppins',
      marginBottom: 30
    }
  },
  gtsIntegrationTextParagraph: {
    marginBottom: 75,
    '& p': {
      font: 'normal normal normal 20px/24px Lato',
      color: '#1d1d1d',
      margin: 0,
      '&:first-of-type': {
        marginBottom: 20
      }
    }
  },
  bolderText: {
    fontWeight: 600,
    textDecoration: 'underline',
    color: '#1d1d1d'
  },
  gtsIntegrationTextButton: {
    display: 'flex',
    justifyContent: 'end',
    '& a': {
      padding: '15px 25px 12px',
      backgroundColor: '#1aa732',
      color: '#ffffff',
      font: 'normal normal 600 16px/25px Poppins',
      textTransform: 'uppercase',
      borderRadius: 5,
      cursor: 'pointer',
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        padding: '14px 97px 13px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  formContainer: {
    backgroundColor: '#E2FDFF',
    justifyContent: 'center',
    paddingTop: '165px',
    paddingLeft: 36,
    paddingRight: 36,
    paddingBottom: '230px',
    flexDirection: 'column',
    alignItems: 'center',
    '& form': {
      padding: 0,
      paddingTop: 40,
      height: 'auto',
      minHeight: 'unset',
      maxWidth: '812px',
      boxShadow: 'none',
      '& .form-container-trial': {
        backgroundColor: '#e2fdff'
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 85,
      paddingBottom: 100,
      marginBottom: 60
    }
  },
  formTitleContainer: {
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-start'
    }
  },
  formTitle: {
    font: 'normal normal 600 40px/56px Poppins',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      font: 'normal normal 600 30px/35px Poppins',
    }
  },
  newsLetterContainer: {
    '& .container-subscription': {
      [theme.breakpoints.up('lg')]: {
        marginTop: '-100px'
      }
    }
  }
}));

export default selosTemporaisPageStyles;