import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__ISO_20000"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 132.3 130.2"
      xmlSpace="preserve"
      width= {130.448}
      height= {127.284}
      {...props}
    >
      <title>
        Iso 20000
      </title>
      <style>
        {
          ".prefix__st0{fill:#e74e00}.prefix__st1{fill:#696868}.prefix__st3{fill:#fff}.prefix__st4{fill:none;stroke:#fff;stroke-width:7.300000e-02;stroke-miterlimit:3.864}"
        }
      </style>
      <path
        id="prefix__Caminho_1768"
        className="prefix__st0"
        d="M62.3 78.6L51.9 68.2V52.3l10.4 10.4L86.1 39c-13.1-13.1-34.4-13.1-47.5.1-13.1 13.1-13.1 34.4.1 47.4 13.1 13.1 34.3 13.1 47.4-.1 10-10.1 12.6-25.4 6.5-38.2L62.3 78.6z"
      />
      <path
        id="prefix__Caminho_1769"
        className="prefix__st1"
        d="M82.8 123.9c-3.1-.9-3.7-3.6-3.5-6.5h4.4c0 2 .1 3.7 2.5 3.7 1.2.1 2.2-.8 2.2-2v-.4c0-3.8-8.9-4-8.9-10.3 0-3.3 1.6-5.9 7-5.9 4.3 0 6.4 1.9 6.1 6.3h-4.3c0-1.6-.3-3.2-2.1-3.2-1.1-.1-2.2.7-2.3 1.8v.4c0 4 8.9 3.7 8.9 10.3 0 3.7-1.8 5.2-4 5.7l-6 .1z"
      />
      <path
        id="prefix__Caminho_1770"
        className="prefix__st1"
        d="M103.7 109.3c0-2-.3-3.7-2.6-3.7-2.8 0-2.8 3.7-2.8 7.8 0 6.6.6 7.8 3.2 7.8.8 0 1.5-.2 2.2-.4v-4.7h-2.4v-3.2h6.8v10.7c-.5.1-1.2.2-2.1.3h-7.6c-4.1-1-4.7-4.3-4.7-10.7 0-5.3.3-10.7 7.5-10.7 4.3 0 7 2.4 6.8 6.8h-4.3z"
      />
      <path
        id="prefix__Caminho_1771"
        className="prefix__st1"
        d="M116.4 102.5c3.9 0 6 1.5 6.3 4.9v1.4h-4.3c0-1.5-.4-3.2-2.2-3.2-1.1-.1-2.1.7-2.3 1.8v.4c0 3.7 7.6 3.7 8.8 8.9v3.1c-.3 2-1.8 3.7-3.8 4.1h-6.2c-3.1-.9-3.7-3.6-3.5-6.5h4.4c0 2 .1 3.7 2.5 3.7 1.2.1 2.2-.8 2.2-2v-.4c0-3.8-8.9-4-8.9-10.3.1-3.3 1.7-5.9 7-5.9"
      />
      <path
        id="prefix__Ret\xE2ngulo_1567"
        className="prefix__st0"
        d="M66 123.9h65.4v.8H66z"
      />
      <path
        id="prefix__Ret\xE2ngulo_1568"
        className="prefix__st0"
        d="M122.7 98.6h.8v30.5h-.8z"
      />
      <path
        id="prefix__Ret\xE2ngulo_1569"
        transform="rotate(-4.14 6.379 67.045) scale(1.00001)"
        className="prefix__st0"
        d="M1.4 66.2h9.9v1.7H1.4z"
      />
      <path
        id="prefix__Caminho_1772"
        className="prefix__st0"
        d="M5.3 75.6c1.8-.3 2.2-1.8 2.6-3 .3-1 .6-1.8 1.4-1.9.6-.1 1.1.2 1.3.8v.2c.1.3 0 .6-.1.9-.3.3-.7.5-1.1.5h-.3l.3 1.7h.2c.8 0 1.6-.4 2.1-1 .4-.7.6-1.5.4-2.3-.1-.8-.5-1.6-1.2-2.1-.6-.4-1.4-.6-2.1-.4-1.7.3-2.1 1.7-2.5 2.9-.3 1-.6 1.8-1.4 2-.3.1-.7 0-.9-.2-.3-.3-.5-.6-.5-1-.1-.3 0-.7.2-1 .2-.3.6-.5 1-.5l.5-.1-.3-1.7h-.3c-.9.1-1.7.5-2.3 1.1-.4.7-.6 1.6-.4 2.4.3 1.8 1.2 2.7 2.7 2.7.2.1.4 0 .7 0z"
      />
      <path
        id="prefix__Caminho_1773"
        className="prefix__st0"
        d="M6.5 83.1c.6 0 1.3-.1 1.9-.3l2.9-.9c1.6-.3 2.7-1.8 2.4-3.4 0-.2-.1-.4-.2-.6-.2-.8-.7-1.6-1.5-2-.8-.4-1.8-.4-2.6-.1l-2.9.9c-2.5.8-3.4 2.1-2.8 4.2.3 1.3 1.5 2.2 2.8 2.2zm1.3-2c-2 .6-2.4 0-2.6-.7-.2-.4-.1-.8.1-1.2.5-.5 1.1-.8 1.7-.9l3-.9c.3-.1.5-.1.8-.1.6 0 1.2.4 1.3 1 .3.9-.1 1.6-1.3 1.9l-3 .9z"
      />
      <path
        id="prefix__Caminho_1774"
        className="prefix__st0"
        d="M15.5 83.6L4 85l.6 1.3 11.5-1.4-.6-1.3z"
      />
      <path
        id="prefix__Ret\xE2ngulo_1570"
        transform="rotate(-27.557 12.587 88.9)"
        className="prefix__st0"
        d="M7.6 88h9.9v1.7H7.6z"
      />
      <path
        id="prefix__Caminho_1775"
        className="prefix__st0"
        d="M16.2 96.2l1.3-.9-1.8-2.8 2.1-1.4 2 3 1.3-.8-2.9-4.5-8.1 5.3-.2.1 3 4.5 1.3-.8-2-3.1 2.3-1.5 1.7 2.9z"
      />
      <path
        id="prefix__Caminho_1776"
        className="prefix__st0"
        d="M22.2 99.1l-.2.2 1.1 1.3.2-.1c.7-.4 1.2-1.2 1.4-2 0-.8-.3-1.5-.8-2.1-1.4-1.7-3-1.7-4.9-.1L17 98c-1 .7-1.6 1.7-1.9 2.8 0 .8.3 1.6.9 2.2.5.6 1.2 1 1.9 1.1h.2c.8-.1 1.6-.4 2.2-1l.2-.2-1.1-1.3-.2.2c-1.1 1-1.8.5-2.1.2-.3-.2-.4-.6-.4-1 .2-.7.7-1.2 1.2-1.6l1.9-1.6c1-.9 1.4-1 1.5-1h.1c.5-.1.9.1 1.2.5.2.2.3.5.3.8 0 .4-.4.7-.7 1z"
      />
      <path
        id="prefix__Caminho_1777"
        className="prefix__st0"
        d="M26.6 108.9c2.2-.5 3.1-.7 4.2-2 .5-.5.7-1.2.7-1.9-.1-.7-.5-1.4-1.2-1.8-.5-.5-1.2-.7-1.9-.6-.7.1-1.4.6-1.7 1.2l-.1.2 1.3 1.1.2-.2c.2-.3.5-.5.8-.6.2 0 .4.1.5.2.2.1.3.3.4.5 0 .3-.1.5-.3.7-.7.9-1.5 1.1-4 1.8-.6.2-1.4.4-2.2.6h-.1l-.9 1.1 4.2 3.3 1-1.2-2.6-2.1c.6-.1 1.2-.2 1.7-.3z"
      />
      <path
        id="prefix__Caminho_1778"
        className="prefix__st0"
        d="M35.1 112.2c1.3-2 2.5-4.1.3-5.5-.8-.6-2-.6-2.8.1-.8.8-1.5 1.6-2 2.6l-.1.1c-.6.9-1.1 1.9-1.5 2.9-.3 1 .2 2.1 1.2 2.6.4.3.9.4 1.4.5.5 0 1.1-.2 1.5-.6.8-.8 1.5-1.6 2-2.6l-.2-.2.2.1zm-1.8-.4c-.5.9-1.2 1.9-1.8 2-.2 0-.4 0-.5-.1-.2-.1-.3-.3-.3-.4-.1-.6.5-1.6 1-2.5l.1-.2c.5-.8 1-1.6 1.6-2.3.2-.2.5-.4.8-.4.1 0 .3.1.4.1.2.1.3.2.3.4.1.6-.6 1.8-1.4 3.2l-.2.2z"
      />
      <path
        id="prefix__Caminho_1779"
        className="prefix__st0"
        d="M40.7 109.6c-2.4-1.1-3.5 1.1-4.5 3.2l-.1.1c-.5 1-.9 2-1.1 3.1-.2 1.1.4 2.1 1.5 2.5.4.2.8.3 1.2.3 1.6 0 2.5-1.9 3.3-3.6l-.2-.2.2.1.1-.1c1-2 2-4.3-.4-5.4zm-1.3 5.1l-.1.3c-.4.9-.9 2-1.5 2.2-.2.1-.4 0-.6 0-.2-.1-.3-.2-.4-.4-.2-.6.3-1.7.7-2.6l.1-.3c1-2.2 1.6-3.3 2.4-2.9s.4 1.5-.6 3.7z"
      />
      <path
        id="prefix__Caminho_1780"
        className="prefix__st0"
        d="M46.3 111.8c-1-.4-2.1-.1-2.7.7-.6.9-1 1.9-1.3 3l-.1.2c-.7 2.2-1.5 4.6 1 5.5.3.1.6.2 1 .2 1.8 0 2.5-2.1 3.1-3.9l-.2-.1.2.1v-.1c.8-2.4 1.5-4.7-1-5.6zm-.8 5.5c-.3 1-.7 2.1-1.3 2.4-.2.1-.4.1-.6 0-.2-.1-.3-.2-.4-.4-.3-.6.1-1.7.4-2.7l.1-.3c.3-.9.6-1.8 1-2.6.3-.5.5-.6.8-.6.1 0 .2 0 .3.1.9.3.5 1.4-.2 3.8l-.1.3z"
      />
      <path
        id="prefix__Caminho_1781"
        className="prefix__st0"
        d="M54.2 115.4c-.1-1.1-.9-1.9-2-2-2.6-.5-3.1 1.9-3.6 4.2v.1c-.5 2.2-1 4.8 1.7 5.3.2 0 .4.1.7.1.8 0 1.6-.4 2-1.2.5-1 .8-2 1-3.1l-.2-.1.3.1v-.1c0-1.1.1-2.2.1-3.3zm-2.1 3.3l-.1.3c-.2 1-.4 2.2-1 2.5-.2.1-.4.1-.6.1-.2 0-.4-.1-.5-.3-.4-.5-.1-1.7.1-2.7l.1-.3c.4-2.2.7-3.4 1.5-3.4h.2c1 .2.8 1.4.3 3.8z"
      />
      <g id="prefix__Grupo_390">
        <path
          id="prefix__Caminho_1782"
          d="M123.5 93.7V63.1c.4-33.9-26.7-61.6-60.6-62S1.3 27.8 1 61.7v1.4h12.3c0-27.1 21.9-49 49-49.1s49 21.9 49.1 49v30.6h12.1z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#696868"
        />
        <path
          id="prefix__Caminho_1783"
          className="prefix__st3"
          d="M23.5 26.2l.1.1c.3.4 1 .5 1.4.2.1 0 .1-.1.1-.1.4-.3.5-.9.2-1.3 0-.1-.1-.1-.2-.2-1-1-2.8 1.2-4.2-.1-.7-.6-.7-1.7-.1-2.3l.2-.2c.5-.7 1.5-.9 2.1-.4.1.1.3.2.4.4l-.5.5c-.5-.5-1-.6-1.5-.1-.4.3-.4.9-.1 1.2l.1.1c1 1 2.8-1.2 4.2.2.7.8.6 2-.2 2.7-.8.7-1.9.6-2.6-.1l.6-.6z"
        />
        <path
          id="prefix__Caminho_1784"
          className="prefix__st4"
          d="M23.5 26.2l.1.1c.3.4 1 .5 1.4.2.1 0 .1-.1.1-.1.4-.3.5-.9.2-1.3 0-.1-.1-.1-.2-.2-1-1-2.8 1.2-4.2-.1-.7-.6-.7-1.7-.1-2.3l.2-.2c.5-.7 1.5-.9 2.1-.4.1.1.3.2.4.4l-.5.5c-.5-.5-1-.6-1.5-.1-.4.3-.4.9-.1 1.2l.1.1c1 1 2.8-1.2 4.2.2.7.8.6 2-.2 2.7-.8.7-1.9.6-2.6-.1l.6-.6z"
        />
        <path
          id="prefix__Caminho_1785"
          className="prefix__st3"
          d="M25.8 18l.6-.5 1.2 4 1.6 1.8-.6.5L27 22l-3.8-1.8.7-.6 2.9 1.5-1-3.1z"
        />
        <path
          id="prefix__Caminho_1786"
          className="prefix__st4"
          d="M25.8 18l.6-.5 1.2 4 1.6 1.8-.6.5L27 22l-3.8-1.8.7-.6 2.9 1.5-1-3.1z"
        />
        <path
          id="prefix__Caminho_1787"
          className="prefix__st3"
          d="M30.5 19.8l.1.1c.3.5.9.7 1.3.4.1 0 .1-.1.2-.1.5-.2.7-.8.4-1.3 0-.1-.1-.1-.1-.2-.8-1.1-3 .6-4.1-.9-.6-.7-.4-1.8.3-2.3.1 0 .1-.1.2-.1.6-.6 1.6-.6 2.2 0 .1.1.2.3.3.4l-.7.5c-.4-.6-.8-.8-1.4-.4-.4.2-.6.8-.3 1.2 0 0 0 .1.1.1.8 1.1 3-.7 4.1.9.5.9.3 2-.6 2.6-.9.5-2 .3-2.6-.6l.6-.3z"
        />
        <path
          id="prefix__Caminho_1788"
          className="prefix__st4"
          d="M30.5 19.8l.1.1c.3.5.9.7 1.3.4.1 0 .1-.1.2-.1.5-.2.7-.8.4-1.3 0-.1-.1-.1-.1-.2-.8-1.1-3 .6-4.1-.9-.6-.7-.4-1.8.3-2.3.1 0 .1-.1.2-.1.6-.6 1.6-.6 2.2 0 .1.1.2.3.3.4l-.7.5c-.4-.6-.8-.8-1.4-.4-.4.2-.6.8-.3 1.2 0 0 0 .1.1.1.8 1.1 3-.7 4.1.9.5.9.3 2-.6 2.6-.9.5-2 .3-2.6-.6l.6-.3z"
        />
        <path
          id="prefix__Caminho_1789"
          className="prefix__st3"
          d="M34.9 11.8l.4.6-1.4.8 2.8 4.8-.7.4-2.8-4.8-1.4.8-.3-.6 3.4-2z"
        />
        <path
          id="prefix__Caminho_1790"
          className="prefix__st4"
          d="M34.9 11.8l.4.6-1.4.8 2.8 4.8-.7.4-2.8-4.8-1.4.8-.3-.6 3.4-2z"
        />
        <path
          id="prefix__Caminho_1791"
          className="prefix__st3"
          d="M38.8 16.8l-2.7-5.6L39 9.8l.3.6-2.2 1 .9 1.8 2-1 .3.6-2 1 .9 2 2.2-1.1.3.6-2.9 1.5z"
        />
        <path
          id="prefix__Caminho_1792"
          className="prefix__st4"
          d="M38.8 16.8l-2.7-5.6L39 9.8l.3.6-2.2 1 .9 1.8 2-1 .3.6-2 1 .9 2 2.2-1.1.3.6-2.9 1.5z"
        />
        <path
          id="prefix__Caminho_1793"
          className="prefix__st3"
          d="M43.4 14.7l-.7.3-2-5.9 1.3-.5 3.2 4.5-.2-5.5 1.2-.4 2 5.9-.8.3-1.8-5.3.1 5.8-.7.2-3.4-4.7 1.8 5.3z"
        />
        <path
          id="prefix__Caminho_1794"
          className="prefix__st4"
          d="M43.4 14.7l-.7.3-2-5.9 1.3-.5 3.2 4.5-.2-5.5 1.2-.4 2 5.9-.8.3-1.8-5.3.1 5.8-.7.2-3.4-4.7 1.8 5.3z"
        />
        <path
          id="prefix__Caminho_1795"
          className="prefix__st3"
          d="M53.5 7.5c0-.3-.1-.6-.3-.9-.2-.1-.5-.2-.7-.2-.4 0-.7.3-.9.6 0 .1-.1.3 0 1.3l.3 1.6c.2 1.5.8 1.6 1.4 1.5.2 0 1-.2.8-1.4l.8-.1c.4 1.9-1 2.2-1.4 2.2-.8.1-2 .3-2.3-2.1l-.3-1.7c-.3-1.7.4-2.4 1.6-2.6s1.8.4 1.9 1.6l-.9.2z"
        />
        <path
          id="prefix__Caminho_1796"
          className="prefix__st4"
          d="M53.5 7.5c0-.3-.1-.6-.3-.9-.2-.1-.5-.2-.7-.2-.4 0-.7.3-.9.6 0 .1-.1.3 0 1.3l.3 1.6c.2 1.5.8 1.6 1.4 1.5.2 0 1-.2.8-1.4l.8-.1c.4 1.9-1 2.2-1.4 2.2-.8.1-2 .3-2.3-2.1l-.3-1.7c-.3-1.7.4-2.4 1.6-2.6s1.8.4 1.9 1.6l-.9.2z"
        />
        <path
          id="prefix__Caminho_1797"
          className="prefix__st3"
          d="M56.2 11.7l-.4-6.2 3.2-.2.1.7-2.4.2.1 2L59 8l.1.7-2.2.2.1 2.1 2.5-.2v.7l-3.3.2z"
        />
        <path
          id="prefix__Caminho_1798"
          className="prefix__st4"
          d="M56.2 11.7l-.4-6.2 3.2-.2.1.7-2.4.2.1 2L59 8l.1.7-2.2.2.1 2.1 2.5-.2v.7l-3.3.2z"
        />
        <path
          id="prefix__Caminho_1799"
          className="prefix__st3"
          d="M61.4 8.6v2.9h-.8l.1-6.2h2.2c.8 0 1.4.6 1.5 1.3v.2c0 .7-.5 1.3-1.2 1.4.7.1 1 .4 1 1.6 0 .6.1 1.1.2 1.6h-.8c-.2-.7-.2-1.4-.2-2.1 0-.5-.4-.8-.8-.8h-.1l-1.1.1zm0-.7h1.2c.6 0 .9-.5.9-1 .1-.5-.3-.9-.7-1h-1.3l-.1 2z"
        />
        <path
          id="prefix__Caminho_1800"
          className="prefix__st4"
          d="M61.4 8.6v2.9h-.8l.1-6.2h2.2c.8 0 1.4.6 1.5 1.3v.2c0 .7-.5 1.3-1.2 1.4.7.1 1 .4 1 1.6 0 .6.1 1.1.2 1.6h-.8c-.2-.7-.2-1.4-.2-2.1 0-.5-.4-.8-.8-.8h-.1l-1.1.1zm0-.7h1.2c.6 0 .9-.5.9-1 .1-.5-.3-.9-.7-1h-1.3l-.1 2z"
        />
        <path
          id="prefix__Caminho_1801"
          className="prefix__st3"
          d="M69.4 5.7l-.1.7-1.6-.1-.5 5.5-.8-.1.5-5.5-1.7-.2.1-.7 4.1.4z"
        />
        <path
          id="prefix__Caminho_1802"
          className="prefix__st4"
          d="M69.4 5.7l-.1.7-1.6-.1-.5 5.5-.8-.1.5-5.5-1.7-.2.1-.7 4.1.4z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1571"
          transform="rotate(-81.952 70.614 9.013)"
          className="prefix__st3"
          d="M67.5 8.6h6.2v.8h-6.2z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1572"
          fill="none"
          stroke="#fff"
          strokeWidth={0.07299635}
          strokeMiterlimit={3.863}
          d="M69.766 12.038l.868-6.139.792.112-.868 6.139z"
        />
        <path
          id="prefix__Caminho_1803"
          className="prefix__st3"
          d="M72 12.4l1.2-6.1 3 .6-.1.7-2.3-.4-.4 2 2.1.4-.1.7-2.1-.4-.6 2.8-.7-.3z"
        />
        <path
          id="prefix__Caminho_1804"
          className="prefix__st4"
          d="M72 12.4l1.2-6.1 3 .6-.1.7-2.3-.4-.4 2 2.1.4-.1.7-2.1-.4-.6 2.8-.7-.3z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1573"
          transform="rotate(-75.345 77.07 10.304)"
          className="prefix__st3"
          d="M74 9.9h6.2v.8H74z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1574"
          transform="rotate(-75.345 77.07 10.304)"
          className="prefix__st4"
          d="M74 9.9h6.2v.8H74z"
        />
        <path
          id="prefix__Caminho_1805"
          className="prefix__st3"
          d="M82.2 10.4c.1-.3.2-.6.1-.9-.1-.2-.3-.4-.6-.5-.3-.2-.8-.1-1 .2 0 .1-.2.2-.6 1.2l-.5 1.6c-.5 1.4-.1 1.8.5 2 .2.1 1 .2 1.4-.9l.8.3c-.6 1.9-1.9 1.4-2.3 1.3-.7-.3-1.9-.7-1.1-3l.6-1.7c.4-1.8 1.3-2 2.5-1.6 1.2.4 1.4 1.2 1 2.3l-.8-.3z"
        />
        <path
          id="prefix__Caminho_1806"
          className="prefix__st4"
          d="M82.2 10.4c.1-.3.2-.6.1-.9-.1-.2-.3-.4-.6-.5-.3-.2-.8-.1-1 .2 0 .1-.2.2-.6 1.2l-.5 1.6c-.5 1.4-.1 1.8.5 2 .2.1 1 .2 1.4-.9l.8.3c-.6 1.9-1.9 1.4-2.3 1.3-.7-.3-1.9-.7-1.1-3l.6-1.7c.4-1.8 1.3-2 2.5-1.6 1.2.4 1.4 1.2 1 2.3l-.8-.3z"
        />
        <path
          id="prefix__Caminho_1807"
          className="prefix__st3"
          d="M86.6 10.3l.9.4-.8 6.5-.8-.4.2-1.5-2.2-1-1.1 1.2-.7-.3 4.5-4.9zm.1.9l-2.3 2.6 1.8.8.5-3.4z"
        />
        <path
          id="prefix__Caminho_1808"
          className="prefix__st4"
          d="M86.6 10.3l.9.4-.8 6.5-.8-.4.2-1.5-2.2-1-1.1 1.2-.7-.3 4.5-4.9zm.1.9l-2.3 2.6 1.8.8.5-3.4z"
        />
        <path
          id="prefix__Caminho_1809"
          className="prefix__st3"
          d="M93 13.8l-.3.6-1.4-.8-2.8 4.8-.7-.4 2.8-4.8-1.4-.8.3-.6 3.5 2z"
        />
        <path
          id="prefix__Caminho_1810"
          className="prefix__st4"
          d="M93 13.8l-.3.6-1.4-.8-2.8 4.8-.7-.4 2.8-4.8-1.4-.8.3-.6 3.5 2z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1575"
          transform="rotate(-55.673 92.73 17.362)"
          className="prefix__st3"
          d="M89.6 17h6.2v.8h-6.2z"
        />
        <path
          id="prefix__Ret\xE2ngulo_1576"
          fill="none"
          stroke="#fff"
          strokeWidth={0.07299707999999999}
          strokeMiterlimit={3.865}
          d="M90.663 19.743l3.496-5.12.66.45-3.495 5.12z"
        />
        <path
          id="prefix__Caminho_1811"
          className="prefix__st3"
          d="M94 19.2l1.3-1.6c.7-.9 1.9-1 2.8-.4.9.7 1 1.9.4 2.8l-.1.1-1.4 1.6c-1.1 1.3-2 1.5-3 .6s-1.1-1.7 0-3.1m2.3 2l1.3-1.6c.8-1 .2-1.6 0-1.8s-.9-.7-1.7.3l-1.3 1.6c-.9 1.1-.8 1.6-.2 2.1s1.1.5 1.9-.6"
        />
        <path
          id="prefix__Caminho_1812"
          className="prefix__st4"
          d="M94 19.2l1.3-1.6c.7-.9 1.9-1 2.8-.4.9.7 1 1.9.4 2.8l-.1.1-1.4 1.6c-1.1 1.3-2 1.5-3 .6s-1.1-1.7 0-3.1zm2.3 2l1.3-1.6c.8-1 .2-1.6 0-1.8s-.9-.7-1.7.3l-1.3 1.6c-.9 1.1-.8 1.6-.2 2.1s1.1.5 1.9-.6z"
        />
        <path
          id="prefix__Caminho_1813"
          className="prefix__st3"
          d="M104.1 23.2l-4.5 4.3-.8-.8 2.2-5.5-3.9 3.8-.5-.5 4.5-4.3.8.8-2.2 5.4 3.9-3.7.5.5z"
        />
        <path
          id="prefix__Caminho_1814"
          className="prefix__st4"
          d="M104.1 23.2l-4.5 4.3-.8-.8 2.2-5.5-3.9 3.8-.5-.5 4.5-4.3.8.8-2.2 5.4 3.9-3.7.5.5z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent