import React, {
  FC
} from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SuccessStyles from './Success.styles';
import CheckCircle from '../../../assets/images/checkCircle.svg';

const Success: FC = () => {
  const { t }   = useTranslation();
  const classes = SuccessStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.dataContent}>
        <img src={CheckCircle} alt="Success" title="Success" />
        <Typography variant="body1" className={classes.title} >
          {t('thanks')}
        </Typography>
        <Typography variant="body1" className={classes.text} >
          {t('soonForm')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Success
