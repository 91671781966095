import React from 'react';
import { Link } from '@material-ui/core';
import PhoneLogo from '../../../../../assets/icons/social/phone.svg';
import EmailLogo from '../../../../../assets/icons/social/email.svg';
import TitleList from '../../../../../components/layout/footer/components/title';
import { constants } from '../../../../../global-constants';
import { useTranslation } from 'react-i18next';
import contactStyle from './Contact.styles';

function Contact() {
  const classes = contactStyle();
  const { t: translation } = useTranslation();

  interface ContactList {
    src: string;
    alt: string;
    href: string;
    name: string;
  }

  const {
    contacts: { phone, email },
  } = constants;

  const contacts: ContactList[] = [
    {
      src: PhoneLogo,
      alt: 'Phone',
      href: `tel: ${phone}`,
      name: phone,
    },
    {
      src: EmailLogo,
      alt: 'Email',
      href: `mailto: ${email}`,
      name: email,
    },
  ];

  return (
    <div className={classes.contactMenuContainer}>
      <TitleList name={translation('contacts')} className={classes.title} />
      <div className={classes.serviceHours}>{translation('serviceHoursText')}</div>
      <ul className={classes.noBullets}>
        {contacts.map(({ src, alt, href, name }) => (
          <li className={classes.margin} key={alt}>
            {alt === 'Phone' &&
              <div className={classes.contactNumberInfo}>
                <div className={classes.contactInfoContainer}>
                  <img src={src} alt={alt} title={alt} className={classes.logos} />
                  <Link href={href} className={`${classes.list} ${classes.nounderline}`}>
                    {name}
                  </Link>
                </div>
                <div className={classes.contactInfo}>{translation('contactsCost')}</div>
              </div>
            }
            {alt === 'Email' &&
            <>
              <img src={src} alt={alt} title={alt} className={classes.logos} />
              <Link href={href} className={classes.list}>
                {name}
              </Link>
            </>
            }
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Contact;
