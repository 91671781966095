import React, {
  FC
} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from './CardComponent.styles';

interface CardProps {
  title: string;
  description: string;
  url: string;
  urlToRedirect: string;
  color: string;
};

const CardComponent: FC<Partial<CardProps>> = ({
  title,
  description,
  url,
  urlToRedirect,
  color
}) => {
  const classes = useStyles({color: color ?? '', emptyBox: !title});

  if (!title) {
    return (
      <Grid container direction='column' className={classes.root}></Grid>
    );
  }

  return (
    <Grid container direction='column' className={classes.root} title={description}>
      <div className={classes.colorBox} />
      <div className={classes.title}>
        <Typography variant="subtitle1" className={classes.titleText}>
          {title}
        </Typography>
      </div>
      <div className={classes.description}>
        <Typography variant="body1" className={classes.descriptionText}>
          {description}
        </Typography>
      </div>
      <div className={classes.link}>
        {/* Link */}
        <a href={urlToRedirect} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </div>
    </Grid>
  );
};

export default CardComponent;
