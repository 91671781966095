import { createStyles, makeStyles } from '@material-ui/core';

const formStyle = makeStyles(theme =>
  createStyles({
    form: {
      paddingTop: '28.7%',
      paddingLeft: '10%',
      paddingRight: '10%',
      [theme.breakpoints.up('lg')]: {
        minHeight: '1120px',
        maxHeight: '1120px',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: '20.7%',
        paddingLeft: '7%',
        paddingRight: '14.7%',
        paddingBottom: '200px',
        boxShadow: '0px 0px 5px #00000029, 0px 0px 5px #00000029;',
      },

      [theme.breakpoints.down('xs')]: {
        paddingBottom: '100px',
        paddingRight: '5%',
      },
    },
    formApllied: {
      textAlign: 'center',
      lineHeight: 1,
      paddingLeft: 0,
      paddingRight: 0,
      backgroundColor: '#E2FDFF',
      [theme.breakpoints.up('lg')]: {
        paddingTop: '50.7%',
      },
    },

    formHomeLayout: {
      backgroundColor: '#263238',
    },

    inputContent: {
      [theme.breakpoints.down('md')]: {
        maxWidth: '630px'
      },
    },

    titleSucess: {
      color: '#1d1d1d',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '302px',
        paddingLeft: '36px'
      },
    },
    titleHomeLayout: {
      color: '#fff'
    },
    contactLater: {
      paddingLeft: 20,
    },
    thanks: {
      fontWeight: 600,
      margin: 0,
    },
    title: {
      textAlign: 'left',
      font: 'normal normal 600 20px/26px "Source Sans 3", Helvetica, Arial, sans-serif',
      letterSpacing: '0px',
      color: '#1D1D1B',
      opacity: 1,
      paddingBottom: 41,
    },
    contactSoon: {
      margin: 0,
    },
    smallText: {
      color: 'black',
      fontSize: 16,
      marginTop: 10,
      marginBottom: 30,
    },
    newsletterCheckbox: {
      [theme.breakpoints.down('lg')]: {
        marginTop: 14,
      },
    },
    label: {
      fontSize: 16,
      lineHeight: 1.1,
      color: '#404040',

      '& span':{
        textDecoration: 'underline',
        font: 'normal normal bold 16px/17px Lato',
        letterSpacing: '0px',
        color: '#404040',
      }
    },
    buttonSubmitContainerWithCaptcha: {
      marginTop: 30,
      textAlign: 'right',
      display: 'flex', 
      justifyContent: 'space-between',
      alignContent: 'center',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'unset',
      },
    },

    buttonSubmitContainer: {
      marginTop: 30,
      textAlign: 'right',

      [theme.breakpoints.down('xs')]: {
        textAlign: 'unset',
      },
    },

    submitButton: {
      height: '50px',

      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },

    link: {
      color: '#1D1D1B',
      fontWeight: 700,
      textDecoration: 'underline',
    },
    input: {
      height: 40,
    },
    select: {
      height: 40,
    },
    asterisk: {
      color: theme.palette.primary.main,
    },
    animation: {
      maxWidth:'525px',

      [theme.breakpoints.down('xs')]: {
        maxWidth: '340px',
      },
    },
    imageContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: '#263238',
      paddingBottom: '95px',
      marginBottom: '50px',

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        paddingLeft: '36px'
      },
    }
  })
);

export default formStyle;
