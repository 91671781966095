import * as React from "react"

function PauseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.98}
      height={25.124}
      viewBox="0 0 14.98 25.124"
      {...props}
    >
      <defs>
        <style>{".prefix__a{fill:#404040}"}</style>
      </defs>
      <path
        className="prefix__a"
        d="M13.079 0a1.9 1.9 0 00-1.9 1.9v21.322a1.9 1.9 0 103.8 0V1.902A1.9 1.9 0 0013.079 0zM1.902 0a1.9 1.9 0 00-1.9 1.9v21.322a1.9 1.9 0 003.8 0V1.902A1.9 1.9 0 001.902 0z"
      />
    </svg>
  )
};

export default PauseIcon;
