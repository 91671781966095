import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core";
import { Link } from "react-router-dom";

interface ISquare {
  id: number;
  icon: string;
  link: string;
  description: string;
  linkDescription: string;
  externo: boolean;
}

type ILinkComponent = Pick<ISquare, 'externo' | 'link'>

const useStyles = makeStyles((theme: Theme) => ({
  squareContainer: {
    border: 'none',
    width: '100%',
    maxWidth: '266px',
    height: 266,
    background: '#fff',
    boxShadow: '0 0 5px #00000026',
    borderRadius: 26,
    display: 'flex',
    justifyContent: 'center',
    margin: 8,
    alignItems: 'center',
    '&.hasCursor': {
      cursor: 'pointer',
      '&:hover': {
        background: '#f5f5f5'
      },
    }
  },
  integrationAnchorStyle: {
    textDecoration: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&.no-action': {
      pointerEvents: 'none',
      cursor: 'default'
    }
  },
  squareContainerInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '100%',
    margin: '10px'
  },
  centerBoxSlide: {
    height: '200px'
  },
  squareImgContainer: {
    flex: 1
  },
  squareLinkContainer: {
    flex: 1,
    alignItems: 'flex-end',
    '& button': {
      border: 'none',
      background: 'transparent',
      font: 'normal normal 300 18px/18px "Source Sans 3", Helvetica, Arial, sans-serif',
      color: '#1d1d1d',
      letterSpacing: 0
    }
  },
  squareDescContainer: {
    flex: 1,
    font: 'normal normal 600 18px/22px "Source Sans 3", Helvetica, Arial, sans-serif',
    letterSpacing: 0,
    color: '#1d1d1d',
    textAlign: 'center'
  },
  integrationSlide: {
    display: 'flex',
    justifyContent: 'center'
  },
  slideIcon: {
    width: '150px',
    height: '60px'
  },
  gtsIcon: {
    width: '135px'
  }
}));

const LinkComponent: FC<ILinkComponent> = ({ externo, children, link }) => {
  const classes = useStyles();

  if (externo) {
    return <a className={`${classes.integrationAnchorStyle} ${link === 'noLink' ? 'no-action' : ''}`} href={link} target="_blank">{children}</a>
  }

  return <Link className={`${classes.integrationAnchorStyle} ${link === 'noLink' ? 'no-action' : ''}`} to={link}>{children}</Link>
}

const Square: FC<ISquare> = ({ id, icon, link, description, linkDescription, externo }) => {
  const classes = useStyles();

  return (
    <div className={`slide-item-container ${classes.squareContainer} ${link !== 'noLink' ? 'hasCursor' : ''}`} title={description}>
      <LinkComponent externo={externo} link={link}>
        <div className={classes.centerBoxSlide}>
          <div className={classes.squareContainerInfo}>
            <div className={`${classes.squareImgContainer} ${classes.integrationSlide}`}><img className={id === 3 ? `${classes.slideIcon} ${classes.gtsIcon}` : classes.slideIcon} src={icon} alt=""/></div>
            <div className={`${classes.squareLinkContainer} ${classes.integrationSlide}`}><button>{linkDescription}</button></div>
            <div className={`${classes.squareDescContainer} ${classes.integrationSlide}`}>{description}</div>
          </div>
        </div>
      </LinkComponent>
    </div>
  );
};

export default Square;
