import Axios from 'axios';

type PostRequest = (
  apiMethod: string,
  config: {
    postData: {},
    source: string,
    successRequest?: (data) => void,
    errorRequest?: (error) => void
  }) => void;

export const postRequest:PostRequest = (apiMethod, {
  postData,
  source,
  successRequest = (data) => {},
  errorRequest = (error) => {},
}) => {
  const payload = {
    apiMethod,
    httpMethod: 'POST',
    postData,
    source
  };

  Axios.post("api/"+ apiMethod, payload)
    .then(data => {
      successRequest(data);
    })
    .catch(error => {
      errorRequest(error);
    });
};
