import { useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FreeTrialImg from '../../../assets/icons/pedir_teste.svg';
import { ReactComponent as LogoIdokMobile } from '../../../assets/images/idok_logo_mobile.svg';
import {
  CloseIcon,
  HamburgerIcon
} from '../../../components/icons';
import React, {
  FC, useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import logoIdok from '../../../assets/images/idok_logo.svg';
import { constants } from '../../../global-constants';
import { PageLinkProps } from '../../../Interfaces';
import SelectLanguage from './components/SelectLanguage';
import headerStyle from './HeaderComponent.styles';

import { TrialFormContext } from '../../../context/TrialFormContext';

const Header: FC = () => {
  // Style
  const localStorageidokUrl = window.localStorage.getItem('idokUrl');
  const idokUrl = localStorageidokUrl || "";
  const classes = headerStyle();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const { onToggleForm, showForm } = useContext(TrialFormContext);

  // Drawer
  const [state, setState] = React.useState({ right: false });

  const toggleDrawer = (anchor: string, isOpen: boolean) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setState({ ...state, [anchor]: isOpen });
  };

  // Translation
  const { t: translation, i18n } = useTranslation();

  const pageLink:PageLinkProps = (to, classes, name) => (
    <Link to={to} style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
      <Button className={classes}>{name}</Button>
    </Link>
  );

  const handleOnClickEnter = () => {
    const { languages } = constants;
    const lang = i18n.language;
    let urlToRedirect = idokUrl;

    if (lang !== languages.PT) {
      urlToRedirect = `${urlToRedirect}/?lang=${languages[lang]}`;
    } else {
      urlToRedirect = `${urlToRedirect}/?lang=PT`;
    };
    window.open(urlToRedirect, '_self');
  };

  const onShowForm = () => {
    if (!showForm) onToggleForm();
  }

  return (
    <Toolbar>
      <AppBar position="fixed" color="inherit">
        <Hidden only={['xs', 'sm', 'md']}>
          <Grid container direction="row" justify="space-between" alignItems="center" className={classes.container}>
            <Link smooth to="/#">
              <div className={classes.logoContainer}>
                <img src={logoIdok} title={translation('idokLogo')} alt={translation('idokLogo')} />
              </div>
            </Link>
            <div>
              <Link to="/app-gestao-documental" style={{ textDecoration: 'none' }} scroll={(el) => { scroller.scrollTo(el.id, { smooth: true }) }}>
                <Button className={classes.buttonLink}>
                  App iDOK
                </Button>
              </Link>
              <Link to="/#integrations" style={{ textDecoration: 'none' }} scroll={(el) => { scroller.scrollTo(el.id, { smooth: true, offset: -150 }) }}>
                <Button className={classes.buttonLink}>
                  {translation('integrations')}
                </Button>
              </Link>
              <Link to="/gestao-sistemas" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
                <Button className={classes.buttonLink}>
                  {translation('gestaoSistemas')}
                  <span className={classes.buttonLinkHighlight}>{translation('mobile.linkBold')}</span>
                </Button>
              </Link>
              { pageLink('/#prices', classes.buttonLink, translation('prices')) }
              <Link to={'/qualidade'} style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})}}>
                <Button className={classes.buttonLink}>
                  {translation('quality')}
                </Button>
              </Link>
              <Link to="/#demo" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})}} onClick={onShowForm}>
                <Button variant="contained" size="small" className={classes.buttonLinkTest}>
                  {translation('requestTest')}
                </Button>
              </Link>
              <Button className={classes.buttonLogin} onClick={handleOnClickEnter}>
                {translation('enter')}
              </Button>
              <SelectLanguage />
            </div>
          </Grid>
        </Hidden>
        <Hidden only={['lg', 'xl']}>
          {['right'].map(anchor => (
            <React.Fragment key={anchor}>
              <Grid container direction="row" justify="space-between" alignItems="center" className={classes.container}>
                <Link to="/">
                  {xsDown ? <LogoIdokMobile className={classes.logo} /> : <img src={logoIdok} title={translation('idokLogo')} alt={translation('idokLogo')} className={classes.logo} />}
                </Link>
                <div>
                  <Button className={classes.buttonLogin} onClick={handleOnClickEnter}>
                    {translation('enter')}
                  </Button>
                  <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={toggleDrawer(anchor, true)}>
                    <HamburgerIcon />
                  </IconButton>
                </div>
              </Grid>
              <Drawer
                transitionDuration={0}
                className={classes.drawer}
                anchor="right"
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                classes={{ paper: classes.paper }}
              >
                <div className={classes.header}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.container}
                  >
                    <SelectLanguage />
                    <IconButton onClick={toggleDrawer(anchor, false)}>
                      <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                  </Grid>
                </div>
                <div className={classes.menuContent}>
                  <ul className={classes.drawerUl}>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      <Link to="/app-gestao-documental" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
                        <Button className={classes.drawerLinkSpecial}>
                          App iDOK
                        </Button>
                      </Link>
                    </li>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      <Link to="/#integrations" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true, offset: -150})} }>
                        <Button className={classes.drawerLinkSpecial}>
                          { translation('integrations') }
                        </Button>
                      </Link>
                    </li>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      <Link to="/gestao-sistemas" style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} }>
                        <Button className={classes.drawerLinkSpecial}>
                          {translation('gestaoSistemas')}
                          <span className={`${classes.buttonLinkHighlight} mobile-menu`}>{translation('mobile.linkBold')}</span>
                        </Button>
                      </Link>
                    </li>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      { pageLink('/#features', classes.drawerLink, translation('features')) }
                    </li>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      { pageLink('/#prices', classes.drawerLinkSpecial, translation('prices')) }
                    </li>
                    <li className={classes.drawerLi} onClick={toggleDrawer(anchor, false)}>
                      { pageLink('/qualidade', classes.drawerLink, translation('quality')) }
                    </li>
                  </ul>
                </div>
                <div className={classes.freeTrialDiv}>
                  <Link to='/#demo' style={{ textDecoration: 'none' }} scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})} } onClick={toggleDrawer(anchor, false)}>
                    <Button variant="outlined" color="primary" className={classes.freeTrialButton} >
                      {translation('requestTest')}
                    </Button>
                    <img src={FreeTrialImg} alt={translation('requestTest')} title={translation('requestTest')} className={classes.freeTrialImg} />
                  </Link>
                </div>
              </Drawer>
            </React.Fragment>
          ))}
        </Hidden>
      </AppBar>
    </Toolbar>
  );
};

export default Header;
