import React from 'react';
import { AlertsConfigurations } from "@acin.reactjs/alert";
import Button from '@material-ui/core/Button';
import '../i18n';

type CustomAlerts = {
  successAlert: AlertsConfigurations["alert1"];
  infoAlert: AlertsConfigurations["alert3"];
  warningAlert: AlertsConfigurations["alert3"];
  errorAlert: AlertsConfigurations["alert4"];
};

export const globalConfig = {
  action: (key, api) => {
    const click = () => {
      api.closeAlert(key);
    };

    return <button onClick={click}>close</button>;
  },
};

export const customAlerts:CustomAlerts = {
  // Success Alert
  successAlert: { variant: "success" },

  // Info Alert
  infoAlert: {
    variant: "info",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    action: (key, api) => {
      const click = () => {
        api.closeAlert(key);
      };

      return (
        <Button  onClick={click}>
          close
        </Button>
      );
    },
  },

  // Warning Alert
  warningAlert: {
    variant: "warning",
    anchorOrigin: {
      vertical: "top",
      horizontal: "right",
    },
  },

  // Error Alert
  errorAlert: {
    variant: "error",
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "right",
    },
  }
};