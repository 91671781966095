import React from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectArrowIcon } from '../../../../components/icons';
import selectLanguageStyle from './SelectLanguage.styles';

function SelectLanguage() {
  const { i18n } = useTranslation();
  const { formControl, selectMenu, icon, paper, list } = selectLanguageStyle();

  const [country, setCountry] = React.useState(i18n.language);

  const handleChange = event => {
    setCountry(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={formControl}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={country}
        onChange={handleChange}
        IconComponent={SelectArrowIcon}
        classes={{ selectMenu, icon }}
        MenuProps={{ classes: { paper, list } }}
      >
        <MenuItem value="PT">PT</MenuItem>
        <MenuItem value="GB">EN</MenuItem>
        <MenuItem value="ES">ES</MenuItem>
      </Select>
    </FormControl>
  );
}

export default SelectLanguage;
