import {makeStyles, Theme} from '@material-ui/core/styles';

const mobileStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#E2FDFF',
    padding: '10% 7%',

    [theme.breakpoints.up('lg')]: {
        padding: '0 0 0 7%',
    },
  },
  itemLeft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',

    '& > *:not(:last-child)': {
      marginBottom: 32,
    },

    [theme.breakpoints.up('lg')]: {
      padding: '50px 12px',
    },

  },
  itemRight: {
    display: 'none',

    [theme.breakpoints.up('lg')]: {
        display: 'block'
    },
  },
  secondaryTitle: {
    color: '#005580',
    font: 'normal normal 600 18px/27px Poppins',
    textTransform: 'uppercase'
  },
  mainTitle: {
    font: 'normal normal 600 30px/36px Poppins',
    [theme.breakpoints.up('sm')]: {
        font: 'normal normal 600 36px/44px Poppins',
    },
    [theme.breakpoints.up('lg')]: {
        font: 'normal normal 600 48px/64px Poppins',
    },

    '& .color--blue': {
      color: theme.palette.primary.dark
    },

    '& > span': {
      display: 'block'
    }
  },
  paragraph: {
    color: "#1d1d1b",

    '& .link': {
      color: '#1d1d1b'
    },

    '& .bold': {
      fontWeight: 600
    }
  },
  button: {
    marginTop: 32,
    height: 50,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'relative',
    transform: 'translateY(100px)',
    transition: 'transform 0.4s linear'
  },
  imageAnimated: {
    transform: 'translateY(0)'
  }
}));

export default mobileStyles;
