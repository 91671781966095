import React from 'react';
import { Grid, Link, SvgIconProps } from '@material-ui/core';
import { LinkedinIcon, YoutubeIcon } from '../../../../../components/icons';
import { constants } from '../../../../../global-constants';
import socialStyle from './Social.styles';

const SocialMedia: React.FC = () => {
  const classes = socialStyle();

  interface SocialMedia {
    href: string;
    Icon: React.FC<SvgIconProps>;
    label: string;
  }

  const {
    socials: { linkedin, youtube },
  } = constants;

  const socials: SocialMedia[] = [
    {
      href: linkedin,
      Icon: LinkedinIcon,
      label: 'linkedin'
    },
    {
      href: youtube,
      Icon: YoutubeIcon,
      label: 'youtube'
    }
  ];

  return (
    <Grid container spacing={0} className={classes.socialMedia}>
      {socials.map(({ href, Icon, label }, index) => (
        <Grid item xs={3} className={classes.flexBasis} key={index}>
          <Link href={href} target="_blank" rel="noopener" aria-label={label}>
            <Icon className={classes.icon} />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default SocialMedia;
