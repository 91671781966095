import { makeStyles, createStyles } from '@material-ui/core';
import palette from '../../../../../global-style/colors.styles';

const qnaStyles = makeStyles(() =>
  createStyles({
    container: {
      borderBottom: '1px solid #BFBFBF',
    },
    containerItem: {
      marginBottom: 20,
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    question: {
      fontWeight: 'bold',
      cursor: 'pointer',
      marginTop: 50,
    },
    icons: {
      color: palette.secondary.main,
      marginTop: 50,
      paddingLeft: '10px',
      cursor: 'pointer',
    },
    collapse: {
      marginBottom: 20,
    },
    bold: {
      fontWeight: 'bold',
    },
  })
);

export default qnaStyles;
