import React, { 
  useCallback, 
  FC 
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BaseError from "./BaseError";

const Error404: FC = () => {
  const imageName = 'error404';
  const { t }     = useTranslation();
  const history   = useHistory();
  
  const onClick = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <BaseError
      name={imageName}
      dataOptions={{
        title: t("page404.title"),
        subTitle: t("page404.subTitle"),
        description: t("page404.description")
      }}
      onClick={onClick}
    />
  );
};

export default Error404;