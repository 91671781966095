import React, { useState } from 'react';
import { Grid, Link, Typography, Collapse } from '@material-ui/core';
import { OpenIcon } from '../../../../../components/icons';
import Title from '../../../../../components/layout/footer/components/title';
import clsx from 'clsx';
import { constants } from '../../../../../global-constants';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import addressStyle from './Address.styles';

function Address() {
  const classes = addressStyle();
  const [checked, setChecked] = useState(false);
  const handleChange = () => setChecked(prev => !prev);
  const { t: translation } = useTranslation();
  const theme     = useTheme();
  const tabletLayout = useMediaQuery(theme.breakpoints.down(690));

  interface AddressList {
    local: string;
    address: string;
    zipcode: string;
    place: string;
    href: string;
  }

  const {
    addresses: { porto, lisboa, madeira, acores },
  } = constants;

  const addresses: AddressList[] = [
    {
      place: porto.place,
      local: porto.local,
      address: porto.address,
      zipcode: porto.zipcode,
      href: porto.href,
    },
    {
      place: lisboa.place,
      local: lisboa.local,
      address: lisboa.address,
      zipcode: lisboa.zipcode,
      href: lisboa.href,
    },
    {
      place: madeira.place,
      local: madeira.local,
      address: madeira.address,
      zipcode: madeira.zipcode,
      href: madeira.href,
    },
    {
      place: acores.place,
      local: acores.local,
      address: acores.address,
      zipcode: acores.zipcode,
      href: acores.href,
    },
  ];

  return (
    <Grid item xs={12} className={classes.padding}>
      <Grid className={classes.centered}>
        <div className={classes.collapseWrapper} onClick={() => handleChange()}>
          <Typography component="span">
            <Title name={translation('addresses')} className={classes.title} />
          </Typography>
          <Grid className={classes.center}>
            <OpenIcon
              className={clsx(classes.iconOpen, {
                [classes.iconClosed]: checked,
              })}
            />
          </Grid>
        </div>
      </Grid>
      <Collapse in={checked} className={classes.overflowHidden}>
        <Grid container spacing={10} className={classes.collapse}>
          {addresses.map(({ local, address, zipcode, place, href }) => (
            tabletLayout ?
              (
                <Grid item xs={12} sm={6} className={classes.padding} key={zipcode}>
                  <Grid className={classes.eachAddressPadding}>
                    <Title name={place} className={classes.titleSemiBold} />
                    <ul className={classes.noBullets}>
                      <li className={classes.footerFonts}>ACIN iCloud Solutions</li>
                      <li className={classes.footerFonts}>{local}</li>
                      <li className={classes.footerFonts}>{address}</li>
                      <li className={classes.footerFonts}>{zipcode}</li>
                    </ul>
                    <ul className={classes.noBullets}>
                      <li>
                        <Link className={classes.list} href={href} target="_blank" rel="noopener">
                          {translation('maps')}
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              ) :
              (
                <Grid item className={classes.padding} key={zipcode}>
                  <Grid className={classes.eachAddressPadding}>
                    <Title name={place} className={classes.titleSemiBold} />
                    <ul className={classes.noBullets}>
                      <li className={classes.footerFonts}>ACIN iCloud Solutions</li>
                      <li className={classes.footerFonts}>{local}</li>
                      <li className={classes.footerFonts}>{address}</li>
                      <li className={classes.footerFonts}>{zipcode}</li>
                    </ul>
                    <ul className={classes.noBullets}>
                      <li>
                        <Link className={classes.list} href={href} target="_blank" rel="noopener">
                          {translation('maps')}
                        </Link>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              )
          ))}
        </Grid>
      </Collapse>
    </Grid>
  );
}

export default Address;
