import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import palette from '../../../../../global-style/colors.styles';


const contactStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactMenuContainer: {
      maxWidth: '266px'
    },
    serviceHours: {
      font: 'normal normal normal 13px/17px Lato',
      letterSpacing: 0,
      color: '#1d1d1d',
      marginTop: 13
    },
    noBullets: {
      listStyleType: 'none',
      padding: '0px',
    },
    list: {
      color: '#1D1D1D',
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '2px',
      fontWeight: 400
    },
    nounderline: {
      textDecoration: 'none'
    },
    title: {
      color: palette.secondary.dark,
      fontWeight: 600,
      fontSize: '16px',
    },
    logos: {
      width: '16px',
      height: '16px',
      marginTop: '5px'
    },
    margin: {
      marginTop: '18px',
      display: 'flex',
      gap: '10px',
      [theme.breakpoints.down(600)]: {
        justifyContent: 'center',
      },
    },
    contactInfoContainer: {
      display: 'flex',
      gap: '8px',
      [theme.breakpoints.down(600)]: {
        justifyContent: 'center'
      }
    },
    contactInfo: {
      font: 'normal normal 400 11px/15px Lato',
      letterSpacing: '0px',
      color: '#1D1D1D',
      paddingLeft: '25px',
      width: '100%',
      [theme.breakpoints.down(600)]: {
        paddingLeft: 0
      }
    },
    contactNumberInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      width: '100%'
    }
  })
);

export default contactStyles;
