import { createStyles, makeStyles } from '@material-ui/core';

const successStyle = makeStyles(theme =>
  createStyles({
    container: {
      padding: '60px 17% 100px 17%',
    },
    dataContent:{
      display:'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& img': {
        width: '73px',
        height: '73px',
      }
    },
    title:{
      paddingTop:'25px',
      font: 'normal normal 600 20px/26px lato',
      letterSpacing: '0px',
      color: '#1D1D1B',
    },
    text:{
      font: 'normal normal normal 20px/26px lato',
      color: '#1D1D1B',
      
      [theme.breakpoints.down('xs') ]: {
        textAlign: 'center',
      },
    },
  })
);

export default successStyle;