import { constants } from '../../../global-constants';
import ImageWorld from '../../../assets/icons/group16142.svg';
import ImageBills from '../../../assets/icons/group16144.svg';
import ImageEmployees from '../../../assets/icons/group16143.svg';
import AcinGovGray from '../../../assets/images/platformsIdokInGray/contratacao-publica.svg';
import AcinGovColor from '../../../assets/images/platformsIdokInColor/contratacao-publica.svg';
import IparqueGray from '../../../assets/images/platformsIdokInGray/iparque-icon-cinza.svg';
import IparqueColor from '../../../assets/images/platformsIdokInColor/iparque-icon-cor.svg';
import ImedGray from '../../../assets/images/platformsIdokInGray/gestao-clinica.svg';
import ImedColor from '../../../assets/images/platformsIdokInColor/gestao-clinica.svg';
import IgestGray from '../../../assets/images/platformsIdokInGray/programa-faturacao.svg';
import IgestColor from '../../../assets/images/platformsIdokInColor/programa-faturacao.svg';
import CdoEstadotGray from '../../../assets/images/platformsIdokInGray/concursos-publicos.svg';
import CdoEstadoColor from '../../../assets/images/platformsIdokInColor/concursos-publicos.svg';
import IfregGray from '../../../assets/images/platformsIdokInGray/gestao-freguesias.svg';
import IfregColor from '../../../assets/images/platformsIdokInColor/gestao-freguesias.svg';
import PayPayGray from '../../../assets/images/platformsIdokInGray/solucao-pagamentos-online.svg';
import PayPayColor from '../../../assets/images/platformsIdokInColor/solucao-pagamentos-online.svg';
import IrhGray from '../../../assets/images/platformsIdokInGray/gestao-assiduidade.svg';
import IrhColor from '../../../assets/images/platformsIdokInColor/gestao-assiduidade.svg';
import GtsGray from '../../../assets/images/platformsIdokInGray/assinatura-digital.svg';
import GtsColor from '../../../assets/images/platformsIdokInColor/assinatura-digital.svg';
import OmeuTaxiGray from '../../../assets/images/platformsIdokInGray/programa-faturacao-taxis.svg';
import OmeuTaxiColor from '../../../assets/images/platformsIdokInColor/programa-faturacao-taxis.svg';
import IlinkGray from '../../../assets/images/platformsIdokInGray/faturacao-eletronica-edi.svg';
import IlinkColor from '../../../assets/images/platformsIdokInColor/faturacao-eletronica-edi.svg';
import AgroMarketGray from '../../../assets/images/platformsIdokInGray/agromarket-grey.svg';
import AgroMarketColor from '../../../assets/images/platformsIdokInColor/agromarket-color.svg';
import DenunciasGray from '../../../assets/images/platformsIdokInGray/denuncias-grey.svg';
import DenunciasColor from '../../../assets/images/platformsIdokInColor/denuncias-color.svg';

const { platforms } = constants;
export const boxParams = [
  {
    imageUrl:ImageWorld,
    alt:'Clientes Gestao Documental',
    highlight:'highlightNumberClientAcin',
    firstNormal:'clients',
    secondNormal:'normalNumberClientAcin',
  },
  {
    imageUrl:ImageBills,
    alt:'Faturacao Gestao Documental',
    highlight:'highlightBillsAcin',
    firstNormal:'',
    secondNormal:'normalBillsAcin',
  },
  {
    imageUrl:ImageEmployees,
    alt:'Colaboradores Gestao Documental',
    highlight:'highlightEmployeesAcin',
    firstNormal:'',
    secondNormal:'normalEmployeesAcin',
  }
];

export const platformParams = (translation) => [
  [
    {
      src1:AcinGovGray,
      src2: AcinGovColor,
      urlToRedirect: platforms.acingov,
      alt: translation('acinGroup.platforms.acingov.desc')
    },
    {
      src1: IparqueGray,
      src2: IparqueColor,
      urlToRedirect:platforms.iparque,
      alt: translation('acinGroup.platforms.iParque.desc')
    },
    {
      src1: ImedGray,
      src2: ImedColor,
      urlToRedirect:platforms.imed,
      alt: translation('acinGroup.platforms.imed.desc')
    }
  ],
  [
    {
      src1: IgestGray,
      src2: IgestColor,
      urlToRedirect:platforms.igest,
      alt: translation('acinGroup.platforms.igest.desc')
    },
    {
      src1: CdoEstadotGray,
      src2: CdoEstadoColor,
      urlToRedirect:platforms.comprasDoEstado,
      alt: translation('acinGroup.platforms.cestado.desc')
    },
    {
      src1: IfregGray,
      src2: IfregColor,
      urlToRedirect:platforms.ifreg,
      alt: translation('acinGroup.platforms.ifreg.desc')
    }
  ],
  [
    {
      src1: PayPayGray,
      src2: PayPayColor,
      urlToRedirect:platforms.paypay,
      alt: translation('acinGroup.platforms.paypay.desc')
    },
    {
      src1: IrhGray,
      src2: IrhColor,
      urlToRedirect:platforms.irh,
      alt: translation('acinGroup.platforms.irh.desc')
    },
    {
      src1: GtsGray,
      src2: GtsColor,
      urlToRedirect:platforms.gts,
      alt: translation('acinGroup.platforms.gts.desc')
    }
  ],
  [
    {
      src1: OmeuTaxiGray,
      src2: OmeuTaxiColor,
      urlToRedirect:platforms.omeutaxi,
      alt: translation('acinGroup.platforms.taxi.desc')
    },
    {
      src1: IlinkGray,
      src2: IlinkColor,
      urlToRedirect:platforms.ilink,
      alt: translation('acinGroup.platforms.ilink.desc')
    },
    {
      src1: AgroMarketGray,
      src2: AgroMarketColor,
      urlToRedirect: platforms.agroMarket,
      alt: translation('acinGroup.platforms.agromarket.desc')
    }
  ],
  [
    {
      src1: DenunciasGray,
      src2: DenunciasColor,
      urlToRedirect: platforms.denuncias,
      alt: translation('acinGroup.platforms.denuncias.desc')
    }
  ]
];
