import {makeStyles, Theme} from '@material-ui/core';

const mobilePageStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 17,
    color: "#1D1D1B",
    overflowX: 'hidden',
    paddingBottom: 32,

    '& a': {
      color: "#1D1D1B",
    },

    '& .color--secondary-dark': {
      color: theme.palette.secondary.dark
    },

    '& .color--secondary-main': {
      color: theme.palette.secondary.main
    },

    '& .color--white': {
      color: '#fff'
    },

    '& .bold': {
      fontWeight: 600,
    },

    '& .normal': {
      fontWeight: 300
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  },

  title: {
    font: 'normal normal 600 30px/36px Poppins',
    [theme.breakpoints.up('sm')]: {
      font: 'normal normal 600 36px/48px Poppins',
    },
    [theme.breakpoints.up('lg')]: {
      font: 'normal normal 600 48px/56px Poppins',
    },

  },

  mainSection: {
    backgroundColor: theme.palette.secondary.light,
    minHeight: 'calc(100vh - 80px)',
    height: '100%',
    display: 'flex',
    padding: '0 7%',
    paddingBottom: 60
  },

  mainSectionLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h1': {
      font: 'normal normal 600 48px/56px Poppins',
      margin: 0,
      color: '#1d1d1d',
      '& span': {
        color: '#1aa732'
      },
      [theme.breakpoints.down('md')]: {
        font: 'normal normal 600 30px/35px Poppins'
      }
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'unset',
      paddingTop: 45
    }
  },
  mainSectionRight: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 0',
    '& .banner-image': {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 600,
        maxHeight: 600
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: 674,
        maxHeight: 720
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  mainSectionText: {
    margin: 0,
    font: 'normal normal 400 20px/24px Lato',
    color: '#1d1d1d',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      font: 'normal normal 400 18px/24px Lato'
    }
  },
  mainSectionSmallContainerSmall: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .banner-image': {
        maxHeight: 324
      },
      marginBottom: 30
    }
  },
  separator: {
    width: '150px',
    height: '12px',
    backgroundColor: theme.palette.secondary.dark,
    marginBottom: '32px',

    [theme.breakpoints.up('lg')]: {
      marginBottom: '48px',
    },
  },
  secondarySection: {
    padding: '10% 7%',
  },
  secondarySectionTitle: {
    textAlign: 'center',
    marginBottom: 32,

    [theme.breakpoints.up('lg')]: {
      marginBottom: 48,
    },

    '& .box-container': {
      position: 'relative',
      zIndex: 1,
    },

    '& svg': {
      position: 'absolute',
      left: -120,
      top: -65,
      zIndex: -1,

      [theme.breakpoints.up('sm')]: {
        left: -120
      },
    },

  },
  secondaryParagraph: {
    color: "#1D1D1B",
    textAlign: 'center',
    maxWidth: 864,
    margin: '0 auto 32px'
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
      position: 'relative',
      zIndex: 2,
      width: '20%',
      opacity: 0,
      transition: 'transform 0.4s linear'
    },

    '& :nth-child(1)': {
      transform: 'translateX(100%)',
      zIndex: 0,
      height: '90%',
    },

    '& :nth-child(2)': {
      transform: 'translateX(50%)',
      zIndex: 1,
      height: '95%',
    },

    '& :nth-child(4)': {
      transform: 'translateX(-50%)',
      zIndex: 1,
      height: '95%',
    },

    '& :nth-child(5)': {
      transform: 'translateX(-100%)',
      zIndex: 0,
      height: '90%',
    },
  },
  imageContainerAnimated: {
    '& img': {
      opacity: 1,
    },

    '& :nth-child(1)': {
      transform: 'translateX(20%)',
    },

    '& :nth-child(2)': {
      transform: 'translateX(10%)',
    },

    '& :nth-child(4)': {
      transform: 'translateX(-10%)',
    },

    '& :nth-child(5)': {
      transform: 'translateX(-20%)',
    },
  },
  thirdSection: {
    backgroundColor: '#263238',
    padding: '10% 7% 5%',
    position: 'relative',

    [theme.breakpoints.up('lg')]: {
      padding: '5% 7%',
    },
  },
  thirdSectionImage: {
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      bottom: '-20%',
      left: '24%',
      height: '115%',
      width: '100%',
      zIndex: 1
    },
  },
  thirdSectionLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  thirdSectionRight: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  thirdSectionTitle: {
    color: "#fff",
    marginBottom: 32,

    '& .color': {
      color: theme.palette.secondary.main
    },

    '& span': {
      display: 'block'
    },

    [theme.breakpoints.up('lg')]: {
      marginBottom: 48,
    },
  },
  thirdSectionParagraph: {
    marginBottom: 24,

    '& a': {
      color: "#fff",
    },

  },
  square: {
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: 0,
    opacity: 0.11,

    '&.square--small': {
      top: 0,
      right: 224,
      width: 177,
      height: 177,
    },

    '&.square--big': {
      top: 177,
      right: 0,
      width: 224,
      height: 224,
    }
  },
  forthSectionLeft: {
    padding: '10% 7%',

    [theme.breakpoints.up('lg')]: {
      padding: '5% 7%',
    },
  },
  forthSectionRight: {
    padding: '10% 7%',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  forthSectionTitle: {
    marginBottom: 32,

    [theme.breakpoints.up('lg')]: {
      marginBottom: 48,

      '& .break': {
        display: 'block'
      }
    },
  },
  list: {
    margin: 0,
    padding: 0,
    marginBottom: 24
  },
  listItem: {
    listStyle: 'none',
    display: 'flex',
    marginBottom: 8,
    fontSize: 18,

    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },

    '& .bullet': {
      marginRight: 8,
      fontWeight: 'bold'
    },
  },
  listCards: {
    display: 'grid',
    gridTemplateColumns: "repeat(1, fr)",
    listStyle: 'none',
    gap: 32,
    margin: 0,
    padding: 0,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: "repeat(2, 224px)",
    },
  },
  listCardItem: {
    backgroundColor: '#fff',
    boxShadow: "0px 3px 6px #00000029",
    padding: 20,
    textAlign: 'center',
  },
  listCardContent: {
    font: 'normal normal 300 18px/20px Poppins',
    color: '#404040',
    marginTop: 16,

    '& .bold': {
      display: 'block'
    }
  },
}))

export default mobilePageStyles;
