import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const titleStyle = makeStyles((theme: Theme) =>
  createStyles({
    titleContent: {
      maxWidth: '800px',
      paddingBottom: '5px',

      [theme.breakpoints.down('md')]: {
        maxWidth: '600px',
      }
    },
    title: {
      [theme.breakpoints.down(1280)]: {
        fontSize: 36,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
    },
    textSpotlight: {
      marginBottom: 33,
      paddingBottom: 13,
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('xs')]: {
        display: 'inline-block',
        borderBottom: `8px solid ${theme.palette.secondary.dark}`,
      },
    },
    overline: {
      lineHeight: 1,
      fontWeight: 600,
      fontSize: 48,

      [theme.breakpoints.down('md')]: {
        fontSize: 36,
      },
      [theme.breakpoints.down('sm')]: {
        lineHeight: 1.2,
        fontSize: 36,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
      margin: 0,
    },
    greenBorder: {
      borderBottom: `12px solid ${theme.palette.secondary.dark}`,
      width: '22%',
      marginBottom: 30,
      marginTop: 25,
      [theme.breakpoints.down('sm')]: {
        marginTop: 15,
        marginBottom: 20,
        borderBottom: `12px solid ${theme.palette.secondary.dark}`,
      },
    },
    lowWeigth: {
      fontWeight: 300,
      [theme.breakpoints.down('md')]: {
        fontSize: 36,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
    },
  })
);

export default titleStyle;
