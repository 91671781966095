import { createTheme } from "@material-ui/core/styles";
import { FormTheme } from "@acin.reactjs/form-components";

declare module "@material-ui/core/styles" {
  interface ThemeOptions extends FormTheme {}
}

const theme = createTheme({
  forms: {
    selectSearch: {
      formControl: {
        maxWidth: '660px',
        margin: 'auto',
      },
      input: {
        root: {
          paddingRight: '14px !important',
          minHeight: '50px',
          fontSize: '20.2px',
          fontFamily: 'Lato',
          fontWeight: 300,
          lineHeight: '1.1876em',
          color: '#676769',
          border: '1px solid #DEDEDE',
        },
      },
      option: {
        root: {
          textAlign: 'left',
          color: '#676769',
        },
      },
      dropdown: {
        "& [class*='MuiPaper-root']": {
          marginTop: "-5px",
          border: "2px solid #DEDEDE",
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
  },
});

export default theme;
