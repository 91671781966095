import React, {
  FC,
  useState,
  useEffect,
  useRef
} from 'react';
import Lottie from 'react-lottie-player';
import VisibilitySensor from "react-visibility-sensor";
import { AnimatedImageProps } from '../../Interfaces';
import lottieHome from '../../assets/animations/section_home.json';
import lottieTrial from '../../assets/animations/section_free_trial.json';
import lottieGts from '../../assets/animations/idok-gts.json';
import lottieIdokMobile from '../../assets/animations/animacao-idok-mobile.json';

const AnimatedImage: FC<AnimatedImageProps> = ({ name, options = {}, divProps, ...props }) => {
  const [autoPlay, setAutoPlay] = useState(false);
  const [animationObj, setAnimationObj] = useState(null);

  useEffect(() => {
    switch (name) {
      case 'section_home':
        setAnimationObj(lottieHome);
        break;
      case 'section_free_trial':
        setAnimationObj(lottieTrial);
        break;
      case 'idok-gts':
        setAnimationObj(lottieGts);
        break;
      case 'animacao-idok-mobile':
        setAnimationObj(lottieIdokMobile);
        break;
      default:
        break;
    }
  }, [name]);

  const handleVisibility = (isVisible) => {
    if (isVisible) {
      setAutoPlay(true);
    };
  }

  return (
    <VisibilitySensor
      onChange={handleVisibility}
      partialVisibility
      >
        <div {...divProps}>
            <Lottie
              loop={options.loop}
              animationData={animationObj}
              play={autoPlay}
              renderer="svg"
              style={{ 'width': '100%', 'height': '100%' }}
            />
        </div>
    </VisibilitySensor>
  );
};
export default AnimatedImage;
