import React, {
  FC,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import pricesStyle from './PricesComponent.styles';
import Button from '../../formComponents/Button';
import FormComponent from './PricesForm';
import Success from './Success';
import GreenBoxes from '../../../assets/images/GreenBoxes';
import GreenBox from '../../../assets/images/GreenBox';

type BtnPrice = 'button' | 'form' | 'success';

const Prices: FC = () => {
  let component = <></>;
  const classes = pricesStyle();
  const { t } = useTranslation();
  const componentNames:BtnPrice[] = ['button', 'form', 'success'];
  const [priceComponent, setPriceComponent] = useState<BtnPrice>(componentNames[0]);
  const theme = useTheme();
  const mobileLayout = useMediaQuery(theme.breakpoints.down('xs'));
  
  switch (priceComponent) {
    case componentNames[0]:
      component = (
        <div className={classes.btnContent}>
          <Button 
            value={t('requestPrices')}
            onClick={() => setPriceComponent(componentNames[1])}
            classname={classes.requestBtn}
          />
        </div>
      )
      break;
    case componentNames[1]:
      component = 
        <FormComponent 
          onSubmitForm={()=> setPriceComponent(componentNames[2])}
        />
      break;
    case componentNames[2]:
      component = 
        <Success />
      break;
  };

  return (
    <Grid className={classes.container} id="prices">
      <div className={classes.titleContent}>
        { mobileLayout ? <GreenBox /> : <GreenBoxes />}
        <Typography variant="body1" className={classes.title}>
          <span className={classes.normalTitle}>{t('normalTitlePrices')}</span>
          <span className={classes.greenTitle}>{t('highlightTitlePrices')}</span>
        </Typography>
      </div>
      <div className={classes.introPrices}>
        <Typography variant="body1">
          {t('introPrices')}
        </Typography>
        <Typography component="span" variant="body1" className={classes.higlightColor}>
          {t('titlePrices')}
        </Typography>
      </div>
      <Grid item xs={12} className={classes.pricesFormContainer}>
        {component}
      </Grid>
    </Grid>
  );
};

export default Prices;
