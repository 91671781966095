import React, {
  FC
} from 'react';
import {
  Route,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PublicRouteProps } from '../../Interfaces';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/FooterComponent';

const PublicRoute:FC<PublicRouteProps> = ({
  component: Component,
  restricted=false,
  header=true,
  footer=true,
  footerShadow=false,
  ...items
}) => {
  const localStorageidokUrl = window.localStorage.getItem('idokUrl');
  const redirectTo = localStorageidokUrl || "";
  const [cookies] = useCookies();

  const template = (props) => {
    const headerComp = (header) ? <Header /> : null;
    const footerComp = (footer) ? <Footer footerShadow={footerShadow}/> : null;

    return (
      <>
        {headerComp}
        <Component {...props} />
        {footerComp}
      </>
    );
  };

  return (
    <Route {...items} render={props => (
      (cookies.d_time && restricted) ?
        window.location.replace(redirectTo as string)
      : template(props) )}
    />
  )
}

export default PublicRoute
