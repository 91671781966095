import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const PlatformStyle = makeStyles((theme: Theme) =>
  createStyles({
    contentLogo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',

      '& a': {
        cursor:'pointer'
      }
    }
  })
);

export default PlatformStyle;