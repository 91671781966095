import React from 'react'
import { HashLink as Link} from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { PlayIcon } from '../../../../icons';
import useStyles from './MobileLayout.styles';
import Title from '../title';
import AnimatedImage from '../../../../animation/AnimatedImage';

function MobileLayout({
  showVideo
}) {
  const classes            = useStyles();
  const { t: translation } = useTranslation();

  return (
    <>
      <div className={classes.title}>
        <Title />
      </div>
      <div className={classes.animatedImage}>
        <AnimatedImage
          options={{ loop: false }}
          name="section_home"
        />
      </div>
      <div className={classes.textContent}>
        <Typography variant="body1" className={classes.mainContent}>
          {translation('mainContentHome')}
        </Typography>
      </div>
      <div className={classes.btnContainer}>
        <Grid item xs={12}>
          <Link
            to="/#demo"
            style={{ textDecoration: 'none' }}
            scroll={(el) => {scroller.scrollTo(el.id , {smooth: true})}}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.buttonLink}
            >
              { translation('requestTest') }
            </Button>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => showVideo()}
            endIcon={<PlayIcon />}
            className={classes.videoBtn}
            fullWidth
          >
            { translation('watchVideo') }
          </Button>
        </Grid>
      </div>
    </>
  )
}

export default MobileLayout;
