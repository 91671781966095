import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const creditStyle = makeStyles((theme: Theme) =>
  createStyles({
    credits: {
      marginBottom: '10px',
      textAlign: 'center',

      [theme.breakpoints.only('sm')]: {
        paddingRight: 0,
      },
      
      [theme.breakpoints.only('xs')]: {
        paddingRight: 20,
        paddingLeft: 20,
        margin: '10px 0px 50px 0',
      },
    },
    list: {
      color: '#1D1D1B',
      textDecoration: 'underline',
    },
    footerFonts: {
      color: '#1D1D1B',
      fontSize: '16px',
    },
    w3cIcon: {
      textAlign: 'center',
      paddingTop: '80px',
    }
  })
);

export default creditStyle;
