import React, {
  FC
} from 'react';
import Hidden from "@material-ui/core/Hidden";
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid';
import Qna, { QuestionAndAnswerProps as ListType } from '../qna/QnaComponent';


interface QuestionsProps {
  faqsList: ListType[] | ListType;
  url: string;
  active?: boolean;
};

const QuestionsComponent: FC<QuestionsProps> = ({
  faqsList,
  url,
  active=false
}) => {
  
  if (active) {
    (faqsList as ListType).active = active;
  };
  
  return (
    <Hidden>
        <Collapse>
          { 
            Array.isArray(faqsList) ?
              faqsList.map(faq => 
                <Grid item xs={12} key={faq.question}>
                  <Qna {...faq} url={url} />
                </Grid>
              )
            :
              <Grid item xs={12} key={(faqsList).question}>
                <Qna {...faqsList} url={url} />
              </Grid>
          }
        </Collapse>
        <Grid container>
          {
            Array.isArray(faqsList) ?
              faqsList.map(faq => 
                <Grid item xs={12} key={faq.answer}>
                  <Qna {...faq} url={url} />
                </Grid>
              )
            :
              <Grid item xs={12} key={(faqsList).answer}>
                <Qna {...faqsList} url={url}/>
              </Grid>
          }
        </Grid>
      </Hidden>
  )
};

export default QuestionsComponent
